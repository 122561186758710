import { useLocation, useNavigate } from "react-router-dom";

import TableBody from "./common/tableBody";
import TableHeader from "./common/tableHeader";

import "../styles/monthDetail.css";

const PersonaDetail = () => {

  const isAdmin = localStorage.getItem('isAdmin') === 'true';  
  const navigate = useNavigate();

  if (!isAdmin) {
    navigate('/');
  }

  const location = useLocation();
  const stateData = location.state;

  const HeaderKeys = [
    "Member",
    "User Persona",
    "Identities",
    "Churn Tags",
    "Source",
  ];

  const tableBodyKeys = ['name',"userPersona", "identityTags", "churnTags", "source"];

  return (
    <table className="table table-success table-striped table-container">
      <TableHeader headerData={HeaderKeys} />
      <TableBody
        bodyData={stateData}
        headerKeys={tableBodyKeys}
      />
    </table>
  );
};

export default PersonaDetail;
