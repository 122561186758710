import React, { useState } from 'react';
import axios from 'axios';
import { DAILY_ZONES, WEEK_DAYS } from "../../staticData";
import InputField from '../../components/common/inputField';
import ResponseModal from '../../components/generalResponseModal';

const RemoveMovement = ({ weeklyMovement, memberCode, onClose }) => {

    const [day, setDay] = useState('');
    const [zone, setZone] = useState('');
    const [movementName, setMovementName] = useState('select');
    const [movementId, setMovementId] = useState('');
    const [movements, setMovements] = useState([]);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    const handleRemoveMovement = async () => {
        console.log({
            memberCode,
            day,
            zone,
            movementId,
            zoneId: weeklyMovement[day][zone]['zoneId'],
            action: 'remove',
        });

        try {
            const weeklyMovementResp = await axios.put(`${process.env.REACT_APP_MAIN_SERVICE_BASE_URL}/api/v1/weekly-movement/workout`, {
                memberCode,
                zoneId: weeklyMovement[day][zone]['zoneId'],
                action: 'remove',
                movementId,
            });
            const data = weeklyMovementResp.data;
            if (weeklyMovementResp.status === 200) {
                setSuccess(true);
            } else {
                setError(true);
            }
        } catch (error) {
            console.log('error ', error);
            setError(true);
        }
    };

    const handleChange = (key, value) => {
        console.log('change', key, value)
        switch (key) {
            case 'day':
                setDay(value);
                break;

            case 'zone':
                setZone(value);
                setMovements(weeklyMovement[day][value].movements);
                break;

            case 'movementName':
                const selectedMovement = movements.find(m => m.movementId === value);
                setMovementName(selectedMovement.movementName);
                setMovementId(value);
                break;

            default:
                break;
        }
    }

    // if (loading) {
    //     return (
    //         <div className="w-full flex flex-col items-center justify-center backdrop-blur-md px-4">
    //             <h1>Loading...</h1>
    //         </div>
    //     );
    // }

    return (
        <div className="fixed inset-0 bg-opacity-80 backdrop-blur-sm flex items-center justify-center z-50">
            <div className="bg-black p-4 border rounded-md w-auto">

                <div className="flex flex-row items-center justify-between">
                    <h2 className="font-bold text-xxl pr-4">
                        Remove Movement
                    </h2>
                    <button
                        className="w-full sm:w-auto border border-gray-300 rounded-md bg-generalButton text-white text-sm py-1 px-2 mb-2 sm:mb-0 sm:mr-2"
                        onClick={onClose}
                    >Close</button>
                </div>
                {!(success || error) && <div className="flex flex-col items-center justify-between pt-2">
                    <InputField
                        label="Day"
                        name="day"
                        value={day}
                        onChange={handleChange}
                        options={WEEK_DAYS.map((d) => ({ value: d, label: d }))}
                        type="dropdown"
                        customClassName="flex flex-row w-4/5 items-center justify-between mb-2"
                        inputBoxCustomClassName="w-full ml-2 border"
                    />
                    <InputField
                        label="Zone"
                        name="zone"
                        value={zone}
                        disabled={!day}
                        onChange={handleChange}
                        options={DAILY_ZONES.map((z) => ({ value: z, label: z }))}
                        type="dropdown"
                        customClassName="flex flex-row w-4/5 items-center justify-between mb-2"
                        inputBoxCustomClassName="w-full ml-2 border"
                    />
                    <InputField
                        label="Movement Name"
                        name="movementName"
                        value={movementName}
                        onChange={handleChange}
                        disabled={!zone}
                        type="dropdown"
                        customClassName="flex flex-row w-4/5 items-center justify-between mb-2"
                        options={movements.map((m) => ({ value: m.movementId, label: m.movementName }))}
                        inputBoxCustomClassName="w-full ml-2 border"
                    />
                    <button
                        className="sm:w-2/5 border border-gray-300 rounded-md bg-generalButton text-white text-sm my-3 py-1 sm:mb-0 sm:mr-2"
                        onClick={handleRemoveMovement}
                        disabled={!day || !zone || !movementName}
                    >
                        Remove Movement
                    </button>
                </div>}
                {success && <ResponseModal success={true} message="Movement removed successfully" close={onClose} />}
                {error && <ResponseModal success={false} message="Failed to remove movement" close={onClose} />}
            </div>
        </div>
    );
}

export default RemoveMovement;