import axios from "axios";
import { useEffect, useState } from "react";
import { FaHome } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { MONTH_LIST } from "../../staticData";

const MealDetail = ({ meal, close }) => {
    console.log('meal ', meal.mealNutritionAnalysis.mealName);

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-opacity-90 backdrop-blur-3xl bg-black/90">
            <div className="flex flex-col border p-2 w-11/12 md:w-1/2 mx-auto my-auto rounded-lg bg-gray">
                <div className="grid grid-cols-1 md:grid-cols-5">
                    <h1 className="text-xl font-bold col-span-4">{meal.mealNutritionAnalysis.mealName}</h1>
                    <button
                        className="text-sm col-span-1"
                        onClick={close}
                    >
                        close
                    </button>
                </div>
                <div className="flex flex-col pt-2">
                    <img src={meal.mealUrl} alt={meal.mealName} className="rounded-md w-full h-60 object-cover" />
                    <div className="flex-col ml-2 mt-2">
                        <h1 className="text-sm font-bold">{meal.mealType}</h1>
                        <div className="flex flex-col text-sm">
                            <span>calories: {meal.mealNutritionAnalysis.calories}</span>
                            <span>carbs: {meal.mealNutritionAnalysis.carbohydrates}</span>
                            <span>protein: {meal.mealNutritionAnalysis.protein}</span>
                            <span>fat: {meal.mealNutritionAnalysis.fat}</span>
                        </div>
                    </div>
                    <div>
                        {meal.mealNutritionAnalysis.feedback}
                    </div>
                </div>
            </div>
        </div>
    );
};

const Nutrition = () => {
    const [nutritionData, setNutritionData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showMealDetail, setShowMealDetail] = useState(false);
    const [expandedLevel, setExpandedLevel] = useState(null); // Track the currently expanded level
    const [selectedMeal, setSelectedMeal] = useState(null);
    const { clientCode } = useParams();

    const navigate = useNavigate();

    const fetchNutritionData = async () => {
        setLoading(true);
        try {
            const nutritionDataResp = await axios.get(`${process.env.REACT_APP_MAIN_SERVICE_BASE_URL}/api/v1/nutrition/meals?memberCode=${clientCode}`);
            const data = nutritionDataResp.data;
            if (nutritionDataResp.status === 200) {
                setNutritionData(data.data);
            }
        } catch (error) {
            console.error('Error fetching nutrition data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchNutritionData();
    }, []);

    const handleLevelClick = (level) => {
        console.log('expandedLevel', expandedLevel);
        setExpandedLevel(level === expandedLevel ? null : level); // Toggle expansion
    };

    const handleMealClick = (meal) => {
        setSelectedMeal(meal);
        setShowMealDetail(true);
    };

    if (loading) {
        return (
            <div className="w-full flex flex-col items-center justify-center backdrop-blur-md px-4">
                <h1>Loading...</h1>
            </div>
        );
    }

    return (
        <div className="flex flex-col items-center justify-center backdrop-blur-md text-white p-2 w-full">
            <div className="flex flex-row items-center justify-between w-full grid grid-cols-10">
                <h1 className="text-3xl font-bold col-span-9 flex flex-row mx-auto">Nutrition</h1>
                <FaHome
                    size={20}
                    onClick={() => navigate('/coach-tools')}
                />
            </div>
            {nutritionData && nutritionData.length !== 0 && (
                nutritionData.map(nd => (
                    <div key={nd.date} className="w-full px-4">
                        <div
                            className="flex flex-row items-center justify-between text-xl font-bold cursor-pointer px-2 mt-2 w-full md:w-2/5 border rounded-lg"
                            onClick={() => handleLevelClick(nd.date)}
                        >
                            <span>{nd.date.split("/")[0]} {MONTH_LIST[parseInt(nd.date.split("/")[1])].slice(0, 3)} {nd.date.split("/")[2]}</span>
                            <span className="text-sm font-normal">{nd.meals.length} meals</span>
                            <span className="text-sm font-normal">{nd.totalCalories} calories</span>
                        </div>
                        {expandedLevel === nd.date && (
                            <div className="grid grid-cols-1 md:grid-cols-3 gap-2 w-4/5 mt-2">
                                {nd.meals.map(meal => (
                                    <div key={meal._id} className="flex flex-col border-gray-500 p-2">
                                        <div className="flex flex-col md:flex-row pt-2">
                                            <img
                                                src={meal.mealUrl}
                                                alt={meal.mealName}
                                                className="rounded-md w-full md:w-40 h-40 object-cover"
                                            />
                                            <div className="flex-col ml-2 mt-2">
                                                <h1 className="text-sm font-bold">{meal.mealNutritionAnalysis.mealName.slice(0, 10)}...</h1>
                                                <h1 className="text-sm font-bold">{meal.mealType}</h1>
                                                <div className="flex flex-col text-sm">
                                                    <span>calories: {meal.mealNutritionAnalysis.calories}</span>
                                                    <span>carbs: {meal.mealNutritionAnalysis.carbohydrates}</span>
                                                    <span>protein: {meal.mealNutritionAnalysis.protein}</span>
                                                    <span>fat: {meal.mealNutritionAnalysis.fat}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <p className="mt-1">
                                            {meal.mealNutritionAnalysis.feedback.slice(0, 50)}...
                                            <span className="text-lg font-bold" onClick={() => handleMealClick(meal)}>See more</span>
                                        </p>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                ))
            )}
            {showMealDetail && selectedMeal && (
                <MealDetail
                    meal={selectedMeal}
                    close={() => setShowMealDetail(false)}
                />
            )}
        </div>
    );
}

export default Nutrition;