import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "./common/button";
import TableHeader from "./common/tableHeader";
import TableBody from "./common/tableBody";
import axios from "axios";
import {
  calculateAverageUserPayment,
  processRevenueApiResponse,
  getMembersWithNextPayment,
} from "../utils";

const keys = ["month", "year", "revenue", "expenses", "profit"];
const capKeys = ["Month", "Year", "Revenue", "Expenses", "Profit"];
const totalKeys = ["revenue", "expenses", "profit"];

const Finances = () => {
  const isAdmin = localStorage.getItem("isAdmin") === "true";  
  const navigate = useNavigate();

  if (!isAdmin) {
    navigate("/");
  }

  const [revenueAPIData, setRevenueAPIData] = useState([]);
  const [showPaymentDue, setShowPaymentDue] = useState(false);
  const [revenueData, setRevenueData] = useState([]);
  const [paymentDueData, setPaymentDueData] = useState([]);
  const [avgUserRevenue, setAvgUserRevenue] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const revResponse = await axios.get(
          `${process.env.REACT_APP_ANALYTICS_SERVICE_BASE_URL}/finance/revenue`
        );

        setRevenueAPIData(revResponse.data);

        const clientCodeRes = await axios.get(
          `${process.env.REACT_APP_ANALYTICS_SERVICE_BASE_URL}/clients`
        );

        // const clientCodeList= getClientCodeList(clientCodeRes.data);
        // console.log('list', clientCodeList)
        // setActiveClientCodeList(clientCodeList);

        const revenueData = await processRevenueApiResponse(revResponse.data);
        setRevenueData(revenueData);

        const avgUserRevenue = await calculateAverageUserPayment(
          revResponse.data
        );
        setAvgUserRevenue(avgUserRevenue);

        const pdData = await getMembersWithNextPayment(
          revResponse.data,
          clientCodeRes.data
        );

        setPaymentDueData(pdData);
        // console.log(pdData);
      } catch (err) {
        console.log("error in getting revenue: ", err);
      }
    };

    fetchData();
  }, []);

  const handleClick = (year, month) => {
    const currData = revenueAPIData.filter((item) => {
      const { paymentDate } = item;
      const paymentYear = paymentDate.getFullYear();
      const paymentMonth = paymentDate.getMonth();

      return paymentYear === year && paymentMonth === month;
    });

    navigate(`/finance/${year}/${month}`, { state: currData });
  };

  const handleAdd = () => {
    navigate("/finance/add");
  };

  const handleMemberHistory = () => {
    const clients = [...new Set(revenueAPIData.map((item) => item.clientName))];
    const stateData = {
      clients: clients,
      paymentData: revenueAPIData,
    };
    navigate("/finance/member", { state: stateData });
  };

  const handleShowPaymentDue = () => {
    setShowPaymentDue(!showPaymentDue);
  };

  return (
    <div>
      <div className="average-stay">
        An average member pays ₹ {avgUserRevenue} during their stay!
      </div>
      <Button onClick={handleAdd} content="Add New" />
      <Button onClick={handleMemberHistory} content="Member History" />
      <Button onClick={handleShowPaymentDue} content="Payments Due" />

      <div className="table-container">
        <table className="table table-success table-striped table-container">
          <TableHeader headerData={capKeys} showDetail />
          <TableBody
            bodyData={revenueData}
            headerKeys={keys}
            handleClick={handleClick}
            showTotal={totalKeys}
            showDetail
          />
        </table>
      </div>

      {showPaymentDue && (
        <div className="table-container">
          <h3>Payments Due</h3>
          <table className="table table-success table-striped table-container">
            <TableHeader
              headerData={["Client Name", "Next Payment Date", "Last Amount"]}
            />
            <TableBody
              bodyData={paymentDueData}
              headerKeys={["clientName", "nextPaymentDate", "lastAmount"]}
            />
          </table>
        </div>
      )}
    </div>
  );
};

export default Finances;
