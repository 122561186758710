import React, { useState } from "react";
import Workout from "./settingComponents/Workout";
import Nutrition from "./settingComponents/Nutrition";
import Lifestyle from "./settingComponents/Lifestyle";

const Settings = ({ clientData }) => {
  const [selectedOption, setSelectedOption] = useState("workout");
  const [isOpen, setIsOpen] = useState(false);

  const options = [
    { value: "workout", label: "Workout" },
    { value: "nutrition", label: "Nutrition" },
    { value: "lifestyle", label: "Lifestyle" },
  ];

  const renderComponent = () => {
    switch (selectedOption) {
      case "workout":
        return <Workout clientData={clientData} />;
      case "nutrition":
        return <Nutrition />;
      case "lifestyle":
        return <Lifestyle clientData={clientData} />;
      default:
        return null;
    }
  };

  return (
    <div className="mt-2">
      <div className="relative flex gap-2 items-center">
        <p className="pt-3">Choose What to Update:</p>
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="bg-white border border-gray-300 rounded-md px-4 py-2 inline-flex items-center justify-between w-40"
        >
          <span>
            {options.find((opt) => opt.value === selectedOption)?.label}
          </span>
          <svg
            className="h-5 w-5 text-gray-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
        {isOpen && (
          <div className="absolute left-[175px] top-12 mt-1 w-48 bg-white border border-gray-300 rounded-md shadow-lg">
            {options.map((option) => (
              <button
                key={option.value}
                onClick={() => {
                  setSelectedOption(option.value);
                  setIsOpen(false);
                }}
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
              >
                {option.label}
              </button>
            ))}
          </div>
        )}
      </div>
      <div className="mt-4">{renderComponent()}</div>
    </div>
  );
};

export default Settings;
