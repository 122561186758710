export function getPreviousAndPreviousToPreviousMonth() {
    const monthNames = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
  
    const currentDate = new Date();
    const currentMonthIndex = currentDate.getMonth();
    const previousMonthIndex = (currentMonthIndex - 1 + 12) % 12;
    const previousToPreviousMonthIndex = (currentMonthIndex - 2 + 12) % 12;
  
    const previousMonthName = monthNames[previousMonthIndex];
    const previousToPreviousMonthName = monthNames[previousToPreviousMonthIndex];
  
    return [previousMonthName, previousToPreviousMonthName];
  }
  
  export function convertMonthlySkillPoint(data) {
    const skillOrder = ["Endurance", "Pull", "Squat", "Core", "Push"];
    const counts = [];
  
    skillOrder.forEach(score => {
      const skillData = data.find(item => item.category === score);
      counts.push(skillData ? skillData.score : 0);
    });
  
    return counts;
  }