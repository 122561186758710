import React from "react";
import { format } from "date-fns";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const NutritionCalendar = ({
  selectedDate,
  onSelectDate,
  consumedCalorie,
  totalCalories,
}) => {
  const generateCurrentWeekCalendar = () => {
    const today = new Date();
    const dayOfWeek = today.getDay();
    const diffFromMonday = (dayOfWeek + 6) % 7;
    const firstDayOfWeek = new Date(
      today.setDate(today.getDate() - diffFromMonday)
    );
    const daysOfWeek = Array.from({ length: 7 }, (_, i) => {
      const date = new Date(firstDayOfWeek);
      date.setDate(firstDayOfWeek.getDate() + i);
      return date;
    });

    return daysOfWeek.map((date, index) => {
      const dateString = format(date, "yyyy-MM-dd");
      const isSelected = dateString === selectedDate;
      const percentCompletion = (consumedCalorie / totalCalories) * 100;

      return (
        <div
          key={index}
          className={`relative flex h-[65px] w-fit grow flex-col items-center justify-center gap-0 rounded-[7.5px] ${isSelected ? "bg-black sm:h-32 h-24" : ""}`}
          onClick={() => onSelectDate(dateString)}
        >
          <h3
            className={`text-[15px] leading-[17.32px] ${isSelected ? "text-[#7E87EF]" : "text-[#929292]"}`}
          >
            {format(date, "EEE")}
          </h3>
          <h4
            className={`text-[18px] leading-[17.32px] ${isSelected ? "text-[#7E87EF]" : "text-[#929292]"}`}
          >
            {format(date, "d")}
          </h4>
          <div>
            <CircularProgressbar
              value={percentCompletion}
              circleRatio={0.5}
              strokeWidth={16}
              styles={buildStyles({
                rotation: 0.75,
                strokeLinecap: "round",
                trailColor: "#ffffff1f",
                pathColor: getColor(
                  [
                    { threshold: 25, color: "#e74c3c" },
                    { threshold: 50, color: "#F5C563" },
                    { threshold: 75, color: "#7E87EF" },
                    { threshold: 100, color: "#5ECC7B" },
                  ],
                  percentCompletion
                ),
                textSize: "16px",
                pathTransitionDuration: 0.5,
              })}
              className="mt-1 h-[18px] w-[35.34px]"
            />
          </div>
        </div>
      );
    });
  };

  return (
    <div className="flex flex-row items-center justify-around mb-2 w-full mt-4">
      {generateCurrentWeekCalendar()}
    </div>
  );
};

const getColor = (colors, percentCompletion) => {
  const color = colors.find(({ threshold }) => percentCompletion <= threshold);
  return color ? color.color : colors[colors.length - 1].color;
};

export default NutritionCalendar;
