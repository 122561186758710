import axios from "axios";
import { useState } from "react";
import InputField from "../../components/common/inputField";
import ResponseModal from "../../components/generalResponseModal";

const RemoveTask = ({ memberCode, onClose, lifestyle }) => {

    const [circle, setCircle] = useState('');
    const [taskId, setTaskId] = useState('');
    const [tasks, setTasks] = useState([]);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);


    const handleRemoveTask = async () => {
        console.log({
            memberCode,
            taskId,
            circle,
            action: 'remove',
        });
        try {
            setLoading(true);
            const response = await axios.put(
                `${process.env.REACT_APP_MAIN_SERVICE_BASE_URL}/api/v1/lifestyle`,
                {
                    memberCode,
                    taskId,
                    circle,
                    action: 'remove',
                }
            );
            if (response.status === 200) {
                setSuccess(true);
                setLoading(false);
            } else {
                setError(true);
                setLoading(false);
            }
        } catch (error) {
            console.error('Error removing task:', error);
            setLoading(false);
            setError(true);
        }
    };

    const handleChange = (key, value) => {
        switch (key) {
            case 'circle':
                setCircle(value);

                const circle = lifestyle.filter(circle => circle.name === value);
                setTasks(circle[0].tasks);
                break;
            case 'taskId':
                setTaskId(value);
                break;
            default:
                console.log('Invalid key');
                break;
        }
    };

    if (loading) {
        return (
            <div className="fixed inset-0 backdrop-blur-sm bg-blend-overlay flex items-center justify-center z-50">
                <div className="bg-black p-4 border rounded-md w-auto">
                    <h2 className="font-bold text-xxl">Removing Task...</h2>
                </div>
            </div>
        );
    }

    return (
        <div className="fixed inset-0 backdrop-blur-sm bg-blend-overlay flex items-center justify-center z-50">
            <div className="bg-black p-4 border rounded-md w-auto">
                <div className="flex flex-row items-center justify-between">
                    <h2 className="font-bold text-xxl text-white">
                        Remove Task
                    </h2>
                    <button
                        className="w-full sm:w-auto border border-gray-300 rounded-md bg-generalButton text-sm py-1 px-2 mb-2 sm:mb-0 sm:mr-2"
                        onClick={onClose}
                    >
                        Close
                    </button>
                </div>
                {!(success || error) && (
                    <div className="flex flex-col items-center justify-between pt-2 text-white">
                        <InputField
                            label="Circle"
                            name="circle"
                            value={circle}
                            onChange={handleChange}
                            options={[
                                { value: 'Morning Circle' },
                                { value: 'Afternoon Circle' },
                                { value: 'Evening Circle' },
                            ]}
                            type="dropdown"
                            customClassName="flex flex-row w-4/5 items-center justify-between mb-2 text-white"
                            inputBoxCustomClassName="w-full ml-2 border"
                            optionCustomClass="text-black"
                        />
                        <InputField
                            label="Task"
                            name="taskId"
                            value={taskId}
                            onChange={handleChange}
                            options={tasks.map((task) => ({
                                value: task.taskId,
                                label: task.taskName,
                            }))}
                            type="dropdown"
                            customClassName="flex flex-row w-4/5 items-center justify-between mb-2 text-white"
                            inputBoxCustomClassName="w-full ml-2 border"
                            optionCustomClass="text-black"
                        />
                        <button
                            className="sm:w-2/5 border border-gray-300 rounded-md bg-generalButton text-sm my-3 py-1 sm:mb-0 sm:mr-2"
                            onClick={handleRemoveTask}
                            disabled={!taskId || !circle}
                        >
                            Remove Task
                        </button>
                    </div>
                )}
                {success && <ResponseModal success={true} message="Task removed successfully" close={onClose} />}
                {error && <ResponseModal success={false} message="Failed to remove task" close={onClose} />}
            </div>
        </div>
    );
};

export default RemoveTask;