import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaHome, FaPlus, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import AddMovementToZoneNew from "./AddMovementNew";

const WeeklySchedule = ({ clientData }) => {
  const [weeklyMovement, setWeeklyMovement] = useState({});
  const [loading, setLoading] = useState(true);
  const [expandedLevel, setExpandedLevel] = useState(null);
  const [editedMovements, setEditedMovements] = useState({});
  const [showAddMovementModal, setShowAddMovementModal] = useState(false);
  const [selectedDay, setSelectedDay] = useState("");
  const [selectedZone, setSelectedZone] = useState("");

  const { code } = clientData;
  const navigate = useNavigate();

  const fetchWeeklyMovement = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_MAIN_SERVICE_BASE_URL}/api/v1/weekly-movement/workout?memberCode=${code}`
      );
      if (response.status === 200) {
        setWeeklyMovement(response.data.data.weeklyWorkout || {});
      }
    } catch (error) {
      console.error("Error fetching weekly movement data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchWeeklyMovement();
  }, []);

  const handleLevelClick = (level) => {
    setExpandedLevel(level === expandedLevel ? null : level);
  };

  const handleMovementChange = (day, zone, movementId, field, value) => {
    setEditedMovements((prev) => ({
      ...prev,
      [`${day}-${zone}-${movementId}`]: {
        ...prev[`${day}-${zone}-${movementId}`],
        [field]: value,
      },
    }));
  };

  const handleSaveMovement = async (day, zone, movement) => {
    const editKey = `${day}-${zone}-${movement.movementId}`;
    const editedMovement = editedMovements[editKey] || {};

    try {
      await axios.put(
        `${process.env.REACT_APP_MAIN_SERVICE_BASE_URL}/api/v1/weekly-movement/workout`,
        {
          memberCode: code,
          zoneId: weeklyMovement[day][zone].zoneId,
          action: "update",
          movementId: movement.movementId,
          movementName: editedMovement.movementName || movement.movementName,
          movementTime: `${editedMovement.time || movement.time.split(" ")[0]} mins`,
        }
      );
      fetchWeeklyMovement();
      setEditedMovements((prev) => {
        const newState = { ...prev };
        delete newState[editKey];
        return newState;
      });
    } catch (error) {
      console.error("Error updating movement:", error);
    }
  };

  const handleDiscardChanges = (day, zone, movementId) => {
    setEditedMovements((prev) => {
      const newState = { ...prev };
      delete newState[`${day}-${zone}-${movementId}`];
      return newState;
    });
  };

  const handleDeleteMovement = async (day, zone, movementId) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_MAIN_SERVICE_BASE_URL}/api/v1/weekly-movement/workout`,
        {
          memberCode: code,
          zoneId: weeklyMovement[day][zone].zoneId,
          action: "remove",
          movementId,
        }
      );
      fetchWeeklyMovement(); // Refetch data after deletion
    } catch (error) {
      console.error("Error deleting movement:", error);
    }
  };
  const handleAddMovementClick = (day, zone) => {
    setSelectedDay(day);
    setSelectedZone(zone);
    setShowAddMovementModal(true);
  };
  const closeModal = () => {
    setShowAddMovementModal(false);
    setSelectedDay("");
    setSelectedZone("");
    fetchWeeklyMovement();
  };

  if (loading) {
    return (
      <div className="w-full flex flex-col items-center justify-center backdrop-blur-md px-4">
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center backdrop-blur-md text-black p-2 w-full">
      <div className="flex flex-row items-center justify-between w-full px-4"></div>
      {Object.keys(weeklyMovement).length === 0 ? (
        <h1 className="text-xl mt-4">No weekly schedule found</h1>
      ) : (
        <div className="w-full px-2 md:px-4">
          {Object.keys(weeklyMovement).map((day) => (
            <div key={day} className="flex flex-col mb-4">
              <div
                className="flex flex-row border px-2 py-1 justify-between rounded-md items-center cursor-pointer"
                onClick={() => handleLevelClick(day)}
              >
                <h2 className="text-lg md:text-xl font-bold px-2 py-2 w-full">
                  {day}
                </h2>
              </div>
              {expandedLevel === day && (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-6">
                  {Object.entries(weeklyMovement[day]).map(
                    ([zone, zoneData]) =>
                      ["Morning Zone", "Evening Zone"].includes(zone) && (
                        <div key={zone} className="">
                          <div className="flex justify-between items-center mb-4">
                            <h3 className="font-bold text-lg md:text-xl">
                              {zone}
                            </h3>
                            <button
                              className="bg-black text-white text-xs md:text-sm rounded-md px-2 py-1 flex items-center"
                              onClick={() => handleAddMovementClick(day, zone)}
                            >
                              Add
                            </button>
                          </div>
                          <div className="space-y-2">
                            {zoneData.movements.map((movement) => {
                              const editKey = `${day}-${zone}-${movement.movementId}`;
                              const editedMovement =
                                editedMovements[editKey] || {};
                              return (
                                <div
                                  key={movement.movementId}
                                  className="flex flex-col border-1 rounded border-[#EEEDEB] p-2 relative"
                                >
                                  <div className="absolute top-2 right-2">
                                    <FaTrash
                                      className="text-red-500 cursor-pointer"
                                      onClick={() =>
                                        handleDeleteMovement(
                                          day,
                                          zone,
                                          movement.movementId
                                        )
                                      }
                                    />
                                  </div>
                                  <select
                                    value={
                                      editedMovement.movementName ||
                                      movement.movementName
                                    }
                                    onChange={(e) =>
                                      handleMovementChange(
                                        day,
                                        zone,
                                        movement.movementId,
                                        "movementName",
                                        e.target.value
                                      )
                                    }
                                    className="p-1 border rounded w-full mb-2 mt-6"
                                  >
                                    <option value="Walking">Walking</option>
                                    <option value="Dynamic Stretch">
                                      Dynamic Stretch
                                    </option>
                                    <option value="Workout">Workout</option>
                                    <option value="Rest">Rest</option>
                                  </select>
                                  <select
                                    value={
                                      editedMovement.time ||
                                      movement.time.split(" ")[0]
                                    }
                                    onChange={(e) =>
                                      handleMovementChange(
                                        day,
                                        zone,
                                        movement.movementId,
                                        "time",
                                        e.target.value
                                      )
                                    }
                                    className="p-1 border rounded w-full mb-2"
                                  >
                                    <option value="15">15 mins</option>
                                    <option value="30">30 mins</option>
                                    <option value="45">45 mins</option>
                                    <option value="60">60 mins</option>
                                  </select>
                                  <div className="flex justify-end space-x-2">
                                    <button
                                      onClick={() =>
                                        handleSaveMovement(day, zone, movement)
                                      }
                                      className="bg-black w-full text-white px-[6px] py-1 rounded"
                                    >
                                      Save
                                    </button>
                                    <button
                                      onClick={() =>
                                        handleDiscardChanges(
                                          day,
                                          zone,
                                          movement.movementId
                                        )
                                      }
                                      className="bg-white w-full text-black px-[6px] py-1 rounded"
                                    >
                                      Discard
                                    </button>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
      {showAddMovementModal && (
        <AddMovementToZoneNew
          memberCode={code}
          weeklyMovement={weeklyMovement}
          onClose={closeModal}
          selectedDay={selectedDay}
          selectedZone={selectedZone}
          fetchWeeklyMovement={fetchWeeklyMovement}
        />
      )}
    </div>
  );
};

export default WeeklySchedule;
