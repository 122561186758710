import CircularProgress from '@mui/material/CircularProgress';

const ResponseModal = ({ message }) => {
    return (
        <div
            style={{
                position: "relative",  // Relative to its parent container
                width: "80%",         // Full width of the parent container
                height: "80%",        // Full height of the parent container
            }}
        >
            <div
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    backdropFilter: "blur(2px)",
                    zIndex: 1000,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <div
                    style={{
                        backgroundColor: "white",
                        padding: "20px",
                        zIndex: 1001,
                        textAlign: "center",
                        borderRadius: "8px",
                        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                    }}
                >
                    <CircularProgress />
                    <p style={{ marginTop: "20px" }}>{message}</p>
                </div>
            </div>
        </div>
    );
};

export default ResponseModal;
