import React, { useEffect, useState } from 'react';
import CalendarTile from './CalenderTile';
import ProgressBar from './ProgressBar';

function Calendar({
  completionHistory,
  isSummaryPage,
  selectedDate,
  setSelectedDate,
}) {
  const [reversedCompletionHistory, setReversedCompletionHistory] = useState([]);
  const [percentCompletionOfSelectedDate, setPercentCompletionOfSelectedDate] = useState(null);

  useEffect(() => {
    if (completionHistory && completionHistory.length > 0) {
      setReversedCompletionHistory(completionHistory.slice().reverse());
    }
  }, [completionHistory]);

  useEffect(() => {
    try {
      const todayDate = reversedCompletionHistory[reversedCompletionHistory.length - 1]?.date;
      if (!selectedDate) {
        setSelectedDate(todayDate);
      }
    } catch (err) {
      console.error('Error occurred while setting selected date:', err);
    }
  }, [selectedDate, reversedCompletionHistory, setSelectedDate]);

  useEffect(() => {
    const percentCompletionHistory = completionHistory?.find(
      (history) => history.date === selectedDate,
    );
    setPercentCompletionOfSelectedDate(
      percentCompletionHistory?.completionPercentage ?? null,
    );
  }, [completionHistory, selectedDate]);

  return (
    <div className="flex flex-col w-full space-y-4">
      {/* ProgressBar Component in the first row */}
      <div className="flex justify-center">
        <div className="w-full max-w-3xl">
          {percentCompletionOfSelectedDate !== null && (
            <ProgressBar progress={percentCompletionOfSelectedDate} />
          )}
        </div>
      </div>
      {/* CalendarTile Components in the second row */}
      <div className="flex justify-center">
        <div className="w-full max-w-3xl">
          <div className="flex flex-row items-center justify-around">
            {reversedCompletionHistory.map((history) => (
              <CalendarTile
                key={history.date}
                date={history.date}
                percentCompletion={history.completionPercentage}
                setSelectedDate={setSelectedDate}
                isToday={
                  history.date ===
                  reversedCompletionHistory[reversedCompletionHistory.length - 1]
                    ?.date
                }
                isSelected={history?.date === selectedDate}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Calendar;
