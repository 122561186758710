import React, { useEffect, useState } from "react";
import axios from "axios";

import InputField from "./common/inputField";

import "../styles/fundManagementForm.css";
import { useNavigate } from "react-router-dom";

const FundManagementForm = () => {
  const isAdmin = localStorage.getItem("isAdmin") === "true";  
  const navigate = useNavigate();

  if (!isAdmin) {
    navigate("/");
  }

  const [mode, setMode] = useState("revenue"); // Default to revenue mode

  const [formData, setFormData] = useState({
    receiverName: "",
    payeeName: "",
    amount: 0,
    clientName: "",
    paymentDate: "",
    membershipStartDate: "",
    membershipEndDate: "",
    expenseDetail: "",
  });

  const [payeeNameOptions, setPayeeNameOptions] = useState([]);
  const [receiverNameOptions, setReceiverNameOptions] = useState([]);
  const [clientNameOptions, setClientNameOptions] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const handleModeChange = (newMode) => {
    setMode(newMode);
  };

  const fetchData = async () => {
    setReceiverNameOptions([
      { value: "Pranav", label: "Pranav" },
      { value: "Rishi", label: "Rishi" },
    ]);
    setPayeeNameOptions([
      { value: "Pranav", label: "Pranav" },
      { value: "Rishi", label: "Rishi" },
    ]);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_ANALYTICS_SERVICE_BASE_URL}/member`
      );
      const data = await response.json();
      const clientNameList = [];

      for (let item of data) {
        clientNameList.push({
          value: `${item.name}-${item.code}`,
          label: item.name,
        });
      }
      if (clientNameList.length > 0) {
        setClientNameOptions(clientNameList);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleChange = (name, value) => {
    console.log(name, value);

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    console.log("form data", formData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    let url = `${process.env.REACT_APP_ANALYTICS_SERVICE_BASE_URL}/finance`;
    let payload = {};

    if (mode === "expense") {
      url += "/expense";
      const { payeeName, paymentDate, expenseDetail, amount } = formData;
      payload = {
        payeeName,
        paymentDate,
        expenseDetail,
        amount,
      };
    } else if (mode === "revenue") {
      url += "/revenue";
      const {
        receiverName,
        amount,
        clientName,
        paymentDate,
        membershipStartDate,
        membershipEndDate,
      } = formData;
      payload = {
        receiverName,
        amount,
        clientName,
        paymentDate,
        membershipStartDate,
        membershipEndDate,
      };
    }

    try {
      const response = await axios.post(url, payload);

      if (response.status === 201) {
        resetForm();
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const validateForm = () => {
    // Add your input validation logic here
    // For example, check if required fields are not empty
    // You can also display error messages to the user

    return true; // Return true if validation passes, otherwise false
  };

  const resetForm = () => {
    setFormData({
      receiverName: "",
      payeeName: "",
      amount: 0,
      clientName: "",
      clientCode: "",
      paymentDate: "",
      membershipStartDate: "",
      membershipEndDate: "",
      expenseDetail: "",
    });
  };

  return (
    <form className="fund-form">
      <div className="form-group-buttons">
        <button
          type="button"
          className={`btn btn-secondary ${
            mode === "revenue" ? "btn-info" : ""
          }`}
          onClick={() => handleModeChange("revenue")}
        >
          Revenue
        </button>
        <button
          type="button"
          className={`btn btn-secondary ${
            mode === "expense" ? "btn-info" : ""
          }`}
          onClick={() => handleModeChange("expense")}
        >
          Expense
        </button>
      </div>

      {mode === "revenue" ? (
        <>
          <InputField
            onChange={handleChange}
            type="dropdown"
            label="Receiver"
            name="receiverName"
            placeholder="receiver's name"
            value={formData.receiverName}
            options={receiverNameOptions}
          />

          <InputField
            onChange={handleChange}
            type="number"
            label="Amount"
            name="amount"
            placeholder="amount"
            value={formData.amount}
          />

          <InputField
            onChange={handleChange}
            type="dropdown"
            label="Client Name"
            name="clientName"
            placeholder="client's name"
            value={formData.clientName}
            options={clientNameOptions}
          />

          <InputField
            onChange={handleChange}
            type="date"
            label="Payment Date"
            name="paymentDate"
            placeholder="payment date"
            value={formData.paymentDate}
          />

          <div className="form-group-inner">
            <div className="membership-header">Membership</div>
            <InputField
              onChange={handleChange}
              type="date"
              label="Starts From"
              name="membershipStartDate"
              placeholder="membership start date"
              value={formData.membershipStartDate}
            />
            <InputField
              onChange={handleChange}
              type="date"
              label="Ends On"
              name="membershipEndDate"
              placeholder="membership end date"
              value={formData.membershipEndDate}
            />
          </div>
        </>
      ) : (
        <>
          <InputField
            onChange={handleChange}
            label="Payee"
            name="payeeName"
            type="dropdown"
            placeholder="payee's name"
            value={formData.payeeName}
            options={payeeNameOptions}
          />
          <InputField
            onChange={handleChange}
            type="date"
            label="Payment Date"
            name="paymentDate"
            placeholder="payment date"
            value={formData.paymentDate}
          />
          <InputField
            onChange={handleChange}
            type="number"
            label="Amount"
            name="amount"
            placeholder="amount"
            value={formData.amount}
          />
          <InputField
            onChange={handleChange}
            type="text"
            label="Expense Detail"
            name="expenseDetail"
            placeholder="expense detail"
            value={formData.expenseDetail}
          />
        </>
      )}

      <button type="submit" className="btn btn-primary" onClick={handleSubmit}>
        Submit
      </button>
    </form>
  );
};

export default FundManagementForm;
