import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../styles/monthDetail.css";

const MonthDetail = () => {
  const isAdmin = localStorage.getItem("isAdmin") === "true";  
  const navigate = useNavigate();

  if (!isAdmin) {
    navigate("/");
  }

  const location = useLocation();
  const [showNewMembers, setShowNewMembers] = useState(false);
  const [showChurnedMembers, setShowChurnedMembers] = useState(false);

  const handleClick = (section) => {
    if (section === "newMembers") {
      setShowNewMembers(!showNewMembers);
    } else if (section === "churnedMembers") {
      setShowChurnedMembers(!showChurnedMembers);
    }
  };

  const stateData = location.state;

  const { churnedMemberCount, churnedMembers, newMembers, newMemberCount } =
    stateData["currentData"];

  const memberCodeName = stateData["memberCodeName"];

  return (
    <div>
      {newMemberCount !== 0 && (
        <div className="detailSection">
          <div
            className="detailSectionHeader"
            onClick={() => handleClick("newMembers")}
          >
            <span>New Members</span>
            <span>{newMemberCount}</span>
          </div>{" "}
          {showNewMembers && (
            <div className="detailSectionBody">
              {newMembers.map((member) => (
                <div key={member}>
                  <span>{memberCodeName[member]}</span>
                  {/* <span>{member} </span> */}
                </div>
              ))}
            </div>
          )}
        </div>
      )}
      {churnedMemberCount !== 0 && (
        <div className="detailSection">
          <div
            className="detailSectionHeader"
            onClick={() => handleClick("churnedMembers")}
          >
            <span>Churned Members</span>
            <span>{churnedMemberCount}</span>
          </div>
          {showChurnedMembers && (
            <div className="detailSectionBody">
              {churnedMembers.map((member) => (
                <span key={member}>{memberCodeName[member]}</span>
                // <span>{member} </span>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MonthDetail;
