import axios from "axios";
import React, { useEffect, useState } from "react";
import InputField from "../../components/common/inputField";
import { useNavigate, useParams } from "react-router-dom";

import { IoIosRadioButtonOn, IoIosRadioButtonOff } from "react-icons/io";

import {
  ALL_AVAILABLE_COACHES,
  ALL_AVAILABLE_EQUIPMENTS,
  ALL_AVAILABLE_MVTM_BLACKLIST_TAGS,
  ALL_AVAILABLE_MVTM_LEVEL_TAGS,
  DEFAULT_SECTION_FORMATS,
  ROUND_OPTIONS,
  SECTION_CODE_NAME,
} from "../../staticData";
import WeeklySchedule from "../weeklyPlan";
import MessageModal from "../../components/MessageModal";

const UpdateClientData = () => {
  const navigate = useNavigate();

  const navigateToCoachTools = () => {
    navigate("/coach-tools");
  };

  const { clientCode } = useParams();

  const [clientData, setClientData] = useState({});

  const [clientEquipments, setClientEquipments] = useState([]);
  const [clientSections, setClientSections] = useState([]);
  const [clientSectionFormat, setClientSectionFormat] = useState([]);
  const [clientMetconDuration, setClientMetconDuration] = useState("");
  const [clientBlackListTags, setClientBlackListTags] = useState([]);
  const [clientMvmtLevelTags, setClientMvmtLevelTags] = useState([]);
  const [clientCoach, setClientCoach] = useState("");

  const [apiStatus, setApiStatus] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isWorkoutModalOpen, setIsWorkoutModalOpen] = useState(false);

  const fetchClientData = async () => {
    try {
      const clientDataResponse = await axios.get(
        `${process.env.REACT_APP_INSIGHT_SERVICE_BASE_URL}/client/coachMember?code=${clientCode}`
      );
      const {
        equipment,
        sections,
        format,
        metconDuration,
        blackListedTags,
        mvmtLevelTags,
        coach,
      } = clientDataResponse.data;

      setClientData(clientDataResponse.data);
      if (equipment) {
        setClientEquipments(equipment);
      } else {
        setClientEquipments([]);
      }

      if (format) {
        setClientSectionFormat(format);
      } else {
        setClientSectionFormat([]);
      }

      if (sections && sections.length > 0) {
        setClientSections(sections[0]);
      } else {
        setClientSections([]);
      }

      if (metconDuration) {
        setClientMetconDuration(metconDuration);
      } else {
        setClientMetconDuration(0);
      }

      if (mvmtLevelTags) {
        setClientMvmtLevelTags(mvmtLevelTags);
      }

      if (coach) {
        setClientCoach(coach);
      }

      if (blackListedTags) {
        setClientBlackListTags(blackListedTags);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (clientCode) {
      fetchClientData();
    }
  }, [clientCode]);

  const handleChange = (name, value) => {
    console.log(name, value);
    if (name.startsWith("sectionFormatRounds-")) {
      const sectionCode = name.split("-")[1];
      setClientSectionFormat((prevFormats) =>
        updateOrInsertFormat(prevFormats, sectionCode, value)
      );
    } else if (name === "metcon") {
      setClientMetconDuration(parseInt(value) || "");
    }
  };

  const updateUser = async () => {
    try {
      await axios.put(
        `${process.env.REACT_APP_INSIGHT_SERVICE_BASE_URL}/client/coachMember`,
        {
          code: clientCode,
          format: clientSectionFormat,
          equipment: clientEquipments,
          metconDuration: clientMetconDuration,
          section: clientSections,
          blackListedTag: clientBlackListTags,
          mvmtLevelTag: clientMvmtLevelTags,
          coach: clientCoach,
        }
      );
      setApiStatus({ success: true, message: "Update successful!" });
      setIsModalOpen(true);
    } catch (error) {
      console.error(error);
      setApiStatus({ success: false, message: "Update failed!" });
      setIsModalOpen(true);
    }
  };

  const handleToggleGeneral = (data, type) => {
    switch (type) {
      case "equipment":
        toggleEquipment(data, clientEquipments, setClientEquipments);
        break;
      case "sectionFormat":
        toggleSectionFormat(data);
        break;
      case "blackListTag":
        toggleEquipment(data, clientBlackListTags, setClientBlackListTags);
        break;
      case "mvmtLevelTag":
        toggleEquipment(data, clientMvmtLevelTags, setClientMvmtLevelTags);
        break;
      case "coach":
        setClientCoach((prevData) => (prevData !== data ? data : ""));
        break;
      default:
        break;
    }
  };

  const toggleEquipment = (item, array, setArray) => {
    console.log(item, array);
    setArray((prevArray) =>
      prevArray.includes(item)
        ? prevArray.filter((element) => element !== item)
        : [...prevArray, item]
    );
  };

  const isSectionActive = (sectionCode) => {
    const isPresentInSections = clientSections.includes(sectionCode);
    const isPresentInSectionFormat =
      clientSectionFormat &&
      clientSectionFormat.some((csf) => csf.sectionCode === sectionCode);

    // console.log(sectionCode, isPresentInSections || isPresentInSectionFormat);
    return isPresentInSections || isPresentInSectionFormat;
  };

  const toggleSectionFormat = (data) => {
    console.log(data);
    const dataExists = clientSectionFormat
      ? clientSectionFormat.some((csf) => csf.sectionCode === data.sectionCode)
      : false;

    setClientSectionFormat((prevSectionFormat) =>
      dataExists
        ? prevSectionFormat.filter(
            (psf) => psf.sectionCode !== data.sectionCode
          )
        : [
            ...(prevSectionFormat ? prevSectionFormat : []),
            { sectionCode: data.sectionCode, rounds: "2 Rounds" },
          ]
    );

    setClientSections((prevSections) =>
      dataExists
        ? prevSections.filter((ps) => ps !== data.sectionCode)
        : [...prevSections, data.sectionCode]
    );
  };

  const updateOrInsertFormat = (formats, name, value) => {
    const existingFormatIndex = formats.findIndex(
      (format) => format.sectionCode === name
    );

    if (existingFormatIndex !== -1) {
      const updatedFormats = [...formats];
      updatedFormats[existingFormatIndex] = {
        ...updatedFormats[existingFormatIndex],
        rounds: `${value} Rounds`,
      };
      return updatedFormats;
    }

    return [...formats, { sectionCode: name, rounds: value }];
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleUpdateWorkout = async () => {
    try {
      await axios.put(
        `${process.env.REACT_APP_MAIN_SERVICE_BASE_URL}/api/v1/workout/hyper`,
        {
          memberCode: clientCode,
        }
      );
      setApiStatus({ success: true, message: "Workout update successful!" });
    } catch (error) {
      console.error(error);
      setApiStatus({ success: false, message: "Workout update failed!" });
    } finally {
      setIsWorkoutModalOpen(false);
      setIsModalOpen(true);
    }
  };

  const openWorkoutModal = () => {
    setIsWorkoutModalOpen(true);
  };

  const closeWorkoutModal = () => {
    setIsWorkoutModalOpen(false);
  };

  return (
    <div className="flex flex-col p-4 text-white justify-center items-center w-full max-w-7xl mx-auto">
      <div className="flex flex-col sm:flex-row justify-between w-full mb-6">
        <h2 className="text-xl sm:text-2xl mb-4 sm:mb-0">
          Edit Profile - {clientData.name}
        </h2>
        <button
          className="border border-gray-300 rounded-md bg-generalButton text-white text-sm px-4 py-2 w-full sm:w-auto"
          onClick={navigateToCoachTools}
        >
          Close
        </button>
      </div>

      {Object.keys(clientData).length > 0 && (
        <div className="flex flex-col lg:flex-row w-full mb-3">
          <div className="flex flex-col w-full lg:w-1/2 lg:pr-4 mb-6 lg:mb-0">
            <div className="border rounded-md backdrop-blur-md p-3 mb-6">
              <h4 className="text-lg font-semibold mb-4">Equipments</h4>
              <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-2">
                {ALL_AVAILABLE_EQUIPMENTS.map((eq, idx) => (
                  <span
                    key={idx}
                    className={`flex items-center justify-center border rounded-md p-2 cursor-pointer text-sm ${
                      clientEquipments.includes(eq)
                        ? "bg-green text-white"
                        : "bg-white text-black"
                    }`}
                    onClick={() => handleToggleGeneral(eq, "equipment")}
                  >
                    {eq}
                  </span>
                ))}
              </div>
            </div>

            <div className="flex flex-col sm:flex-row gap-4 mb-6">
              <div className="w-full sm:w-1/2 border rounded-md backdrop-blur-md p-3">
                <h4 className="text-lg font-semibold mb-3">Metcon Duration</h4>
                <div className="flex flex-col sm:flex-row items-start sm:items-center">
                  <span className="mr-2 mb-2 sm:mb-0">Time in minutes</span>
                  <InputField
                    name="metcon"
                    value={clientMetconDuration}
                    onChange={handleChange}
                    customClassName="border border-gray-300 rounded-md bg-white text-black text-sm w-full sm:w-24"
                    inputBoxCustomClassName="p-2 border border-gray-300 rounded w-full"
                  />
                </div>
              </div>

              <div className="w-full sm:w-1/2 border rounded-md backdrop-blur-md p-3">
                <h4 className="text-lg font-semibold mb-3">
                  Black Listed Tags
                </h4>
                <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
                  {ALL_AVAILABLE_MVTM_BLACKLIST_TAGS.map((tag, idx) => (
                    <span
                      key={idx}
                      className={`flex items-center justify-center border rounded-md p-2 cursor-pointer text-sm ${
                        clientBlackListTags.includes(tag)
                          ? "bg-green text-white"
                          : "bg-white text-black"
                      }`}
                      onClick={() => handleToggleGeneral(tag, "blackListTag")}
                    >
                      {tag}
                    </span>
                  ))}
                </div>
              </div>
            </div>

            <div className="flex flex-col sm:flex-row gap-4 mb-6">
              <div className="w-full sm:w-1/2 border rounded-md backdrop-blur-md p-3">
                <h4 className="text-lg font-semibold mb-3">Coach Assigned</h4>
                <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
                  {ALL_AVAILABLE_COACHES.map((tag, idx) => (
                    <span
                      key={idx}
                      className={`flex items-center justify-center border rounded-md p-2 cursor-pointer text-sm ${
                        tag === clientCoach
                          ? "bg-green text-white"
                          : "bg-white text-black"
                      }`}
                      onClick={() => handleToggleGeneral(tag, "coach")}
                    >
                      {tag}
                    </span>
                  ))}
                </div>
              </div>

              <div className="w-full sm:w-1/2 border rounded-md backdrop-blur-md p-3">
                <h4 className="text-lg font-semibold mb-3">
                  Movement Level Tags
                </h4>
                <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
                  {ALL_AVAILABLE_MVTM_LEVEL_TAGS.map((tag, idx) => (
                    <span
                      key={idx}
                      className={`flex items-center justify-center border rounded-md p-2 cursor-pointer text-sm ${
                        clientMvmtLevelTags.includes(tag)
                          ? "bg-green text-white"
                          : "bg-white text-black"
                      }`}
                      onClick={() => handleToggleGeneral(tag, "mvmtLevelTag")}
                    >
                      {tag}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="w-full lg:w-1/2 lg:pl-4">
            <div className="border rounded-md backdrop-blur-md p-4">
              <h4 className="text-lg font-semibold mb-4">Section Format</h4>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
                {DEFAULT_SECTION_FORMATS.map((dsf, index) => {
                  let rounds;
                  if (clientSectionFormat) {
                    const clientFormat = clientSectionFormat.find(
                      (format) => format.sectionCode === dsf.sectionCode
                    );
                    rounds = clientFormat ? clientFormat.rounds : dsf.rounds;
                  } else {
                    rounds = dsf.rounds;
                  }

                  return (
                    <div
                      className={`flex flex-col rounded-md p-2 ${
                        isSectionActive(dsf.sectionCode)
                          ? "bg-green text-white"
                          : "bg-white text-black"
                      }`}
                      key={index}
                    >
                      <div className="flex flex-row justify-between items-center mb-2">
                        <span className="text-sm">
                          {SECTION_CODE_NAME[dsf.sectionCode]}
                        </span>
                        <span
                          className="cursor-pointer"
                          onClick={() =>
                            handleToggleGeneral(dsf, "sectionFormat")
                          }
                        >
                          {isSectionActive(dsf.sectionCode) ? (
                            <IoIosRadioButtonOn size={20} />
                          ) : (
                            <IoIosRadioButtonOff size={20} />
                          )}
                        </span>
                      </div>
                      {isSectionActive(dsf.sectionCode) &&
                        isSectionActive(dsf.sectionCode) !== "METCON" && (
                          <InputField
                            onChange={handleChange}
                            type="dropdown"
                            options={ROUND_OPTIONS}
                            name={`sectionFormatRounds-${dsf.sectionCode}`}
                            disabled={!clientSectionFormat}
                            value={parseInt(rounds)}
                            customClassName="border border-gray-300 rounded-md bg-white text-black text-sm w-full"
                            inputBoxCustomClassName="w-4/5"
                          />
                        )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="mt-6 flex flex-col sm:flex-row justify-center w-full sm:w-auto gap-2">
        <button
          className="w-full sm:w-auto border border-gray-300 rounded-md bg-generalButton text-white text-sm py-2 px-4"
          onClick={updateUser}
        >
          Update Member
        </button>
        <button
          className="w-full sm:w-auto border border-gray-300 rounded-md bg-generalButton text-white text-sm py-2 px-4"
          onClick={openWorkoutModal}
        >
          Update Workout
        </button>
      </div>

      {isModalOpen && (
        <MessageModal message={apiStatus.message} close={closeModal} />
      )}

      {isWorkoutModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex justify-center items-center z-50">
          <div className="bg-gray-800 rounded-lg p-6 w-11/12 max-w-md">
            <p className="text-white text-center mb-4">
              Pressing continue will update the latest workout, else the changes
              will be reflected in the next workout
            </p>
            <div className="flex flex-col sm:flex-row justify-center items-center">
              <button
                className="w-full sm:w-auto border border-gray-300 rounded-md bg-generalButton text-white text-sm py-2 px-4 mb-2 sm:mb-0 sm:mr-2"
                onClick={closeWorkoutModal}
              >
                Abort
              </button>
              <button
                className="w-full sm:w-auto border border-gray-300 rounded-md bg-green text-white text-sm py-2 px-4"
                onClick={handleUpdateWorkout}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UpdateClientData;
