import React, { useState } from "react";
import UpdateWorkoutNew from "../UpdateWorkoutNew";
import UpdateClientDataNew from "../UpdateClientDataNew";
import WeeklySchedule from "../newWeeklyPlan/indexnew";

const Workout = ({ clientData }) => {
  const [selectedSection, setSelectedSection] = useState("theme");

  const renderComponent = () => {
    switch (selectedSection) {
      case "theme":
        return <UpdateWorkoutNew clientData={clientData} />;
      case "settings":
        return <UpdateClientDataNew />;
      case "schedule":
        return (
          <div>
            <WeeklySchedule clientData={clientData} />
          </div>
        );
      default:
        return null;
    }
  };

  const buttonClass = (section) =>
    `px-4 py-2 rounded ${
      selectedSection === section
        ? "bg-black text-white"
        : "bg-white text-black"
    }`;

  return (
    <div className="">
      {/* Button Container */}
      <div className="mb-4 flex flex-col md:flex-row md:justify-between gap-2">
        <button
          className={buttonClass("theme")}
          onClick={() => setSelectedSection("theme")}
        >
          Update Workout Theme
        </button>
        <button
          className={buttonClass("settings")}
          onClick={() => setSelectedSection("settings")}
        >
          Update Workout Settings
        </button>
        <button
          className={buttonClass("schedule")}
          onClick={() => setSelectedSection("schedule")}
        >
          Update Weekly Schedule
        </button>
      </div>

      {/* Render the selected component */}
      <div className="mt-4">{renderComponent()}</div>
    </div>
  );
};

export default Workout;
