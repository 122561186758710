import React, { useState, useEffect } from 'react';
import {
  Bar,
  XAxis,
  YAxis,
  ComposedChart,
  ResponsiveContainer,
  CartesianGrid,
  Line,
  Tooltip,
} from 'recharts';
import { CustomizedWorkoutLabel } from './CustomizedWorkoutLabel';
import ParabolicBar from './ParabolicBar';

const WorkoutGraph = ({ apiData }) => {
  const [chartWidth, setChartWidth] = useState(400);

  useEffect(() => {
    const handleResize = () => {
      setChartWidth(window.innerWidth < 430 ? window.innerWidth - 20 : 400);
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const formattedData = apiData?.data?.map((item) => ({
    ...item,
    week: item.week.split('-')[0],
  }));

  const maxCount = Math.max(...formattedData.map(item => item.count));

  return (
    <div className="mx-auto my-auto mt-15 mb-3 max-w-[450px]">
      <ResponsiveContainer width="95%" height={200}>
        <ComposedChart
          width={500}
          height={300}
          data={formattedData}
          margin={{ top: 20, right: 0, left: 0, bottom: 0 }}
        >
          <XAxis
            tick={{ width: 55, fontSize: 13 }}
            padding={{ left: 6, right: 8 }}
            dataKey="week"
            className="pt-7"
            label={<CustomizedWorkoutLabel chartWidth={chartWidth} />}
            interval={'preserveStartEnd'}
          />
          <YAxis
            domain={[0, maxCount + 5]}
            label={{
              value: 'NO. OF WORKOUTS',
              angle: -90,
              fontSize: 12,
              position: 'insideBottomLeft',
              offset: 25,
            }}
            axisLine={false}
            className="mt-7"
            tick={{ fontSize: 12 }}
            tickCount={6}
            interval="preserveEnd"
          />
          <Tooltip
            content={({ payload }) => {
              if (payload && payload.length > 0) {
                const { payload: tooltipPayload } = payload[0];
                return (
                  <div className="custom-tooltip rounded-md border border-[#1c1c1e] bg-black p-2 text-[#808080]">
                    <p>{`Week: ${tooltipPayload.week}`}</p>
                    <p>{`Count: ${tooltipPayload.count}`}</p>
                  </div>
                );
              }
              return null;
            }}
          />
          <CartesianGrid opacity={0.3} vertical={false} />
          <Bar
            dataKey="count"
            fill="#8884d8"
            shape={<ParabolicBar controlPointOffset={15} />}
          />
          <Line
            dot={false}
            type="monotone"
            dataKey="count"
            stroke="#7D86EB"
            strokeWidth={2}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default WorkoutGraph;