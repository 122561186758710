const ResponseModal = ({ success, message, close }) => {
  return (
    <>
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backdropFilter: "blur(40px)",
          zIndex: 1000,
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            // backgroundColor: "blue",
            padding: "20px",
            zIndex: 1001,
            textAlign: "center",
            borderRadius: "8px",
            boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
            color: "white",
          }}
          className="bg-theme"
        >
          <>
            <p style={{ marginTop: "20px" }} className="text-3xl font-bold">
              {message}
            </p>
            <button
              onClick={close}
              style={{ marginTop: "20px" }}
              // className={`${success} ? "bg-generalButton text-white text-sm rounded-md w-full" : ""`}
              className="text-xl font-bold px-2 py-1 bg-generalButton text-white rounded-md"
            >
              Close
            </button>
          </>
        </div>
      </div>
    </>
  );
};

export default ResponseModal;
