import React from "react";
import NewLifestyle from "../newLifestyle/NewLifestyle";

const Lifestyle = ({ clientData }) => {
  const { code } = clientData;
  return (
    <div>
      <NewLifestyle code={code} />
    </div>
  );
};

export default Lifestyle;
