import { useEffect, useState } from "react";
import { IoCloseCircleOutline } from "react-icons/io5";

const RemovedClientListScreen = ({
  setScreenVisibility,
  addClientFunc,
  removedClientList,
}) => {
  const [searchTerm, setSearchTerm] = useState("");

  const [filteredOptions, setFilteredOptions] = useState([]);

  useEffect(() => {
    setFilteredOptions(
      removedClientList?.filter((option) =>
        option?.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, removedClientList]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <div className="absolute z-[100] top-0 left-0 h-screen w-screen flex justify-center backdrop-blur-sm items-center">
      <div className="bg-white border flex flex-col  border-black   rounded-xl mb-32 ">
        <div className="border-b py-2 px-3 flex gap-5 items-center justify-between">
          Removed Client List{" "}
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="w-[160px] px-4 py-1  border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <IoCloseCircleOutline onClick={() => setScreenVisibility(false)} />
        </div>

        <div className="max-h-[230px] pl-3 pr-1 flex flex-col gap-2 overflow-y-scroll mt-2 mb-2">
          {filteredOptions &&
            filteredOptions.map((item) => (
              <div className="flex gap-5 justify-between">
                <div>○ {item?.name} </div>{" "}
                <div
                  onClick={() => addClientFunc(item?.code)}
                  className="border border-green hover:cursor-pointer text-green rounded-md px-2"
                >
                  Add
                </div>{" "}
              </div>
            ))}
        </div>
        {/* <div className="flex justify-between w-full mt-3">
          <div
            onClick={() => }
            className=" rounded-md border border-black w-min px-3 py-1 mt-3 hover:cursor-pointer"
          >
            Cancel
          </div>
          <div
            onClick={() => }
            className="bg-black rounded-md text-white w-min px-3 py-1 mt-3 hover:cursor-pointer"
          >
            Delete
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default RemovedClientListScreen;
