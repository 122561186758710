import React, { useEffect, useState } from "react";
import axios from "axios";

import ProgressBar from "../../components/Calender/ProgressBar";
import NutritionCalendar from "./NutritionCalendar";

// const memberCode = 'PRAN';

const CaloriesTracker = ({ memberCode }) => {
  const [consumedCalorie, setConsumedCalorie] = useState(0);
  const [totalCalories, setTotalCalories] = useState(0);
  const [loading, setLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState(
    new Date().toISOString().split("T")[0]
  ); // Default to today
  const [completedCaloriePercentage, setCompletedCaloriePercentage] =
    useState(0);

  const fetchCaloriesData = async (date) => {
    setLoading(true);
    try {
      // Fetch daily consumed calories data from the API
      const uploadedMealResp = await axios.get(
        `${process.env.REACT_APP_MAIN_SERVICE_BASE_URL}/api/v1/nutrition/meals?memberCode=${memberCode}&date=${date}`
      );
      const data = uploadedMealResp.data;

      if (data?.success) {
        const consumedCalories = data.data.meals.reduce(
          (acc, meal) => acc + meal.mealNutritionAnalysis.calories,
          0
        );
        setConsumedCalorie(consumedCalories);
      }

      // Fetch total planned calories for the selected day
      const planResponse = await axios.get(
        `${process.env.REACT_APP_MAIN_SERVICE_BASE_URL}/api/v1/nutrition/meal-plan?memberCode=${memberCode}`
      );
      const planData = planResponse.data;

      if (planData?.success && planData.data.length > 0) {
        const todayPlan = planData.data.find(
          (plan) => plan.date == date.split("-")[2]
        );
        if (todayPlan) {
          const totalRequiredCalorie = parseInt(todayPlan.totalCalorie, 10);
          setTotalCalories(totalRequiredCalorie);

          const percentage = (consumedCalorie / totalRequiredCalorie) * 100;
          setCompletedCaloriePercentage(percentage);
        } else {
          setTotalCalories(0);
          setCompletedCaloriePercentage(0);
        }
      }
    } catch (error) {
      console.error("Error fetching calories data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCaloriesData(selectedDate);
  }, [selectedDate]);

  const cmp =
    totalCalories > 0 ? Math.round((consumedCalorie / totalCalories) * 100) : 0;

  return (
    <div className="w-full flex flex-col items-center justify-center backdrop-blur-md px-4">
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          <h1 className="text-xl font-bold text-white pt-2">
            Calories Consumption
          </h1>
          <div className="w-full mt-2">
            <ProgressBar
              progress={cmp}
              message={` of total calorie required ${consumedCalorie} / ${totalCalories} kcal`}
            />
            <NutritionCalendar
              selectedDate={selectedDate}
              onSelectDate={(date) => setSelectedDate(date)}
              consumedCalorie={consumedCalorie}
              totalCalories={totalCalories}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default CaloriesTracker;
