import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { MONTH_NAMES } from '../../utils/dateUtils';

const formatData = (data) => {
  return data.map(item => ({
    date: new Date(item.date.year, item.date.month - 1, item.date.day),
    score: item.score
  })).sort((a, b) => a.date - b.date);
};

const customXAxisTickFormatter = (tick) => {
  const date = new Date(tick);
  const day = date.getDate();
  const month = MONTH_NAMES[date.getMonth()];
  return `${month} ${day}`;
};

const FitnessGraph = ({ apiData }) => {
  const data = formatData(apiData.data);

  return (
    <div className="mx-auto my-auto mt-5 max-w-[450px]">
      <ResponsiveContainer width="100%" height={200}>
        <LineChart
          data={data}
          margin={{
            top: 22,
            right: 40,
            left: 20,
            bottom: 2,
          }}
        >
          <XAxis
            dataKey="date"
            tickFormatter={customXAxisTickFormatter}
            interval="preserveStartEnd"
          />
          <YAxis
            domain={[0, 'auto']}
          />
          <Tooltip
            labelFormatter={(value) => customXAxisTickFormatter(value)}
            formatter={(value) => [value.toFixed(1), "Score"]}
          />
          <Line type="monotone" dataKey="score" stroke="#8884d8" activeDot={{ r: 8 }} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default FitnessGraph;