export function getChurnedAndNewMembers(
  attendenceData,
  memberData,
  month,
  year
) {
  let stateData = {};

  let currData = attendenceData.filter(
    (item) => item.month === month && item.year === year
  );
  currData = currData[0];

  stateData["currentData"] = currData;

  // for new members
  let memberCodeName = currData.newMembers
    .map((code) => {
      const member = memberData.find(
        (member) => code !== "KU" && member.code === code
      );
      return member ? { name: member.name, code: member.code } : null;
    })
    .filter(Boolean);

  // for churned members
  let churnedMembers = currData.churnedMembers
    .map((code) => {
      const member = memberData.find((member) => member.code === code);
      return member ? { name: member.name, code: member.code } : null;
    })
    .filter(Boolean);

  memberCodeName = [...memberCodeName, ...churnedMembers];

  const memberCodeNameMap = {};
  memberCodeName.forEach((member) => {
    memberCodeNameMap[member.code] = member.name;
  });

  stateData["memberCodeName"] = memberCodeNameMap;

  return stateData;
}

export async function processRevenueApiResponse(apiResponse) {
  const data = [];

  for (const entry of apiResponse) {
    const paymentDate = new Date(entry.paymentDate);
    const year = paymentDate.getFullYear();
    const month = paymentDate.getMonth() + 1;

    const existingData = data.find(
      (item) => item.year === year && item.month === month
    );

    if (existingData) {
      existingData.revenue += entry.amount;
    } else {
      data.push({
        month,
        year,
        revenue: entry.amount,
      });
    }
  }

  // Sort the data array by year and then by month
  data.sort((a, b) => {
    if (a.year === b.year) {
      return a.month - b.month;
    }
    return a.year - b.year;
  });

  return data;
}

export async function calculateAverageUserPayment(apiResponse) {
  const uniqueClients = new Set();
  let totalAmount = 0;

  for (const entry of apiResponse) {
    uniqueClients.add(entry.clientName);
    totalAmount += entry.amount;
  }

  const averagePayment = Math.ceil(totalAmount / uniqueClients.size);
  return averagePayment;
}

export async function getMembersWithNextPayment(
  revenueAPIdata,
  activeClientListData
) {
  // console.log(activeClientListData)
  const activeClientList = getClientCodeList(activeClientListData);

  // console.log(revenueAPIdata, activeClientList)
  const nextPaymentData = {};

  // change to for loop
  for (let i = 0; i < revenueAPIdata.length; i++) {
    const entry = revenueAPIdata[i];
    const membershipEndDate = new Date(entry.membershipEndDate);

    // Check if the membership is active, within the allowed time frame, and the client code is in activeClientList
    if (activeClientList.includes(entry.clientCode)) {
      // Use membershipEndDate as the base for nextPaymentDate
      const nextPaymentDate = new Date(membershipEndDate);

      // Format nextPaymentDate as "Mon DD YYYY"
      const formattedNextPaymentDate = nextPaymentDate.toLocaleDateString(
        "en-US",
        {
          year: "numeric",
          month: "short",
          day: "numeric",
        }
      );

      const clientName = entry.clientName;
      const lastAmount = entry.amount;

      // Check if the client name already exists in nextPaymentData
      if (
        !nextPaymentData[clientName] ||
        nextPaymentDate > new Date(nextPaymentData[clientName].nextPaymentDate)
      ) {
        // If not, or if this payment is more recent, update the entry
        nextPaymentData[clientName] = {
          clientName: clientName,
          nextPaymentDate: formattedNextPaymentDate,
          lastAmount: lastAmount,
        };
      }
    }
  }

  // Convert the nextPaymentData object into an array of values
  const result = Object.values(nextPaymentData);

  // Sort the result array by nextPaymentDate
  result.sort(
    (a, b) => new Date(a.nextPaymentDate) - new Date(b.nextPaymentDate)
  );

  // console.log(result)

  return result;
}

export function getPersonaTopDetails(apiResponse) {
  const groupedData = apiResponse.reduce((acc, item) => {
    const { userPersona, identityTags, churnTags, source } = item;

    if (!acc[userPersona]) {
      acc[userPersona] = {
        userPersona,
        identityTags: {},
        churnTags: {},
        source: {},
        totalCount: 0,
      };
    }

    // Count the users for each property
    acc[userPersona].totalCount++;

    // Count identityTags
    identityTags.forEach((tag) => {
      if (tag !== "") {
        acc[userPersona].identityTags[tag] =
          (acc[userPersona].identityTags[tag] || 0) + 1;
      }
    });

    // Count churnTags
    churnTags.forEach((tag) => {
      if (tag !== "") {
        acc[userPersona].churnTags[tag] =
          (acc[userPersona].churnTags[tag] || 0) + 1;
      }
    });

    // Count sources
    acc[userPersona].source[source] =
      (acc[userPersona].source[source] || 0) + 1;

    return acc;
  }, {});

  // Step 2: Calculate percentages and get the top 5 properties for each persona
  const resultArray = Object.values(groupedData).map((personaData) => {
    const { userPersona, identityTags, churnTags, source, totalCount } =
      personaData;

    // Helper function to format a property and its percentage
    const formatProperty = (property, count) =>
      `${property} ${Math.round((count / totalCount) * 100)}%`;

    // Calculate percentages and format properties
    const sortedIdentityTags = Object.entries(identityTags)
      .map(([tag, count]) => formatProperty(tag, count))
      .sort((a, b) => {
        const percentageA = parseFloat(a.split(" ")[1]);
        const percentageB = parseFloat(b.split(" ")[1]);
        return percentageB - percentageA;
      })
      .slice(0, 5);

    const sortedChurnTags = Object.entries(churnTags)
      .map(([tag, count]) => formatProperty(tag, count))
      .sort((a, b) => {
        const percentageA = parseFloat(a.split(" ")[1]);
        const percentageB = parseFloat(b.split(" ")[1]);
        return percentageB - percentageA;
      })
      .slice(0, 5);

    const sortedSources = Object.entries(source)
      .map(([src, count]) => formatProperty(src, count))
      .sort((a, b) => {
        const percentageA = parseFloat(a.split(" ")[1]);
        const percentageB = parseFloat(b.split(" ")[1]);
        return percentageB - percentageA;
      })
      .slice(0, 5);

    return {
      userPersona,
      identityTags: sortedIdentityTags,
      churnTags: sortedChurnTags,
      source: sortedSources,
    };
  });

  resultArray.sort((a, b) => a.userPersona - b.userPersona);

  return resultArray;
}

export function getClientCodeList(apiResponse) {
  let result = [];
  for (let data of apiResponse) {
    result = [...result, ...data.members];
  }

  // console.log(result)
  return result;
}

export async function addGrowthValueToArray(array, key) {
  const keyGrowth = `${key}Growth`;
  const result = [];
  for (let i = 0; i < array.length; i++) {
    if (i === 0) {
      result.push({
        ...array[i],
        [keyGrowth]: 0, // Use computed property name
      });
    } else {
      const growth = Math.round(
        ((array[i][key] - array[i - 1][key]) / array[i - 1][key]) * 100
      );
      result.push({
        ...array[i],
        [keyGrowth]: `${growth} %`, // Use computed property name
      });
    }
  }
  return result;
}

export function combineRevenueAndMemberStats(revenueData, memberCountData) {

  // Create a set of all unique year-month pairs
  const allDates = Array.from(
    new Set([
      ...revenueData.map((item) => `${item.year}-${item.month}`),
      ...memberCountData.map((item) => `${item.year}-${item.month}`),
    ])
  );

  // Sort the dates in ascending order
  allDates.sort((a, b) => {
    const [yearA, monthA] = a.split("-").map(Number);
    const [yearB, monthB] = b.split("-").map(Number);
    return yearA - yearB || monthA - monthB;
  });

  const result = [];
  for (const date of allDates) {
    const [year, month] = date.split("-").map(Number);

    let revenueItem = revenueData.find(
      (item) =>
        parseInt(item.year) === parseInt(year) &&
        parseInt(item.month) === parseInt(month)
    );
    let memberItem = memberCountData.find(
      (item) =>
        parseInt(item.year) === parseInt(year) &&
        parseInt(item.month) === parseInt(month)
    );

    // If no matching item is found in either array, create a default one
    if (!revenueItem) {
      revenueItem = {
        year,
        month,
        revenue: 0,
        profit: 0,
        expenses: 0,
      }; // Add other properties as needed, with default values
    }
    if (!memberItem) {
      memberItem = {
        year,
        month,
        newMemberCount: 0,
        newChurnCount: 0,
        activeCount: 0,
        activeCountGrowth: 0,
        totalMemberCount: 0,
      }; // Add other properties as needed, with default values
    }

    result.push({
      ...revenueItem,
      ...memberItem,
    });
  }

  return result;
}
