import React, { useState } from "react";
import { Menu } from "lucide-react";

const ResponsiveSidebar = ({ navItems, activeSection, setActiveSection }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="bg-white w-full md:w-1/4 p-2">
      {/* Hamburger menu for small screens */}
      <button
        className="md:hidden w-full flex items-center justify-between px-4 py-2 bg-gray-200 rounded"
        onClick={toggleMenu}
      >
        <span>Menu</span>
        <Menu
          className={`transform transition-transform duration-200 ${isMenuOpen ? "rotate-90" : ""}`}
        />
      </button>

      {/* Navigation items */}
      <nav className={`mt-5 ${isMenuOpen ? "block" : "hidden"} md:block`}>
        {navItems.map((item) => (
          <button
            key={item.id}
            onClick={() => {
              setActiveSection(item.id);
              setIsMenuOpen(false);
            }}
            className={`flex items-center w-full px-4 py-2 text-center rounded ${
              activeSection === item.id ? "bg-[#EEEEEE]" : "hover:bg-[#F5F5F5]"
            }`}
          >
            {item.label}
          </button>
        ))}
      </nav>
    </div>
  );
};

export default ResponsiveSidebar;
