import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AddTask from "./AddTask";
import RemoveTask from "./RemoveTask";

const DailyLifestyle = () => {
  const [dailyLifestyle, setDailyLifestyle] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showAddTask, setShowAddTask] = useState(false);
  const [showRemoveTask, setShowRemoveTask] = useState(false);
  const { clientCode } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDailyData = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const res = await axios.get(
          `${process.env.REACT_APP_MAIN_SERVICE_BASE_URL}/api/v1/lifestyle?user=${clientCode}`
        );

        if (!res.data.success) {
          throw new Error("Failed to fetch daily lifestyle information");
        }

        const { lifeStyleDetails } = res.data;

        // Validate data structure (add validation logic as needed)
        if (!Array.isArray(lifeStyleDetails.circles)) {
          throw new Error("Unexpected data format: circles is not an array");
        }

        setDailyLifestyle(lifeStyleDetails);
      } catch (error) {
        console.error("Error fetching daily lifestyle information:", error);
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchDailyData();
  }, [clientCode]);

  const { email, name } = location.state || {};

  const handleGenerateNew = () => {
    console.log("generate new");
    navigate(`/client/${clientCode}/lifestyle/new`, {
      state: { email, name },
    });
  };

  const handleAddTask = () => {
    console.log("add task");
    setShowAddTask(true);
  };

  const handleRemoveTask = () => {
    console.log("remove task");
    setShowRemoveTask(true);
  };

  return (
    <div className="container mx-auto px-4">
      {isLoading ? (
        <p className="text-center text-gray-500">Loading daily lifestyle...</p>
      ) : error ? (
        <p className="text-center text-red-500">Error: {error}</p>
      ) : (
        dailyLifestyle &&
        !(showAddTask || showRemoveTask) && (
          <>
            <div className="grid grid-cols-10 pb-2">
              <div className="text-3xl font-bold grid col-span-7 text-white">
                Daily Lifestyle
              </div>
              <div className="grid col-span-3 flex items-center justify-between grid-cols-3 gap-2">
                <button
                  className="bg-generalButton col-span-1 text-white px-4 py-2 rounded-md hover:bg-blue-600"
                  onClick={handleGenerateNew}
                >
                  Generate New
                </button>
                <button
                  className="bg-generalButton col-span-1 text-white px-4 py-2 rounded-md hover:bg-blue-600"
                  onClick={handleAddTask}
                >
                  Add Task
                </button>
                <button
                  className="bg-generalButton col-span-1 text-white px-4 py-2 rounded-md hover:bg-blue-600"
                  onClick={handleRemoveTask}
                >
                  Remove Task
                </button>
              </div>
            </div>
            <div className="grid grid-cols-4 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {dailyLifestyle.circles.map((circle) => (
                <div
                  key={circle.name}
                  className="bg-white rounded-lg p-4 shadow-md"
                >
                  <h3 className="text-xl font-bold text-gray-800">
                    {circle.name}
                  </h3>
                  <ul className="flex flex-col warp">
                    {circle.tasks.map((task) => (
                      <li
                        key={task.taskId}
                        className="flex justify-between items-center"
                      >
                        <div className="flex flex-row items-center text-sm w-full px-2 grid grid-cols-6">
                          <div className="grid col-span-2">
                            {task.time || "-"}{" "}
                          </div>
                          <div className="grid col-span-4">{task.name}</div>
                        </div>
                        {/* <div className="flex-shrink-0">
                                                <button className="bg-blue-500 text-black px-4 py-2 rounded-md hover:bg-blue-600">Details</button>
                                            </div> */}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </>
        )
      )}
      {showAddTask && (
        <AddTask
          memberCode={clientCode}
          onClose={() => setShowAddTask(false)}
        />
      )}

      {showRemoveTask && (
        <RemoveTask
          memberCode={clientCode}
          lifestyle={dailyLifestyle.circles}
          onClose={() => setShowRemoveTask(false)}
        />
      )}
    </div>
  );
};

export default DailyLifestyle;
