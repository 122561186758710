import React from 'react';
import { useLocation } from 'react-router-dom';

const Questionnaire = () => {
  const location = useLocation();
  const { questionnaireResponse } = location.state;

  return (
    <div className="flex flex-col justify-center items-center text-white">
      <h2 className="text-2xl font-bold mb-4">Questionnaire Responses</h2>
      {questionnaireResponse.map((item, index) => (
        <div key={index} className="mb-4 p-4 border rounded-md w-4/5">
          <p className="font-semibold text-xl bg-clip-text text-transparent bg-gradient-to-r from-[#6482AD] to-[#B5CFB7]">{item.content}</p>
          <p>{Array.isArray(item.answer) ? item.answer.join(', ') : item.answer}</p>
        </div>
      ))}
    </div>
  );
};

export default Questionnaire;