import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const WORKOUT_BASE_THEME_OPTIONS = [
  'Horizontal Push',
  'Horizontal Pull',
  'Squat',
  'Vertical Push',
  'Posterior Chain',
];

const EQUIPMENT_OPTIONS = [
  'At gym (full equipment)',
  'At home (bands & dumbbell)',
];

const WORKOUT_DURATION_OPTIONS = ['Regular', 'Shorter'];

const containerStyle = {
  marginTop: '2rem',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '50vh',
  padding: '20px',
  boxSizing: 'border-box',
};

const buttonStyle = {
  marginTop: '40px',
  display: 'block',
  width: '100%',
  padding: '10px',
  backgroundColor: '#007bff',
  color: '#fff',
  border: 'none',
  borderRadius: '4px',
  cursor: 'pointer',
  transition: 'background-color 0.3s',
};

const formStyle = {
  backgroundColor: 'rgb(236, 221, 221)',
  padding: '20px',
  border: '1px solid #ddd',
  borderRadius: '8px',
  boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
  maxWidth: '400px',
  width: '100%',
  textAlign: 'center',
};

const inputContainerStyle = {
  margin: '10px 0',
  textAlign: 'left',
};

const labelStyle = {
  display: 'block',
  marginBottom: '5px',
  fontWeight: 'bold',
};

const selectStyle = {
  width: '100%',
  padding: '8px',
  borderRadius: '4px',
  border: '1px solid #ccc',
  fontSize: '16px',
};

const Modal = ({ message, close }) => {
  return (
    <>
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          backdropFilter: 'blur(2px)',
          zIndex: 1000,
        }}
      ></div>
      <div
        style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'white',
          padding: '20px',
          zIndex: 1001,
        }}
      >
        <h4>{message}</h4>
        <button onClick={close}>Close</button>
      </div>
    </>
  );
};

const UpdateWorkout = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { name } = location.state;
  const { clientCode } = useParams();

  const [workout, setWorkout] = useState(null);
  const [inputValues, setInputValues] = useState({
    customTheme: '',
    customEquipments: '',
    customDuration: '',
  });
  const [modalMessage, setModalMessage] = useState(null);

  const navigateToCoachTools = () => {
    navigate("/coach-tools");
  };

  useEffect(() => {
    const fetchWorkout = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_MAIN_SERVICE_BASE_URL}/api/v1/workout/hyper?memberCode=${clientCode}`
        );
        const workoutData = response.data[0];
        setWorkout(workoutData);
        setInputValues({
          customTheme: workoutData.theme,
          customEquipments: '',
          customDuration: '',
        });
      } catch (error) {
        console.error('Error fetching workout:', error);
      }
    };

    fetchWorkout();
  }, [clientCode]);

  const handleUpdateWorkout = async () => {
    const { customTheme, customEquipments, customDuration } = inputValues;
    const reqBody = {
      memberCode: clientCode,
      theme: customTheme || workout.theme,
      equipment:
        customEquipments === 'At home (bands & dumbbell)'
          ? 'band-dumbbell'
          : 'gym',
      isLite: customDuration === 'Shorter',
    };
    try {
      await axios.put(
        `${process.env.REACT_APP_MAIN_SERVICE_BASE_URL}/api/v1/workout/hyper`,
        reqBody
      );
      setModalMessage('Workout update successful!');
    } catch (error) {
      console.error('Error updating workout:', error);
      setModalMessage('Workout update failed!');
    }
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setInputValues((prevValues) => ({
      ...prevValues,
      [id]: value,
    }));
  };

  const closeModal = () => {
    setModalMessage(null);
  };

  if (!workout) return <div>Loading...</div>;

  const WORKOUT_THEME_OPTIONS = WORKOUT_BASE_THEME_OPTIONS.filter(
    (theme) => theme !== workout.theme
  );
  WORKOUT_THEME_OPTIONS.unshift(workout.theme);

  return (
    <div style={containerStyle}>
      <button
        className='border border-gray-300 rounded-md bg-generalButton text-white text-sm px-2 py-2'
        style={{ marginTop: '6rem' }}
        onClick={navigateToCoachTools}
      >
        Coach Tools
      </button>

      <div style={containerStyle}>
        <h3 style={{ marginTop: '4rem', marginBottom: '3rem' }} className='text-white' >Customize {name}'s workout</h3>
        <div style={formStyle}>
          <div style={inputContainerStyle}>
            <label htmlFor="customTheme" style={labelStyle}>
              THEME
            </label>
            <select
              id="customTheme"
              value={inputValues.customTheme}
              onChange={handleInputChange}
              style={selectStyle}
            >
              {WORKOUT_THEME_OPTIONS.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          <div style={inputContainerStyle}>
            <label htmlFor="customEquipments" style={labelStyle}>
              EQUIPMENT (OPTIONAL)
            </label>
            <select
              id="customEquipments"
              value={inputValues.customEquipments}
              onChange={handleInputChange}
              style={selectStyle}
            >
              {EQUIPMENT_OPTIONS.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          <div style={inputContainerStyle}>
            <label htmlFor="customDuration" style={labelStyle}>
              WORKOUT DURATION
            </label>
            <select
              id="customDuration"
              value={inputValues.customDuration}
              onChange={handleInputChange}
              style={selectStyle}
            >
              {WORKOUT_DURATION_OPTIONS.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          <button onClick={handleUpdateWorkout} style={buttonStyle}>
            Update
          </button>
        </div>

        {modalMessage && <Modal message={modalMessage} close={closeModal} />}
      </div>
    </div>
  );
};

export default UpdateWorkout;
