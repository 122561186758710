// write a component called MemberFeedback that renders a list of feedbacks
// calling api localhost:8820/api/v1/insights/feedback-list

import axios from "axios";
import { useEffect, useState } from "react";

// which returns an array of feedbacks like
// [
//     {
//         "_id": "66d2f0a3e506565b67cea09c",
//         "memberCode": "SHSOLA1866",
//         "duration": "50",
//         "mood": "Good",
//         "rating": "3",
//         "on": {
//             "Day": 31,
//             "Month": 8,
//             "Year": 2024
//         },
//         "workoutDay": "Aug Day 9"
//     },
//     {
//         "_id": "66d2e91ee506565b67cea05a",
//         "memberCode": "JIBHAT3736",
//         "duration": "30",
//         "mood": "Happy",
//         "rating": "5",
//         "on": {
//             "Day": 31,
//             "Month": 8,
//             "Year": 2024
//         },
//         "workoutDay": "Aug Day 5"
//     },
//     {
//         "_id": "66d2c6d5e506565b67ce9f4d",
//         "memberCode": "DIKS",
//         "duration": "65",
//         "mood": "Happy",
//         "rating": "4",
//         "on": {
//             "Day": 31,
//             "Month": 8,
//             "Year": 2024
//         },
//         "workoutDay": "Aug Day 13"
//     },
//     {
//         "_id": "66d2c5e9e506565b67ce9f40",
//         "memberCode": "VIS6714",
//         "duration": "50",
//         "rating": "5",
//         "mood": "Happy ",
//         "on": {
//             "Day": 31,
//             "Month": 8,
//             "Year": 2024
//         },
//         "workoutDay": "Aug Day 15"
//     },
// ]
// it should show the memberCode, duration, mood, rating, and on as ond.Day/on.Month/on.Year in a card
// it should be mobile responsive and should display the feedbacks in a card format
// also if it is loading it should display a loading text

const MemberFeedback = () => {
    const [feedbacks, setFeedbacks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchFeedbacks = async () => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_MAIN_SERVICE_BASE_URL}/api/v1/insights/feedback-list`);
                if (res.status === 200) {
                    setFeedbacks(res.data.data);
                } else {
                    setError('Failed to fetch feedbacks');
                }
            } catch (err) {
                setError(err.response ? err.response.statusText : 'An error occurred');
            } finally {
                setLoading(false);
            }
        };

        fetchFeedbacks();
    }, []);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;


    return (
        <div className="p-2 backdrop-blur-md">
            {loading ? (
                <p>Loading...</p>
            ) : (
                <div className="grid sm:grid-cols-1 lg:grid-cols-4 md:grid:cols-3 text-white py-2 px-3">
                    {feedbacks.map((feedback) => (
                        <div key={feedback._id} className="p-2 m-1 col-span-1 rounded-lg bg-gray-100 shadow-xl ">
                            <div className="flex flex-row w-full items-center justify-between">
                                <span className="text-lg font-bold">{feedback.name}</span>
                                <span>
                                    {feedback.on.Day}/{feedback.on.Month}/{(feedback.on.Year) % 100}
                                </span>
                            </div>

                            <div className="flex flex-col pl-3">
                                <span>Duration: {feedback.duration} min</span>
                                <span>Mood: {feedback.mood}</span>
                                <span>Rating: {feedback.rating}</span>

                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};


export default MemberFeedback;
