import React, { useState } from "react";
import Fitness from "./Fitness";
import Nutrition from "./Nutrition";
import Lifestyle from "./Lifestyle";

const Stats = ({ clientData }) => {
  const [activeCategory, setActiveCategory] = useState("fitness");

  const renderActiveComponent = () => {
    switch (activeCategory) {
      case "fitness":
        return <Fitness clientData={clientData} />;
      case "nutrition":
        return <Nutrition clientData={clientData} />;
      case "lifestyle":
        return <Lifestyle clientData={clientData} />;
      default:
        return null;
    }
  };

  return (
    <div className="space-y-4">
      <div className="flex space-x-2 bg-[#F5F7F8] px-2 py-2 rounded w-[265px]">
        <button
          onClick={() => setActiveCategory("fitness")}
          className={`rounded px-2 py-1 ${activeCategory === "fitness" ? "bg-white shadow-sm" : ""}`}
        >
          Fitness
        </button>
        <button
          onClick={() => setActiveCategory("nutrition")}
          className={` rounded px-2 py-1 ${activeCategory === "nutrition" ? "bg-white shadow-sm" : ""}`}
        >
          Nutrition
        </button>
        <button
          onClick={() => setActiveCategory("lifestyle")}
          className={` rounded px-2 py-1 ${activeCategory === "lifestyle" ? "bg-white shadow-sm" : "bg-[#F5F7F8]"}`}
        >
          Lifestyle
        </button>
      </div>
      {renderActiveComponent()}
    </div>
  );
};

export default Stats;
