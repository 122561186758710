import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BsFillSignStopFill } from "react-icons/bs";
import InputField from "../../components/common/inputField";
import axios from "axios";
import TopPerformersConsistency from "../../components/topPerformerConsistency";
import TopPerformersFitnessScore from "../../components/topPerformersFitnessScore";
import ClientAnalytics from "../clientAnalytics";
import TLSgraph from "../../components/TLSgraph";
import WeeklyActiveUsersGraph from "../../components/weeklyActiveUsersGraph";
import MemberFeedback from "../feedback";

const CoachTools = () => {
  const [clientNameOptions, setClientNameOptions] = useState([]);
  const [clientCode, setClientCode] = useState("");
  const [clientApiResp, setClientApiResp] = useState([]);
  const [coachWiseClients, setCoachWiseClients] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  const fetchClientList = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_INSIGHT_SERVICE_BASE_URL}/client/active`
      );
      const clientList = res.data;

      const cnOptions = clientList.map((client) => ({
        value: client.code,
        label: client.name,
      }));

      setClientApiResp(clientList);
      setClientNameOptions(cnOptions);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchClientList();
  }, []);

  const handleChange = (name, value) => {
    if (name === "clientName") {
      setClientCode(value);
    }
  };

  const navigateToClientAnalytics = () => {
    navigate(`/analytics/client`);
  };

  const navigateToProfile = () => {
    navigate(`/client/${clientCode}`);
  };

  const fetchCoachWiseClients = async () => {
    setIsLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_MAIN_SERVICE_BASE_URL}/api/v1/client/consistency-tls`
      );
      setCoachWiseClients(res.data.data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchClientList();
    fetchCoachWiseClients();
  }, []);

  const CoachWiseClientList = ({ coachWiseClients, isLoading }) => {
    const groupedClients = coachWiseClients.reduce((acc, client) => {
      const coach = client.coach || "Unassigned";
      if (!acc[coach]) {
        acc[coach] = [];
      }
      acc[coach].push(client);
      return acc;
    }, {});

    // Filter out coaches with less than 2 clients
    Object.keys(groupedClients).forEach((coach) => {
      groupedClients[coach].sort(
        (a, b) => b.avgLast4weeklyConsistency - a.avgLast4weeklyConsistency
      );
    });
    const filteredGroupedClients = Object.fromEntries(
      Object.entries(groupedClients).filter(
        ([_, clients]) => clients.length >= 2
      )
    );

    const [filters, setFilters] = useState(
      Object.fromEntries(
        Object.keys(filteredGroupedClients).map((coach) => [coach, []])
      )
    );

    const [expandedCoaches, setExpandedCoaches] = useState({});

    const tlsColors = ["red", "yellow", "green"];

    const getColor = (tlsColour) => {
      switch (tlsColour.toLowerCase()) {
        case "yellow":
          return "#FFB22C";
        case "red":
          return "red";
        case "green":
          return "green";
        default:
          return tlsColour;
      }
    };

    const toggleFilter = (coach, color) => {
      setFilters((prevFilters) => {
        const newFilters = { ...prevFilters };
        if (newFilters[coach].includes(color)) {
          newFilters[coach] = newFilters[coach].filter((c) => c !== color);
        } else {
          newFilters[coach] = [...newFilters[coach], color];
        }
        return newFilters;
      });
    };
    const clearFilters = (coach) => {
      setFilters((prevFilters) => ({
        ...prevFilters,
        [coach]: [],
      }));
    };

    const toggleExpand = (coach) => {
      setExpandedCoaches((prev) => ({
        ...prev,
        [coach]: !prev[coach],
      }));
    };

    return (
      <div className="flex flex-col border border-gray-300 rounded p-2.5 my-5 mx-4 shadow-md space-y-4 w-full sm:w-11/12 backdrop-blur-md">
        <h3 className="text-2xl sm:text-4xl text-center font-bold mb-4 pt-4">
          <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#F7EFE5] to-[#E3A5C7]">
            Coach Wise Clients
          </span>
        </h3>
        {isLoading ? (
          <p className="text-center text-lg text-white">
            Loading Coach Wise Clients List...
          </p>
        ) : (
          ""
        )}
        <div className="flex flex-wrap justify-between">
          {Object.entries(filteredGroupedClients).map(([coach, clients]) => {
            const sortedClients = clients.sort(
              (a, b) =>
                b.avgLast4weeklyConsistency - a.avgLast4weeklyConsistency
            );
            const filteredClients = clients
              .filter((client) => client.name) // Filter out clients without names
              .filter(
                (client) =>
                  filters[coach].length === 0 ||
                  filters[coach].includes(client.tlsColour.toLowerCase())
              );
            const displayClients = expandedCoaches[coach]
              ? filteredClients
              : filteredClients.slice(0, 10);

            return (
              <div
                key={coach}
                className="border rounded p-4 mb-4 w-full sm:w-[48%] md:w-[32%] lg:w-[30%]"
              >
                <h4 className="font-bold mb-2 flex justify-between items-center">
                  <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#F7EFE5] to-[#E3A5C7]">
                    {coach}
                  </span>
                  <span className="text-sm text-white">
                    ({filteredClients.length})
                  </span>
                </h4>
                <div className="flex space-x-2 mb-2">
                  {tlsColors.map((color) => (
                    <button
                      key={color}
                      className={`w-4 h-4 rounded-full ${filters[coach].includes(color)
                        ? "ring-2 ring-offset-2"
                        : ""
                        }`}
                      style={{ backgroundColor: getColor(color) }}
                      onClick={() => toggleFilter(coach, color)}
                    />
                  ))}
                  <button
                    className="text-sm text-white"
                    onClick={() => clearFilters(coach)}
                  >
                    Clear
                  </button>
                </div>
                <ul className="space-y-1">
                  <div className="flex justify-between mb-3">
                    <span className="text-sm text-white font-bold ml-4">Clients</span>
                    <span className="text-sm text-white font-bold"></span>
                    <span className="text-sm text-white font-bold  ml-8">
                      Weekly Consistency
                    </span>
                  </div>
                  {displayClients.map((client) => (
                    <li
                      key={client.code}
                      className="flex justify-between"
                      onClick={() => navigate(`/client/${client.code}`)}
                    >
                      <span className="text-xs text-white">{client.name}</span>
                      <div className="flex flex-row justify-between">
                        <span className="mr-12">{client.isMembershipDue ? <BsFillSignStopFill color='red' /> : ""}</span>
                        <span
                          style={{ color: getColor(client.tlsColour) }}
                          className="text-sm font-bold mr-16 items-center w-8"
                        >
                          {client.avgLast4weeklyConsistency}
                        </span>
                      </div>
                    </li>
                  ))}
                </ul>
                {filteredClients.length > 10 && (
                  <button
                    className="mt-2 text-sm text-white"
                    onClick={() => toggleExpand(coach)}
                  >
                    {expandedCoaches[coach] ? "See Less" : "See More"}
                  </button>
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col justify-center">
      <form className="flex flex-col max-w-[600px] p-3 mx-auto border rounded-md border-b border-gray-300 shadow-sm">
        <InputField
          type="dropdown"
          label="Client Name"
          name="clientName"
          placeholder="Client's name"
          value={clientCode}
          options={clientNameOptions}
          onChange={handleChange}
        />
        <button
          onClick={navigateToProfile}
          disabled={!clientCode}
          className={`px-2.5 py-2 border-none rounded flex-1 w-full mr-2.5 my-2 text-center 
                  ${clientCode
              ? "bg-generalButton text-white cursor-pointer"
              : "bg-lightGray text-gray-500 cursor-not-allowed"
            }
            `}
        >
          Go to Profile
        </button>
      </form>

      <div className="w-full flex items-center justify-center">
        <div className="flex flex-col border border-gray-300 rounded p-2.5 my-5 mx-4 shadow-md space-y-4 w-full sm:w-11/12 backdrop-blur-md">
          <h3 className="text-2xl sm:text-4xl text-center font-bold mb-4 pt-4">
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#F7EFE5] to-[#E3A5C7]">
              Weekly Biz Metrics
            </span>
            <WeeklyActiveUsersGraph />
          </h3>
        </div>
      </div>

      <div className="w-full flex items-center justify-center">
        <CoachWiseClientList
          coachWiseClients={coachWiseClients}
          isLoading={isLoading}
        />
      </div>

      <div className="w-full flex items-center justify-center">
        <div className="flex flex-col border border-gray-300 rounded p-2.5 shadow-md space-y-4 w-full max-w-[400px] sm:max-w-[900px] backdrop-blur-md">
          <h2 className="bg-clip-text text-transparent bg-gradient-to-r from-[#F7EFE5] to-[#E3A5C7] text-center mt-2">
            Current Client Status
          </h2>
          <TLSgraph />
        </div>
      </div>

      <div className="flex flex-wrap justify-center items-center gap-4 my-4 mx-4 text-white">
        <TopPerformersConsistency />
        <TopPerformersFitnessScore />
      </div>

      <div className="w-4/5 mx-auto border border-gray-300 rounded-md">
        <h3 className="text-2xl sm:text-4xl text-center font-bold mb-4 pt-4">
          <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#F7EFE5] to-[#E3A5C7]">
            Member Workout Feedbacks
          </span>
        </h3>

        <MemberFeedback />
      </div>

    </div>
  );
};

export default CoachTools;
