import React from "react";
import { TrendingUp, TrendingDown } from "lucide-react";

const StepCountDisplay = ({ stepCount }) => {
  const getLatestStepCount = (data) => {
    if (!Array.isArray(data) || data.length === 0)
      return { date: "N/A", stepCount: 0 };

    return data.sort((a, b) => {
      const [dayA, monthA, yearA] = a.date.split("/");
      const [dayB, monthB, yearB] = b.date.split("/");

      const dateA = new Date(yearA, monthA - 1, dayA);
      const dateB = new Date(yearB, monthB - 1, dayB);

      return dateB - dateA;
    })[0];
  };

  const calculateStepTrend = (data) => {
    if (!Array.isArray(data) || data.length < 2) return null;

    // Sort data by date (newest to oldest)
    const sortedData = data.sort((a, b) => {
      const [dayA, monthA, yearA] = a.date.split("/");
      const [dayB, monthB, yearB] = b.date.split("/");

      const dateA = new Date(yearA, monthA - 1, dayA);
      const dateB = new Date(yearB, monthB - 1, dayB);

      return dateB - dateA;
    });

    // Get latest entry
    const latestSteps = sortedData[0].stepCount;

    // Calculate average of previous entries
    const previousEntries = sortedData.slice(1);
    const averagePreviousSteps =
      previousEntries.reduce((sum, entry) => sum + entry.stepCount, 0) /
      previousEntries.length;

    // Calculate percentage change
    const percentageChange =
      ((latestSteps - averagePreviousSteps) / averagePreviousSteps) * 100;

    return percentageChange;
  };

  const latestEntry = getLatestStepCount(stepCount);
  const trend = calculateStepTrend(stepCount);

  return (
    <div className="flex flex-col gap-2">
      <h5 className="text-base">Step Count</h5>
      <p className="text-lg font-semibold">
        {latestEntry.stepCount.toLocaleString()}
      </p>
      {trend !== null && (
        <div className="flex items-center gap-1 text-sm">
          {trend > 0 ? (
            <>
              <TrendingUp className="text-green" size={30} />
              <span className="text-green text-xs">
                Your step count has gone up by {Math.abs(trend).toFixed(1)}%
              </span>
            </>
          ) : (
            <>
              <TrendingDown className="text-red" size={30} />
              <span className="text-red text-xs">
                Your step count has gone down by {Math.abs(trend).toFixed(1)}%
              </span>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default StepCountDisplay;
