import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { MONTH_NAMES } from "../utils/dateUtils";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const StepCountGraph = ({ stepsArray }) => {
  // console.log('steps array ', stepsArray);
  // reverse the workouts array to show the latest week first
  stepsArray = stepsArray.reverse();
  let labels = stepsArray.map(
    (step) =>
      `${step.date.split("/")[0]} ${MONTH_NAMES[parseInt(step.date.split("/")[1])]}`
  );
  let counts = stepsArray.map((step) => step.stepCount);

  // Reverse labels and counts arrays to get the correct order
  labels = labels.reverse();
  counts = counts.reverse();

  const data = {
    labels,
    datasets: [
      {
        label: "Steps",
        data: counts,
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        // borderColor: 'llow',
        borderWidth: 1,
        barThickness: 20,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false, // Hide legend as colors now represent different counts
      },
      title: {
        display: true,
        text: "Daily Step Count",
      },
      tooltip: {
        callbacks: {
          label: (context) => `Steps: ${counts[context.dataIndex]}`,
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        // max: 1,
        // ticks: {
        //     callback: (value) => Math.round(value * 5),
        // },
      },
    },
  };

  return <Bar data={data} options={options} />;
};

export default StepCountGraph;
