import axios from "axios";
import { useState } from "react";
import { DAILY_ZONES, WEEK_DAYS } from "../../staticData";
import InputField from "../../components/common/inputField";
import ResponseModal from "../../components/generalResponseModal";

const AddMovementToZone = ({ weeklyMovement, memberCode, onClose }) => {
    const [day, setDay] = useState('');
    const [zone, setZone] = useState('');
    const [movementName, setMovementName] = useState('');
    const [movementTime, setMovementTime] = useState('');
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    const handleAddMovement = async () => {

        console.log({
            day,
            memberCode,
            zoneId: weeklyMovement[day][zone]['zoneId'],
            action: 'add',
            movementName,
            movementTime: `${movementTime} mins`,
        })
        try {
            const weeklyMovementResp = await axios.put(`${process.env.REACT_APP_MAIN_SERVICE_BASE_URL}/api/v1/weekly-movement/workout`, {
                memberCode,
                day,
                zoneId: weeklyMovement[day][zone]['zoneId'],
                action: 'add',
                movementName,
                movementTime: `${movementTime} mins`,
            });
            const data = weeklyMovementResp.data;
            if (weeklyMovementResp.status === 200) {
                setSuccess(true);
            } else {
                setError(true);
            }
        } catch (error) {
            console.log('errpr ', error);
            setError(true);
        }
    }

    const handleChange = (key, value) => {
        console.log('change', key, value)
        switch (key) {
            case 'day':
                setDay(value)
                break
            case 'zone':
                setZone(value)
                break
            case 'movementName':
                setMovementName(value)
                break
            case 'movementTime':
                setMovementTime(value)
                break
            default:
                console.log('invalid input')
                break
        }
    }

    return (
        <div className="fixed inset-0 backdrop-blur-sm bg-blend-overlay flex items-center justify-center z-50">
            <div className="bg-black p-4 border rounded-md w-auto">
                <div className="flex flex-row items-center justify-between">
                    <h2 className="font-bold text-xxl">
                        Edit Weekly Plan
                    </h2>
                    <button
                        className="w-full sm:w-auto border border-gray-300 rounded-md bg-generalButton text-white text-sm py-1 px-2 mb-2 sm:mb-0 sm:mr-2"
                        onClick={onClose}
                    >Close</button>
                </div>
                {!(success || error) && <div className="flex flex-col items-center justify-between pt-2">
                    <InputField
                        label="Day"
                        name="day"
                        value={day}
                        onChange={handleChange}
                        options={WEEK_DAYS.map((d) => ({ value: d, label: d }))}
                        type="dropdown"
                        customClassName="flex flex-row w-4/5 items-center justify-between mb-2"
                        inputBoxCustomClassName="w-full ml-2 border"
                    />
                    <InputField
                        label="Zone"
                        name="zone"
                        value={zone}
                        onChange={handleChange}
                        disabled={!day}
                        options={DAILY_ZONES.map((z) => ({ value: z, label: z }))}
                        type="dropdown"
                        customClassName="flex flex-row w-4/5 items-center justify-between mb-2"
                        inputBoxCustomClassName="w-full ml-2 border"
                    />
                    <InputField
                        label="Movement Name"
                        name="movementName"
                        value={movementName}
                        onChange={handleChange}
                        type="dropdown"
                        disabled={!zone}
                        customClassName="flex flex-row w-4/5 items-center justify-between mb-2"
                        options={[
                            { value: "Walking", label: "Walking" },
                            { value: "Dynamic Stretch", label: "Dynamic Stretch" },
                            { value: "Workout", label: "Workout" },
                            { value: "Rest", label: "Rest" },
                        ]}
                        inputBoxCustomClassName="w-full ml-2 border"
                    />
                    <InputField
                        label="Movement Duration"
                        name="movementTime"
                        value={movementTime}
                        onChange={handleChange}
                        type="dropdown"
                        options={[
                            { value: "15", label: "15 mins" },
                            { value: "30", label: "30 mins" },
                            { value: "45", label: "45 mins" },
                            { value: "60", label: "60 mins" },
                        ]}
                        customClassName="flex flex-row w-4/5 items-center justify-between mb-2"
                        inputBoxCustomClassName="w-full ml-2 border"
                    />
                    <button
                        className="sm:w-2/5 border border-gray-300 rounded-md bg-generalButton text-white text-sm my-3 py-1 sm:mb-0 sm:mr-2"
                        onClick={handleAddMovement}
                        disabled={!movementName || !zone || !day}
                    >
                        Add Movement
                    </button>
                </div>}
                {success && <ResponseModal success={true} message="Movement added successfully" close={onClose} />}
                {error && <ResponseModal success={false} message="Failed to add movement" close={onClose} />}
            </div>
        </div>
    )
}

export default AddMovementToZone;