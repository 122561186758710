import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ResponseModal from '../../components/Modals/responseModal';

const containerStyle = {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: '2px'
};

const colStyle = {
    width: 'max-content',
    border: '1px solid rgb(204, 204, 204)',
    borderRadius: '5px',
    padding: '20px',
    margin: '10px',
    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 4px 8px 0px',
};

const detailsContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
};

const detailRowStyle = {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gap: '10px',
    alignItems: 'center',
};

const keyStyle = {
    fontWeight: 'bold',
};

const ClientAnalytics = ({ showCoachToolButton=true, showLess }) => {
    const [clients, setClients] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const navigateToCoachTools = () => {
        navigate("/coach-tools");
    };

    useEffect(() => {
        setLoading(true);
        axios.get(`${process.env.REACT_APP_MAIN_SERVICE_BASE_URL}/api/v1/client/analytics/client/active`)
            .then(response => setClients(response.data))
            .catch(error => console.error('Error fetching data:', error))
            .finally(() => setLoading(false));
    }, []);

    // Determine the number of clients to display based on `showLess`
    const displayedClients = showLess ? clients.slice(0, 6) : clients;

    return (
        <>
            {showCoachToolButton && <button onClick={navigateToCoachTools}>Coach Tools</button>}
            <div>
                {loading && <ResponseModal message="Loading .. !" />}
                <div style={containerStyle}>
                    {displayedClients.map((client, index) => (
                        <div key={index} style={colStyle}>
                            <h2>{client.name}</h2>
                            <div style={detailsContainerStyle}>
                                <div style={detailRowStyle}>
                                    <p style={keyStyle}>Total Workouts Done:</p>
                                    <p>{client.totalWorkoutsDone}</p>
                                </div>
                                <div style={detailRowStyle}>
                                    <p style={keyStyle}>Last Workout Date:</p>
                                    <p>{new Date(client.lastWorkoutDate).toLocaleDateString()}</p>
                                </div>
                                <div style={detailRowStyle}>
                                    <p style={keyStyle}>Latest Week Consistency:</p>
                                    <p>{client.latestWeekConsistency}</p>
                                </div>
                                <div style={detailRowStyle}>
                                    <p style={keyStyle}>Average Weekly Consistency:</p>
                                    <p>{client.avgWeeklyConsistency}</p>
                                </div>
                                <div style={detailRowStyle}>
                                    <p style={keyStyle}>Average Monthly Consistency:</p>
                                    <p>{client.avgMonthlyConsistency}</p>
                                </div>
                                <div style={detailRowStyle}>
                                    <p style={keyStyle}>Average Last Eight Week Consistency:</p>
                                    <p>{client.avgLastEightWeekConsistency}</p>
                                </div>
                                <div style={detailRowStyle}>
                                    <p style={keyStyle}>Average Last Four Week Consistency:</p>
                                    <p>{client.avgLastFourWeekConsistency}</p>
                                </div>
                                <div style={detailRowStyle}>
                                    <p style={keyStyle}>Current Fitness Score:</p>
                                    <p>{client.currentFitnessScore}</p>
                                </div>
                                <div style={detailRowStyle}>
                                    <p style={keyStyle}>Fitness Score Improvement Last Month:</p>
                                    <p>{(client.fitnessScoreImprovementLastMonth).toFixed(2)}</p>
                                </div>
                                <div style={detailRowStyle}>
                                    <p style={keyStyle}>Monthly Rate Of Fitness Score Improvement:</p>
                                    <p>{client.monthlyRateOfFSImprovemnt}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}

export default ClientAnalytics;
