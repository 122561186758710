import React from "react";
import { TrendingUp, TrendingDown } from "lucide-react";

const WorkoutCountDisplay = ({ lastEightWeeksWorkout, totalWorkoutsDone }) => {
  const calculateWorkoutTrend = (data) => {
    if (!Array.isArray(data) || data.length < 2) return null;

    // Get latest week's count
    const latestCount = data[0].count;

    // Calculate average of previous weeks
    const previousWeeks = data.slice(1);
    const averagePreviousCount =
      previousWeeks.reduce((sum, week) => sum + week.count, 0) /
      previousWeeks.length;

    // Calculate percentage change
    const percentageChange =
      ((latestCount - averagePreviousCount) / averagePreviousCount) * 100;

    return {
      trend: percentageChange,
      latestWeek: data[0].week,
      latestCount: latestCount,
      averagePrevious: averagePreviousCount,
    };
  };

  const trend = calculateWorkoutTrend(lastEightWeeksWorkout);

  return (
    <div className="flex flex-col gap-2">
      <h5 className="text-base">Monthly Workout Consistency</h5>
      <p className="text-lg font-semibold">{totalWorkoutsDone}</p>
      {trend !== null && trend.averagePrevious !== 0 && (
        <div className="flex items-center gap-1 text-sm">
          {trend.trend > 0 ? (
            <>
              <TrendingUp className="text-green" size={30} />
              <span className="text-green text-xs">
                Your workout count has gone up by{" "}
                {Math.abs(trend.trend).toFixed(1)}%
              </span>
            </>
          ) : trend.trend < 0 ? (
            <>
              <TrendingDown className="text-red" size={30} />
              <span className="text-red text-xs">
                Your workout count has gone down by{" "}
                {Math.abs(trend.trend).toFixed(1)}%
              </span>
            </>
          ) : (
            <span className="text-gray-600">
              Your workout count remains unchanged
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export default WorkoutCountDisplay;
