import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BsFillSignStopFill } from "react-icons/bs";
import TLSgraph from "../../components/TLSgraph";

const CoachWiseClients = ({ clientData }) => {
  const [selectedCoach, setSelectedCoach] = useState("No Coach Selected");
  const [filteredClients, setFilteredClients] = useState([]);
  const [tlsFilter, setTlsFilter] = useState("green");
  const [memberCount, setMemberCount] = useState(0);
  const navigate = useNavigate();

  const coaches = [
    "No Coach Selected",
    ...new Set(clientData.map((client) => client.coach).filter(Boolean)),
  ];
  const tlsColors = ["red", "yellow", "green"];

  useEffect(() => {
    let filtered = clientData;

    if (selectedCoach !== "No Coach Selected") {
      filtered = filtered.filter((client) => client.coach === selectedCoach);
    }

    if (tlsFilter) {
      filtered = filtered.filter(
        (client) => client.tlsColour.toLowerCase() === tlsFilter
      );
    }

    // Sort by consistency score, lowest to highest
    filtered.sort(
      (a, b) =>
        (b.avgLast4weeklyConsistency || 0) - (a.avgLast4weeklyConsistency || 0)
    );

    setFilteredClients(filtered);
    setMemberCount(filtered.length);
  }, [selectedCoach, tlsFilter, clientData]);

  const getColor = (tlsColour) => {
    switch (tlsColour.toLowerCase()) {
      case "yellow":
        return "#FFB22C";
      case "red":
        return "red";
      case "green":
        return "green";
      default:
        return "gray";
    }
  };

  const handleTlsFilter = (color) => {
    setTlsFilter((prev) => (prev === color ? "" : color));
  };

  return (
    <div className="flex flex-col space-y-4 w-full px-[10px] sm:px-10">
      <div className="flex gap-2 mt-4">
        <label
          htmlFor="coachFilter"
          className="flex text-lg font-semibold text-black items-center justify-center"
        >
          Filter by coach:
        </label>
        <select
          id="coachFilter"
          value={selectedCoach}
          onChange={(e) => setSelectedCoach(e.target.value)}
          className="w-auto p-2 border border-gray-300 rounded-md bg-white text-gray-900"
        >
          {coaches.map((coach) => (
            <option key={coach} value={coach}>
              {coach || "Unassigned"}
            </option>
          ))}
        </select>
      </div>
      <div className="flex flex-wrap gap-6">
        <div className="w-full md:w-[48%] border border-gray-300 p-3 rounded">
          <div className="mb-4 flex justify-between">
            <div className="mb-4 font-bold text-lg">
              Member Count:{" "}
              <span className="ml-2 text-lg font-bold border-1 border-gray px-2 py-1 rounded text-[#8991F0]">
                {memberCount}
              </span>
            </div>
            <div>
              <div className="flex space-x-2">
                {tlsColors.map((color) => (
                  <button
                    key={color}
                    className={`w-6 h-6 rounded-full ${tlsFilter === color ? "ring-2 ring-black" : ""}`}
                    style={{ backgroundColor: getColor(color) }}
                    onClick={() => handleTlsFilter(color)}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="max-h-[400px] overflow-y-auto">
            <table className="w-full">
              <thead>
                <tr className="text-black">
                  <th className="text-left text-lg font-semibold p-2">
                    Client
                  </th>
                  <th className="text-center text-lg font-semibold p-2">
                    Coach
                  </th>
                  <th className="text-right text-lg font-semibold p-2">
                    Consistency
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredClients.map((client) => (
                  <tr
                    key={client.code}
                    className="text-black hover:bg-gray-700 cursor-pointer text-sm"
                    onClick={() => navigate(`/client2/${client.code}`)}
                  >
                    <td className="p-2">{client.name || "N/A"}</td>
                    <td className="text-center p-2">
                      {client.coach || "Unassigned"}
                    </td>
                    <td className="text-right p-2 flex justify-end items-center">
                      {client.isMembershipDue && (
                        <BsFillSignStopFill color="red" className="mr-2" />
                      )}
                      <span style={{ color: getColor(client.tlsColour) }}>
                        {client.avgLast4weeklyConsistency || "N/A"}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="w-full md:w-[48%] border border-gray-300 p-6 rounded-md">
          <TLSgraph selectedCoach={selectedCoach} />
        </div>
      </div>
    </div>
  );
};

export default CoachWiseClients;
