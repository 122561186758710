import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import axios from "axios";

const WeeklyCheckIn = () => {
  const [weeklyCheckInData, setWeeklyCheckInData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { clientCode } = useParams();
  const location = useLocation();
  const { name } = location.state || {};

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_MAIN_SERVICE_BASE_URL}/api/v1/weekly-checkin?memberCode=${clientCode}`
        );
        setWeeklyCheckInData(response.data.data);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchData();
  }, [clientCode]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error loading data</div>;
  return (
    <div className="flex flex-col gap-5">
      {weeklyCheckInData.map((checkIn) => (
        <div key={checkIn._id} className="rounded-lg p-4 border border-gray">
          <div className="flex flex-col sm:flex-row justify-between flex-wrap gap-4">
            <p className="flex flex-col sm:flex-row items-start sm:items-center gap-2">
              <span className="text-xl font-semibold text-black">Date:</span>
              <span className="text-base border border-gray p-2 rounded">
                {new Date(checkIn.createdAt).toLocaleString()}
              </span>
            </p>
            <p className="flex flex-col sm:flex-row items-start sm:items-center gap-2">
              <span className="text-xl font-semibold text-black">Rating:</span>
              <span className="text-base border border-gray p-2 rounded">
                {checkIn.rating}/10
              </span>
            </p>
            <p className="flex flex-col sm:flex-row items-start sm:items-center gap-2">
              <span className="text-xl font-semibold text-black">Weight:</span>
              <span className="text-base border border-gray p-2 rounded">
                {checkIn.weight} KG
              </span>
            </p>
          </div>
          <div className="flex flex-col sm:flex-row mt-6 sm:mt-10 justify-between gap-4">
            <p className="flex flex-col items-start gap-2">
              <span className="text-xl font-semibold text-black">
                Achievement:
              </span>
              <span className="text-base border border-gray p-2 rounded w-full sm:w-auto">
                {checkIn.achievement}
              </span>
            </p>
            <p className="flex flex-col items-start gap-2">
              <span className="text-xl font-semibold text-black">
                Learning:
              </span>
              <span className="text-base border border-gray p-2 rounded w-full sm:w-auto">
                {checkIn.learning}
              </span>
            </p>
          </div>
          {checkIn.imageURLs && checkIn.imageURLs.length > 0 && (
            <div className="mt-6 flex flex-wrap justify-center items-center gap-4">
              {checkIn.imageURLs.map((url, imgIndex) => (
                <div
                  key={imgIndex}
                  className="w-full sm:w-auto h-72 overflow-hidden"
                >
                  <img
                    src={url}
                    alt={`Check-in ${imgIndex + 1}`}
                    className="w-full h-full object-cover rounded"
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default WeeklyCheckIn;
