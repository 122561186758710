import React from "react";

import Button from "./button";

const months = [
  "",
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const TableBody = ({
  bodyData,
  headerKeys,
  handleClick,
  handleClickParams,
  showDetail,
  showTotal,
}) => {
  const getCellValue = (data, hkey) => {
    if (hkey === "month") return months[data[hkey]];
    else if (["paymentDate", "lastPaymentDate"].includes(hkey)) {
      return formatDate(data[hkey]);
    }

    return data[hkey] ? data[hkey] : 0;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { month: "long", day: "numeric", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);
    return formattedDate;
  };

  const getTotal = (data, key) => {
    const totalSum = data.reduce((sum, entry) => sum + entry[key], 0);
    return totalSum;
  };

  return (
    <tbody>
      {bodyData.map((data) => {
        const tableRows = headerKeys.map((hkey) => {
          const cellValue = getCellValue(data, hkey);
          if (Array.isArray(cellValue)) {
            // If it's an array, display the items
            return (
              <td key={hkey}>
                {cellValue.map((item, index) => (
                  <div key={index}>
                    {typeof item === "string"
                      ? item // Display string directly
                      : {
                          item,
                        }
                        // Display object key-value pairs side by side
                        // Object.entries(item).map(([key, value]) => (
                        //   <span key={key}>
                        //     {key}: {value}
                        //   </span>
                        // ))
                    }
                  </div>
                ))}
              </td>
            );
          } else {
            // If it's not an array, display the value directly
            return <td key={hkey}>{cellValue}</td>;
          }
        });

        return (
          <tr key={`${data.year}-${data.month}`}>
            {tableRows}
            {showDetail && (
              <td>
                <Button
                  onClick={() => {
                    const params = handleClickParams.map(
                      (paramKey) => data[paramKey]
                    );
                    handleClick(...params);
                  }}
                  content="Details"
                />
              </td>
            )}
          </tr>
        );
      })}

      {showTotal && (
        <tr>
          <td></td>
          <td style={{ fontWeight: "bold" }}>Total</td>
          <td style={{ fontWeight: "bold" }}>
            INR {getTotal(bodyData, "revenue")}
          </td>
          <td style={{ fontWeight: "bold" }}>
            INR {getTotal(bodyData, "expenses")}
          </td>
          <td style={{ fontWeight: "bold" }}>
            INR {getTotal(bodyData, "profit")}
          </td>
          <td></td>
        </tr>
      )}
    </tbody>
  );
};

export default TableBody;
