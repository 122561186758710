import "./App.css";
import { BrowserRouter } from "react-router-dom";

import NavBar from "./components/navBar";
import AppRouter from "./appRouter";

function App() {
  return (
    <BrowserRouter>
    <div className="min-h-screen bg-black/70">
      <NavBar />
      <AppRouter />
      </div>
    </BrowserRouter>
  );
}

export default App;
