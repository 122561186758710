import axios from "axios";
import { useEffect, useState } from "react";
import AddMovementToZone from "./AddMovement";
import RemoveMovement from "./RemoveMovement";
import { useNavigate, useParams } from "react-router-dom";
import { FaHome } from "react-icons/fa";

const WeeklySchedule = () => {
    const [weeklyMovement, setWeeklyMovement] = useState({});
    const [showAddMovementModal, setShowMovementModal] = useState(false);
    const [showRemoveMovementModal, setShowRemoveMovementModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [expandedLevel, setExpandedLevel] = useState(null); // Track the currently expanded level
    const [stats, setStats] = useState([]);
    const [totalWorkouts, setTotalWorkouts] = useState(0);

    const { clientCode } = useParams();
    const navigate = useNavigate();

    const fetchWeeklyMovement = async () => {
        setLoading(true);
        try {
            const weeklyMovementResp = await axios.get(`${process.env.REACT_APP_MAIN_SERVICE_BASE_URL}/api/v1/weekly-movement/workout?memberCode=${clientCode}`);
            const data = weeklyMovementResp.data;
            if (weeklyMovementResp.status === 200) {
                setWeeklyMovement(data.data.weeklyWorkout || []);
                setStats(data.data.stats || []);
                setTotalWorkouts(data.data.totalWorkouts || 0);
            }
        } catch (error) {
            console.error('Error fetching weekly movement data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchWeeklyMovement();
    }, []);

    const closeModal = () => {
        setShowMovementModal(false);
        setShowRemoveMovementModal(false);
        fetchWeeklyMovement(); // Refetch data after closing the modal
    };

    const handleLevelClick = (level) => {
        setExpandedLevel(level === expandedLevel ? null : level); // Toggle expansion
    };

    if (loading) {
        return (
            <div className="w-full flex flex-col items-center justify-center backdrop-blur-md px-4">
                <h1>Loading...</h1>
            </div>
        );
    }

    return (
        <div className="flex flex-col items-center justify-center backdrop-blur-md text-white border-gray-500 p-2 w-full">
            <div className="flex flex-row items-center justify-between w-full grid grid-cols-10">
                <h1 className="text-3xl font-bold col-span-9 flex flex-row mx-auto">Weekly Schedule</h1>
                <FaHome
                    size={20}
                    onClick={() => navigate(`/client/${clientCode}`)}
                />
            </div>
            {
                Object.keys(weeklyMovement).length === 0
                    ? <h1>No weekly schedule found</h1>
                    : <>
                        <div className="w-full px-4">
                            {/* <div className="mb-4">
                                <h2 className="text-xl font-bold">Stats</h2>
                                <ul>
                                    {stats.map((stat, index) => (
                                        <li key={index} className="text-lg">
                                            {stat.name}: {stat.completed}/{stat.total}
                                        </li>
                                    ))}
                                </ul>
                                <p className="text-lg">Total Workouts: {totalWorkouts}</p>
                            </div> */}
                            {Object.keys(weeklyMovement).map((day, dayIndex) => (
                                <div key={dayIndex} className="flex flex-col mb-2">
                                    <div className="flex flex-row border px-2 py-1 justify-between rounded-md">
                                        <h2
                                            className="text-xl font-bold cursor-pointer px-2 mt-2 w-full"
                                            onClick={() => handleLevelClick(day)}
                                        >
                                            {day}
                                        </h2>
                                        {expandedLevel === day && (
                                            <div className="flex flex-row">
                                                <button
                                                    className="bg-generalButton text-white text-sm rounded-md w-full mr-2"
                                                    onClick={() => setShowMovementModal(true)}
                                                >
                                                    Add Movement
                                                </button>
                                                <button
                                                    className="bg-generalButton text-white text-sm rounded-md w-full"
                                                    onClick={() => setShowRemoveMovementModal(true)}
                                                >
                                                    Remove Movement
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                    {expandedLevel === day && (
                                        <div className="grid grid-cols-3 mb-2 p-2 px-4">
                                            {Object.entries(weeklyMovement[day]).map(([zone, zoneData]) => (
                                               ['Morning Zone', 'Evening Zone'].includes(zone) && <div key={zone}>
                                                    <h3 className="font-bold text-xl">{zone}</h3>
                                                    <div className="px-2 py-1">
                                                        {zoneData.movements.map((movement) => (
                                                            <div key={movement.movementId} className="flex flex-col">
                                                                <span className="text-lg">{movement.movementName}</span>
                                                                <span className="text-sm px-2 pb-1">{movement.time}</span>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                        {showRemoveMovementModal && (
                            <RemoveMovement
                                memberCode={clientCode}
                                weeklyMovement={weeklyMovement}
                                level={expandedLevel}
                                onClose={closeModal}
                                fetchWeeklyMovement={fetchWeeklyMovement} // Pass fetch function to modal
                            />
                        )}
                        {showAddMovementModal && (
                            <AddMovementToZone
                                memberCode={clientCode}
                                weeklyMovement={weeklyMovement}
                                onClose={closeModal}
                                level={expandedLevel}
                                fetchWeeklyMovement={fetchWeeklyMovement} // Pass fetch function to modal
                            />
                        )}</>
            }
        </div>
    );
}

export default WeeklySchedule;