const MessageModal = ({ message, close }) => {
  return (
    <>
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          backdropFilter: "blur(2px)",
          zIndex: 1000,
        }}
      ></div>
      <div
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          padding: "20px",
          zIndex: 1001,
        }}
      >
        <h4 className="text-black">{message}</h4>
        <button
          className="w-full border border-gray-300 rounded-md bg-generalButton text-white text-sm mx-2 py-2 px-1"
          onClick={close}
        >
          Close
        </button>
      </div>
    </>
  );
};

export default MessageModal;
