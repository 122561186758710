import React from "react";
import { TrendingUp, TrendingDown } from "lucide-react";

const FitnessScoreDisplay = ({ fitnessScore, fitnessGraphData }) => {
  const calculateInsights = (data) => {
    if (!Array.isArray(data) || data.length < 2) return null;

    const latestScore = data[0].score;
    const averageScore =
      data.reduce((sum, entry) => sum + entry.score, 0) / data.length;
    const percentageChange =
      ((latestScore - averageScore) / averageScore) * 100;

    return percentageChange;
  };

  const trend = calculateInsights(fitnessGraphData?.data);

  return (
    <div className="flex flex-col gap-2">
      <h5 className="text-base">Fitness Score</h5>
      <p className="text-lg font-semibold">{fitnessScore}</p>
      {trend !== null && (
        <div className="flex items-center gap-1 text-sm">
          {trend > 0 ? (
            <>
              <TrendingUp className="text-green" size={30} />
              <span className="text-green text-xs">
                Your fitness score has gone up by {Math.abs(trend).toFixed(1)}%
              </span>
            </>
          ) : (
            <>
              <TrendingDown className="text-red" size={30} />
              <span className="text-red text-xs">
                Your fitness score has gone down by {Math.abs(trend).toFixed(1)}
                %
              </span>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default FitnessScoreDisplay;
