import React from "react";

const InputField = (props) => {
  const {
    onChange,
    label,
    name,
    type,
    placeholder,
    value,
    options,
    error,
    style,
    disabled,
    customClassName,
    inputBoxCustomClassName,
    optionCustomClass,
  } = props;

  const baseInputClass =
    "w-full p-2.5 border border-gray-300 rounded text-black";

  if (type === "dropdown") {
    return (
      <div
        className={customClassName || "flex flex-col text-white font-bold"}
        style={style}
      >
        <label htmlFor={name}>{label}</label>
        <select
          className={`${baseInputClass} ${inputBoxCustomClassName || ""}`}
          name={name}
          value={value}
          id={name}
          onChange={(e) => onChange(name, e.target.value)}
          disabled={disabled}
        >
          <option value="">Select</option>
          {options.map((option) => (
            <option className={optionCustomClass} key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        {error && <div className="text-red-500 text-sm mt-1">{error}</div>}
      </div>
    );
  }

  return (
    <div className={customClassName || "form-group"}>
      <label className="text-white" htmlFor={name}>
        {label}
      </label>
      <input
        type={type}
        className={`${baseInputClass} ${inputBoxCustomClassName || ""}`}
        name={name}
        value={value}
        id={name}
        placeholder={placeholder}
        onChange={(e) => onChange(name, e.target.value)}
        disabled={disabled}
      />
      {error && <div className="text-red-500 text-sm mt-1">{error}</div>}
    </div>
  );
};

export default InputField;