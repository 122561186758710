import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import InputField from "./common/inputField";
import TableHeader from "./common/tableHeader";
import TableBody from "./common/tableBody";

import "../styles/fundManagementForm.css";

const MemberPaymentHistory = () => {
  const isAdmin = localStorage.getItem("isAdmin") === "true";  
  const navigate = useNavigate();

  if (!isAdmin) {
    navigate("/");
  }

  const [clientNameOptions, setClientNameOptions] = useState([]);
  const [clientName, setClientName] = useState("");
  const [clientData, setClientData] = useState([]);

  const location = useLocation();
  const data = location.state;

  const headerKeys = ["Payment Date", "Amount"];
  const hKeys = ["paymentDate", "amount"];

  useEffect(() => {
    const fetchData = async () => {
      const cnOptions = [];

      data.clients.sort();
      for (let client of data.clients) {
        cnOptions.push({
          value: client,
          label: client,
        });
      }

      setClientNameOptions(cnOptions);
    };

    fetchData(); // Invoke fetchData directly inside the useEffect

    if (clientName) {
      const clientp = data.paymentData.filter(
        (item) => item.clientName === clientName
      );
      setClientData(clientp);
    }
  }, [data, clientName]); // Include fetchData, data, and clientName in the dependency array

  const handleChange = (name, value) => {
    if (name === "clientName") {
      setClientName(value);
    }
  };

  return (
    <div>
      <form className="fund-form">
        <InputField
          type="dropdown"
          label="Client Name"
          name="clientName"
          placeholder="client's name"
          value={clientName}
          options={clientNameOptions}
          onChange={handleChange}
        />
      </form>
      {clientData.length !== 0 && (
        <div className="table-container">
          <table className="table table-success table-striped table-container">
            <TableHeader headerData={headerKeys} />
            <TableBody bodyData={clientData} headerKeys={hKeys} />
          </table>
        </div>
      )}
    </div>
  );
};

export default MemberPaymentHistory;
