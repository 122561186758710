import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import TableHeader from "./common/tableHeader";
import TableBody from "./common/tableBody";

import { getChurnedAndNewMembers } from "../utils";

import "../styles/monthYearTable.css";

const kHeader = {
  month: "Month",
  year: "Year",
  newMemberCount: "New Members",
  newChurnCount: "Churned Members",
  activeCount: "Active Members",
  totalMemberCount: "Total",
};

const MonthYearTable = () => {
  const isAdmin = localStorage.getItem("isAdmin") === "true";  
  const navigate = useNavigate();

  if (!isAdmin) {
    navigate("/");
  }

  const [attendenceData, setAttendenceData] = useState([]);
  const [memberData, setMemberData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ANALYTICS_SERVICE_BASE_URL}/attendance/stats/monthly-users`
      );

      setAttendenceData(response.data);

      const memberResponse = await axios.get(
        `${process.env.REACT_APP_ANALYTICS_SERVICE_BASE_URL}/member`
      );
      setMemberData(memberResponse.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleClick = (year, month) => {
    const stateData = getChurnedAndNewMembers(
      attendenceData,
      memberData,
      month,
      year
    );

    navigate(`/stats/${year}/${month}`, { state: stateData });
  };

  // Check if data has elements before rendering
  if (attendenceData.length === 0) {
    return <div>Loading...</div>;
  }

  const doNotPass = ["churnedMembers", "newMembers", "totalMemberCount"];
  const keys = Object.keys(attendenceData[0]).filter(
    (item) => !doNotPass.includes(item)
  );
  keys.push("totalMemberCount");
  let hKeys = keys.map((key) => kHeader[key]);

  const handleClickParams = ["year", "month"];

  return (
    <React.Fragment>
      <h2>Member Analytics</h2>
      <table className="table table-success table-striped table-container">
        <TableHeader headerData={hKeys} showDetail />
        <TableBody
          bodyData={attendenceData}
          headerKeys={keys}
          handleClick={handleClick}
          handleClickParams={handleClickParams}
          showDetail
        />
      </table>
    </React.Fragment>
  );
};

export default MonthYearTable;
