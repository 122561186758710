import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";

const customXAxisTickFormatter = (tick) => {
  const date = new Date(tick);
  const endDate = new Date(date);
  endDate.setDate(date.getDate() + 6);
  return `${date.getDate()} ${date.toLocaleString("default", { month: "short" })} - ${endDate.getDate()} ${endDate.toLocaleString("default", { month: "short" })}`;
};

const WeeklyActiveUsersGraph = () => {
  const [graphData, setGraphData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [chartWidth, setChartWidth] = useState(400);

  useEffect(() => {
    const fetchGraphData = async () => {
      try {
        const [activeClientsRes, activeMembershipsRes] = await Promise.all([
          axios.get(
            `${process.env.REACT_APP_MAIN_SERVICE_BASE_URL}/api/v1/insights/weekly-active-clients`
          ),
          axios.get(
            `${process.env.REACT_APP_MAIN_SERVICE_BASE_URL}/api/v1/insights/weekly-active-memberships`
          ),
        ]);

        const activeClientsData = activeClientsRes.data.data.slice(-52, -1);
        const activeMembershipsData = activeMembershipsRes.data.data.slice(
          -52,
          -1
        );

        const combinedData = activeClientsData.map((clientEntry) => {
          const membershipEntry = activeMembershipsData.find(
            (m) => m.week === clientEntry.week
          );
          return {
            week: new Date(clientEntry.week.split("-")[0]),
            clientCount: clientEntry.count,
            membershipCount: membershipEntry ? membershipEntry.count : 0,
          };
        });

        setGraphData(combinedData);
      } catch (error) {
        console.error("Error fetching graph data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchGraphData();
    const handleResize = () => {
      setChartWidth(window.innerWidth < 430 ? window.innerWidth - 40 : 400);
    };
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const maxClientCount = Math.max(...graphData.map((data) => data.clientCount));
  const maxMembershipCount = Math.max(
    ...graphData.map((data) => data.membershipCount)
  );

  const maxValue = Math.max(maxClientCount, maxMembershipCount);
  const yAxisMax = Math.ceil(maxValue * 1.1);

  if (isLoading) {
    return (
      <p className="text-center text-lg text-black">Loading graph data...</p>
    );
  }

  return (
    <div className="w-full">
      <ResponsiveContainer width="100%" height={400}>
        <LineChart
          data={graphData}
          margin={{
            top: 22,
            right: 40,
            left: 20,
            bottom: 2,
          }}
        >
          <XAxis
            dataKey="week"
            tickFormatter={customXAxisTickFormatter}
            padding={{ left: 16 }}
            tick={{ fill: "#808080", fontSize: 10 }}
            tickSize={8}
            interval={"preserveStartEnd"}
          />
          <YAxis
            domain={[0, yAxisMax]}
            tickCount={10} // Approximate number of tick points
            allowDataOverflow={false}
            label={{
              value: "COUNT",
              angle: -90,
              position: "insideLeft",
              fill: "#808080",
              fontSize: 12,
            }}
            tick={{ fill: "#808080", fontSize: 12 }}
            tickSize={5}
            axisLine={false}
          />
          <CartesianGrid stroke="#808080" opacity={0.1} vertical={false} />
          <Tooltip
            contentStyle={{
              backgroundColor: "#1c1c1e",
              border: "none",
              borderRadius: "4px",
              fontSize: 12,
            }}
            labelStyle={{ color: "#808080" }}
            itemStyle={{ color: "#808080" }}
            formatter={(value, name) => [value, name]}
            labelFormatter={(label) => customXAxisTickFormatter(label)}
          />
          <Legend
            wrapperStyle={{ bottom: 0, left: 0, right: 0 }}
            iconType="circle"
            iconSize={10}
            formatter={(value, entry) => (
              <span style={{ color: entry.color }} className="text-sm">
                {value}
              </span>
            )}
          />
          <Line
            type="monotone"
            dataKey="membershipCount"
            stroke="#5ECC7B"
            fill="#5ECC7B"
            name="Paid Members"
            dot={false}
          />
          <Line
            type="monotone"
            dataKey="clientCount"
            stroke="#f0a000"
            fill="#f0a000"
            name="Active Clients"
            dot={false}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default WeeklyActiveUsersGraph;
