import React, { useEffect, useState } from "react";
import CoachDropdown from "../../components/CoachDropDown";
import SelectCoach from "../../components/SelectCoachScreen";
import axios from "axios";
import MessageModal from "../../components/MessageModal";
import { useParams } from "react-router-dom";

const Profile = () => {
  const [clientData, setClientData] = useState(null);
  const [isAssignScrrenOpen, setIsAssignScreenOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCoach, setSelectedCoach] = useState("");
  const [apiStatus, setApiStatus] = useState(null);
  const [mssgModalOpen, setMssgIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { clientCode } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const clientApiData = await axios.get(
          `${process.env.REACT_APP_MAIN_SERVICE_BASE_URL}/api/v1/client/profile/coach-tools?memberCode=${clientCode}`
        );

        console.log(clientApiData.data);

        setClientData(clientApiData.data.data);

        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };

    fetchData();
  }, [mssgModalOpen]);

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const closeModal = () => {
    setMssgIsModalOpen(false);
  };

  useEffect(() => {
    {
    }
    setSelectedCoach(clientData?.coach);
  }, [clientData?.coach]);

  const updateUser = async () => {
    try {
      setIsAssignScreenOpen(false);
      await axios.put(
        `${process.env.REACT_APP_INSIGHT_SERVICE_BASE_URL}/client/coachMember`,
        {
          coach: selectedCoach,
          code: clientData.code,
        }
      );
      setApiStatus({ success: true, message: "Update successful!" });

      setMssgIsModalOpen(true);
    } catch (error) {
      console.error(error);
      setIsAssignScreenOpen(false);
      setApiStatus({ success: false, message: "Update failed!" });
      setMssgIsModalOpen(true);
    }
  };

  // useEffect(() => {
  //   try {
  //     axios.get(
  //       `${process.env.REACT_APP_MAIN_SERVICE_BASE_URL}/api/v1/client/profile/coach-tools?memberCode=${code}`
  //     );
  //   } catch (err) {}
  // }, []);

  // const handleSelectedCoach = () => {};

  return (
    <div className="p-4 sm:flex justify-between">
      {mssgModalOpen && (
        <MessageModal message={apiStatus.message} close={closeModal} />
      )}
      {isAssignScrrenOpen === true && (
        <SelectCoach
          selectCoach={updateUser}
          selectedCoach={selectedCoach}
          setCoachConfimation={setIsAssignScreenOpen}
        />
      )}
      {clientData?.coach && (
        <div className="flex gap-3 flex-col-reverse sm:flex-row w-full items-center sm:justify-between ">
          <div className="text-xl mb-6">
            Coach Assigned:{" "}
            <CoachDropdown
              previousCoach={clientData.coach}
              coach={selectedCoach}
              assignScreenOpen={setIsAssignScreenOpen}
              setSelectedCoach={setSelectedCoach}
            />
          </div>
          <div className="">
            <button
              onClick={toggleModal}
              className="px-4 py-2 bg-black text-white rounded hover:bg-blue-600 transition-colors"
            >
              Questionnaire Response
            </button>
          </div>
        </div>
      )}

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-30">
          <div className="bg-white rounded-lg w-full max-w-md max-h-[85vh] overflow-hidden">
            <div className="p-4 border-b">
              <h3 className="text-lg font-semibold">Questionnaire Responses</h3>
            </div>
            <div className="overflow-y-auto max-h-[calc(80vh-8rem)] p-4">
              {clientData.questionnaireResponse.map((task, id) => (
                <div key={id} className="mb-4">
                  <p className="text-base font-medium">{task.content}</p>
                  <p className="mt-1 p-2 border-2 border-[#B7B7B7] rounded text-sm">
                    {task.answer[0] || "Not Answered"}
                  </p>
                </div>
              ))}
            </div>
            <div className=" p-2 justify-center flex items-center">
              <button
                onClick={toggleModal}
                className="px-4 py-2 bg-blue-500 text-white bg-black rounded hover:bg-blue-600 transition-colors"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Profile;
