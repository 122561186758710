import React from "react";
import RenewalReport from "../renewalReport";

const RenewalReportNew = () => {
  return (
    <div>
      <RenewalReport />
    </div>
  );
};

export default RenewalReportNew;
