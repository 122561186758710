import React from "react";
import { useNavigate } from "react-router-dom";

import TableBody from "./common/tableBody";
import TableHeader from "./common/tableHeader";

import { getPersonaTopDetails } from "../utils";

const PersonaTagsTable = ({ tagsData }) => {
  const navigate = useNavigate();

  const HeaderKeys = [
    "User Persona",
    "Top Identities",
    "Top Churn Tags",
    "Top Sources",
  ];

  const tableBodyKeys = ["userPersona", "identityTags", "churnTags", "source"];

  const handleClickParams = ["userPersona"];

  const personaDetails = getPersonaTopDetails(tagsData);

  const handleClick = (userPersona) => {
    const filteredTags = tagsData.filter(
      (tag) => tag.userPersona === userPersona
    );

    navigate(`/persona/${userPersona}`, { state: filteredTags });
  };

  return (
    <React.Fragment>
      <h2>Persona Analytics</h2>
      <table className="table table-success table-striped table-container">
        <TableHeader headerData={HeaderKeys} showDetail />
        <TableBody
          bodyData={personaDetails}
          headerKeys={tableBodyKeys}
          handleClickParams={handleClickParams}
          handleClick={handleClick}
          showDetail
        />
      </table>
    </React.Fragment>
  );
};

export default PersonaTagsTable;
