import React, { useEffect, useState } from "react";
import { ALL_AVAILABLE_COACHES } from "../staticData";

const CoachDropdown = ({
  previousCoach,
  coach,
  assignScreenOpen,
  setSelectedCoach,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [previousMemberCoach, setPreviousMemberCoach] = useState(previousCoach);
  const [selectedAccount, setSelectedAccount] = useState("");
  const [query, setQuery] = useState("");

  const handleSearch = (event) => {
    setQuery(event.target.value);
  };

  const filteredItems = ALL_AVAILABLE_COACHES.filter((item) =>
    item.toLowerCase().includes(query.toLowerCase())
  );

  useEffect(() => {
    if (!previousCoach) {
      setPreviousMemberCoach("No Coach Assign");
    } else {
      setPreviousMemberCoach(previousCoach); // Set previousCoach as the initial value
    }
  }, [previousCoach]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelectAccount = (coachName) => {
    setIsOpen(false);
    assignScreenOpen(true);
    setSelectedCoach(coachName);
  };

  return (
    <div className="relative w-56 text-black">
      {/* Selected account display */}
      <button
        onClick={toggleDropdown}
        className="flex items-center justify-between  border border-black rounded-lg w-full px-4 py-2 bg-gray-800 "
      >
        <span className="flex items-center">
          <div className="text-sm"> {previousMemberCoach}</div>
        </span>
        <span className="text-sm">▼</span>
      </button>

      {/* Dropdown menu */}
      {isOpen && (
        <div className="absolute  left-0 mt-2 w-full bg-gray-900 rounded-md shadow-lg  ">
          <div className="px-1 pt-1">
            <input
              type="text"
              placeholder="Search Coach..."
              onChange={handleSearch}
              className="w-full px-1 py-2 mb-3 bg-gray-700 border border-black-opacity-25  rounded-md placeholder:text-sm  "
            />
          </div>
          {filteredItems.map((category, index) => (
            <div
              key={category}
              onClick={() => handleSelectAccount(category)}
              className={`flex text-base justify-between py-1 pl-2 pr-5 hover:cursor-pointer  hover:bg-blue-gray-50 ${index !== category.length - 2 && "border-b border-b-black-opacity-25"} `}
            >
              {category} <div>{previousMemberCoach === category && "✓"}</div>
            </div>
          ))}
          {/* <button className="flex items-center w-full px-4 py-2 mt-2 text-left bg-gray-700 rounded-md">
            <span className="mr-2">➕</span> Create Team
          </button> */}
        </div>
      )}
    </div>
  );
};

export default CoachDropdown;
