import React, { useState } from "react";
import Profile from "./Profile";
import Settings from "./Settings";
import Stats from "./newStats/Stats";
import WeeklyCheckIn from "./WeeklyCheckIn";
import RenewalReportNew from "./RenewalReportNew";
import ResponsiveSidebar from "../../components/ResponsiveSidebar";
import MealUploads from "./MealUploads";

const SettingsPage = ({ clientData }) => {
  const [activeSection, setActiveSection] = useState("stats");

  const navItems = [
    {
      id: "profile",
      label: "Profile",
      component: Profile,
      props: { clientData },
    },
    {
      id: "settings",
      label: "Settings",
      component: Settings,
      props: { clientData },
    },
    {
      id: "stats",
      label: "Statistics",
      component: Stats,
      props: { clientData },
    },
    {
      id: "weeklyCheckIn",
      label: "Weekly Check-In",
      component: WeeklyCheckIn,
    },
    {
      id: "renewalReport",
      label: "Renewal Report",
      component: RenewalReportNew,
    },
    {
      id: "mealUploads",
      label: "Meal Uploads",
      component: MealUploads,
      props: { clientData },
    },
  ];

  const renderContent = () => {
    const activeItem = navItems.find((item) => item.id === activeSection);
    const ActiveComponent = activeItem?.component;
    return ActiveComponent ? (
      <ActiveComponent {...activeItem.props} />
    ) : (
      <div>Section not found</div>
    );
  };

  return (
    <div className="flex flex-col md:flex-row w-full sm:w-[90%] h-auto bg-gray-100">
      {/* Responsive Sidebar */}
      <ResponsiveSidebar
        navItems={navItems}
        activeSection={activeSection}
        setActiveSection={setActiveSection}
      />

      {/* Main content */}
      <div className="flex-1 p-4 md:p-10">{renderContent()}</div>
    </div>
  );
};

export default SettingsPage;
