import React, { useEffect, useState } from "react";
import axios from "axios";

import "../styles/monthYearTable.css";
import TableHeader from "../components/common/tableHeader";
import TableBody from "../components/common/tableBody";
import {
  addGrowthValueToArray,
  combineRevenueAndMemberStats,
  processRevenueApiResponse,
} from "../utils";

const memberKHeader = {
  newMemberCount: "New Members",
  activeCount: "Active Members",
  activeCountGrowth: "Growth %",
  newChurnCount: "Churned Members",
  totalMemberCount: "Total Members",
};

const revenueKHeader = {
  revenue: "Revenue",
  revenueGrowth: "Growth %",
  expenses: "Expenses",
  profit: "Profit",
  totalRevenue: "Total Revenue",
};

const totalHeaderKeys = {
  month: "Month",
  year: "Year",
  ...memberKHeader,
  ...revenueKHeader,
};

const MonthlyMetrics = () => {
  const [attendenceData, setAttendenceData] = useState([]);
  const [revenueData, setRevenueData] = useState([]);
  const [combinedData, setCombinedData] = useState([]); // New state for combined data

  useEffect(() => {
    fetchMemberCountData();
    fetchRevenueData();
  }, []);

  useEffect(() => {
    // Combine data when revenueData or attendenceData changes
    if (revenueData.length > 0 && attendenceData.length > 0) {
      const combined = combineRevenueAndMemberStats(
        revenueData,
        attendenceData
      );
      setCombinedData(combined);
    }
  }, [revenueData, attendenceData]);

  const fetchMemberCountData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ANALYTICS_SERVICE_BASE_URL}/attendance/stats/monthly-users`
      );

      const modifiedData = await addGrowthValueToArray(
        response.data,
        "activeCount"
      );
      setAttendenceData(modifiedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchRevenueData = async () => {
    try {
      const revResponse = await axios.get(
        `${process.env.REACT_APP_ANALYTICS_SERVICE_BASE_URL}/finance/revenue`
      );

      const revenueData = await processRevenueApiResponse(revResponse.data);
      const modifiedData = await addGrowthValueToArray(revenueData, "revenue");
      setRevenueData(modifiedData);
    } catch (err) {
      console.error("error in getting revenue: ", err);
    }
  };

  // Check if data has elements before rendering
  if (attendenceData.length === 0) {
    return <div>Loading...</div>;
  }

  const doNotPass = ["churnedMembers", "newMembers", "totalMemberCount"];
  const keys = Object.keys(totalHeaderKeys).filter(
    (item) => !doNotPass.includes(item)
  );
//   keys.push("totalMemberCount");
  let hKeys = keys.map((key) => totalHeaderKeys[key]);

  return (
    <React.Fragment>
      <h2>Monthly Metrics</h2>
      <table className="table table-success table-striped table-container">
        <TableHeader headerData={hKeys} />
        <TableBody bodyData={combinedData} headerKeys={keys} />
      </table>
    </React.Fragment>
  );
};

export default MonthlyMetrics;
