import React, { useEffect, useState } from "react";
import StepCountGraph from "../../../components/stepCountGraph";
import WorkoutChart from "../../../components/workoutConsistencyChart";
import axios from "axios";
import { useParams } from "react-router-dom";
import WorkoutGraph from "../../../components/WorkoutGraph/WorkoutGraph";
import FitnessGraph from "../../../components/FitnessGraph/FitnessGraph";
import StepCountDisplay from "./insights/StepCountDisplay";
import WorkoutCountDisplay from "./insights/WorkoutCountDisplay";
import FitnessScoreDisplay from "./insights/FitnessScoreDisplay";
import ConsistencyDisplay from "./insights/ConsistencyDisplay";

const Fitness = ({ clientData }) => {
  const {
    totalWorkoutsDone,
    lastEightWeeksWorkout,
    avgLastFourWeekConsistency,
    // lastWorkoutDate,
    // profilePicture,
    // workoutRank,
    // isPaymentDue,
    // membershipEndDate,
    // skills,
    fitnessScore,
    perfectWeeks,
    stepCount,
  } = clientData;
  const [activeGraph, setActiveGraph] = useState("stepCount");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [fitnessGraphData, setFitnessGraphData] = useState(null);
  const [workoutGraphData, setWorkoutGraphData] = useState(null);
  const [radarChartData, setRadarChartData] = useState(null);
  const [activityList, setActivityList] = useState([]);
  const [selectedTimeRangeFitness, setSelectedTimeRangeFitness] = useState(3);
  const [selectedTimeRangeWeekly, setSelectedTimeRangeWeekly] = useState(3);
  const [weeklyWorkoutConsistency, setWeeklyWorkoutConsistency] =
    useState(null);
  const [weeklyWorkoutConsistencyAvg, setWeeklyWorkoutConsistencyAvg] =
    useState(0);
  const [monthlyWorkoutConsistencyAvg, setMonthlyWorkoutConsistencyAvg] =
    useState(0);

  const { clientCode } = useParams();
  const fetchRadarChartData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_MAIN_SERVICE_BASE_URL}/api/v1/monthly-wrapped?user=${clientCode}&month=${new Date().getMonth()}&year=${new Date().getFullYear()}`
      );
      const { currMonthSkillPoint, prevMonthSkillPoint, bestSkill } =
        response.data.data.wrapped;
      setRadarChartData({
        currMonthSkillPoint,
        prevMonthSkillPoint,
        bestSkill,
      });
    } catch (error) {
      console.error("Error fetching radar chart data:", error);
    }
  };
  useEffect(() => {
    const fetchFitnessData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_MAIN_SERVICE_BASE_URL}/api/v1/insights/fitness-score?memberCode=${clientCode}&month=${selectedTimeRangeFitness}`
        );
        setFitnessGraphData(response.data);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchFitnessData();
  }, [clientCode, selectedTimeRangeFitness]);
  const handleTimeRangeChangeFitness = (event) => {
    setSelectedTimeRangeFitness(parseInt(event.target.value));
  };
  useEffect(() => {
    const fetchWeeklyConsistencyData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_MAIN_SERVICE_BASE_URL}/api/v1/insights/weekly-consistency?memberCode=${clientCode}&month=${selectedTimeRangeWeekly}`
        );

        setWorkoutGraphData(response.data);
        const averageCount = response.data.data.reduce(
          (acc, item, _, array) => acc + item.count / array.length,
          0
        );
        setMonthlyWorkoutConsistencyAvg(averageCount.toFixed(2));
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchWeeklyConsistencyData();
  }, [clientCode, selectedTimeRangeWeekly]);

  useEffect(() => {
    const fetchWeeklyConsistencyData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_MAIN_SERVICE_BASE_URL}/api/v1/insights/weekly-consistency?memberCode=${clientCode}&month=${3}`
        );
        setWeeklyWorkoutConsistency(response.data.data);
        const averageCount = response.data.data.reduce(
          (acc, item, _, array) => acc + item.count / array.length,
          0
        );
        setWeeklyWorkoutConsistencyAvg(averageCount.toFixed(2));
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchWeeklyConsistencyData();
  }, [clientCode]);
  const handleTimeRangeChangeWeekly = (event) => {
    setSelectedTimeRangeWeekly(parseInt(event.target.value));
  };

  const fetchActivityData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_MAIN_SERVICE_BASE_URL}/api/v1/insights/weekly-activity-list?memberCode=${clientCode}`
      );

      if (!response.data.success) {
        throw new Error("Network response was not ok");
      }

      const data = response.data.data;

      return data;
    } catch (error) {
      console.error("Error fetching activity data:", error);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [clientResponse, activityData] = await Promise.all([
          axios.get(
            `${process.env.REACT_APP_MAIN_SERVICE_BASE_URL}/api/v1/client/profile/coach-tools?memberCode=${clientCode}`
          ),
          axios.get(
            `${process.env.REACT_APP_MAIN_SERVICE_BASE_URL}/api/v1/weekly-checkin?memberCode=${clientCode}`
          ),
          fetchActivityData(),
        ]);
        setActivityList(activityData);
        await fetchRadarChartData();
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchData();
  }, [clientCode]);
  const renderActiveGraph = () => {
    switch (activeGraph) {
      case "stepCount":
        return <StepCountGraph stepsArray={stepCount} />;
      case "totalWorkouts":
        return <WorkoutChart workouts={weeklyWorkoutConsistency} />;
      case "fitnessScore":
        return (
          fitnessGraphData && (
            <div>
              {" "}
              <select
                value={selectedTimeRangeFitness}
                onChange={handleTimeRangeChangeFitness}
                className="border rounded p-2"
              >
                <option value={3}>3 Months</option>
                <option value={6}>6 Months</option>
                <option value={12}>12 Months</option>
              </select>
              <FitnessGraph apiData={fitnessGraphData} />
            </div>
          )
        );
      case "weeklyConsistency":
        return (
          workoutGraphData && (
            <div>
              <select
                value={selectedTimeRangeWeekly}
                onChange={handleTimeRangeChangeWeekly}
                className="border rounded p-2"
              >
                <option value={3}>3 Months</option>
                <option value={6}>6 Months</option>
                <option value={12}>12 Months</option>
              </select>
              <WorkoutGraph apiData={workoutGraphData} />
            </div>
          )
        );
      default:
        return null;
    }
  };
  return (
    <div className="p-4">
      <div className="flex flex-col sm:flex-row justify-between mb-4">
        <h3 className="text-lg mb-2 sm:mb-0">Fitness Stats</h3>
        <div className="flex items-center">
          <p className="text-lg mr-4">
            Perfect Weeks:
            <span className="inline-block p-[6px] rounded-full border border-black ml-2">
              {perfectWeeks}
            </span>
          </p>
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-4">
        <div
          className={`p-4 border text-center rounded cursor-pointer ${
            activeGraph === "stepCount"
              ? "shadow-2xl "
              : "shadow-md bg-[#F5F7F8]  "
          }`}
          onClick={() => setActiveGraph("stepCount")}
        >
          <StepCountDisplay stepCount={stepCount} />
        </div>
        <div
          className={`p-4 border rounded cursor-pointer text-center ${
            activeGraph === "totalWorkouts"
              ? "shadow-2xl"
              : "shadow-md bg-[#F5F7F8] "
          }`}
          onClick={() => setActiveGraph("totalWorkouts")}
        >
          <h5 className="text-base">Weekly Workout Consistency</h5>
          <p>{weeklyWorkoutConsistencyAvg}</p>
          {workoutGraphData && (
            <ConsistencyDisplay workoutGraphData={workoutGraphData} />
          )}
        </div>
        <div
          className={`p-4 border rounded text-center cursor-pointer ${
            activeGraph === "fitnessScore"
              ? "shadow-2xl"
              : "shadow-md bg-[#F5F7F8] "
          }`}
          onClick={() => setActiveGraph("fitnessScore")}
        >
          <FitnessScoreDisplay
            fitnessScore={fitnessScore}
            fitnessGraphData={fitnessGraphData}
          />
        </div>
        <div
          className={`p-4 border rounded text-center cursor-pointer ${
            activeGraph === "weeklyConsistency"
              ? "shadow-2xl"
              : "shadow-md bg-[#F5F7F8] "
          }`}
          onClick={() => setActiveGraph("weeklyConsistency")}
        >
          <WorkoutCountDisplay
            lastEightWeeksWorkout={lastEightWeeksWorkout}
            totalWorkoutsDone={monthlyWorkoutConsistencyAvg}
          />
        </div>
      </div>

      <div className="w-full h-[300px] sm:h-96 border shadow-sm rounded p-4">
        {loading ? (
          <p>Loading...</p>
        ) : error ? (
          <p>Error loading data: {error.message}</p>
        ) : (
          renderActiveGraph()
        )}
      </div>
    </div>
  );
};

export default Fitness;
