import React from "react";
import { PieChart } from "react-minimal-pie-chart";
import randomcolor from "randomcolor";

import "../../styles/modelChart.css";

const contrastingColors = [
  "#FF6384",
  "#36A2EB",
  "#FFCE56",
  "#33FF99",
  "#FF9F40",
  "#8A2BE2",
  "#00CED1",
];

const getPieChartData = (wdata, key) => {
  const valueCounts = wdata.reduce((counts, entry) => {
    const values = entry[key];

    if (Array.isArray(values)) {
      values.forEach((value) => {
        counts[value] = value.length !== 0 && (counts[value] || 0) + 1;
      });
    } else if (values) {
      counts[values] = (counts[values] || 0) + 1;
    }

    return counts;
  }, {});

  const sortedValues = Object.keys(valueCounts).sort(
    (a, b) => valueCounts[b] - valueCounts[a]
  );

  const topValues = sortedValues.slice(0, 5);

  const pcData = topValues.map((value, index) => ({
    title: value,
    value: valueCounts[value],
    color: contrastingColors[index % contrastingColors.length],
  }));

  return pcData;
};

const keyMaps = {
  source: "Where did they hear about us?",
  identityTags: "Identity Tags",
  churnTags: "Churn Tags",
  convertReasonTags: "Convert Reason",
  userPersona: "User Persona",
  profession: "Profession",
  location:"Location"
};

const Pie = ({ data, gkey }) => {
  const pieChartData = getPieChartData(data, gkey);

  while (pieChartData.length > contrastingColors.length) {
    contrastingColors.push(randomcolor());
  }

  return (
    <div className="pieChartContainer">
      <span className="sectionHeader">{keyMaps[gkey]}</span>
      <div className="sectiondetail">
        <div className="sectionDetailData">
          {pieChartData.map((item) => (
            <div>
              <div className="dataInfo">
                <div
                  className="colorBox"
                  style={{ backgroundColor: item.color }}
                ></div>
                <span>{item.title}</span>
              </div>
              <span className="dataValue">{item.value}</span>
            </div>
          ))}
        </div>
        <PieChart className="sectionDetailPie" data={pieChartData} />
      </div>
    </div>
  );
};

export default Pie;
