import React, { useState } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';

const ResponseModal = ({ message, close }) => {
    return (
        <>
            <div
                style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    backdropFilter: "blur(2px)",
                    zIndex: 1000,
                }}
            >
                <div
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        backgroundColor: "white",
                        padding: "20px",
                        zIndex: 1001,
                        textAlign: "center",
                        borderRadius: "8px",
                        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                    }}
                >
                    {message === "Hold on the report is getting ready..!" && (
                        <>
                            <CircularProgress />
                            <p style={{ marginTop: "20px" }}>{message}</p>
                        </>
                    )}
                    {message === "Failed to generate report" && (
                        <>
                            <p style={{ marginTop: "20px" }}>{message}</p>
                            <button onClick={close} style={{ marginTop: "20px" }}>Close</button>
                        </>
                    )}
                    {message === "Report generated successfully" && (
                        <>
                            <p style={{ marginTop: "20px" }}>{message}</p>
                            <button onClick={close} style={{ marginTop: "20px" }}>Close</button>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

const containerStyle = {
    marginTop: '2rem',
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "50vh",
    padding: "20px",
    boxSizing: "border-box"
};

const formStyle = {
    backgroundColor: "rgb(236, 221, 221)",
    padding: "20px",
    border: "1px solid #ddd",
    borderRadius: "8px",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
    maxWidth: "400px",
    width: "100%",
    textAlign: "center"
};

const buttonStyle = {
    marginTop: "40px",
    display: "block",
    width: "100%",
    padding: "10px",
    backgroundColor: "#007bff",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    transition: "background-color 0.3s"
};

const RenewalReport = () => {
    const location = useLocation();
    const { email, name } = location.state || {};
    const navigate = useNavigate();

    const navigateToCoachTools = () => {
        navigate("/coach-tools");
    };

    const [file, setFile] = useState(null);
    const [response, setResponse] = useState(null);
    const [reportUrl, setReportUrl] = useState('');
    const [loading, setLoading] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setModalMessage('');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('file', file);
        formData.append('email', email);

        setLoading(true);
        setResponse(null);
        setReportUrl('');
        setIsModalOpen(true);
        setModalMessage("Hold on the report is getting ready..!");

        try {
            const res = await axios.post(`${process.env.REACT_APP_MAIN_SERVICE_BASE_URL}/api/v1/renewal-report`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            console.log('res ds', res.data, res.data.success, typeof res.data.success);
            if (res.data.success) {
                setResponse(res.data);

                if (res.data.reportId) {
                    const reportUrl = `${process.env.REACT_APP_PLATFORM_BASE_URL}/journey-reflection/${res.data.reportId}`;
                    setReportUrl(reportUrl);
                    setModalMessage("Report generated successfully");
                }
            } else {
                setModalMessage("Failed to generate report");
            }
        } catch (error) {
            console.error('Error uploading file:', error);
            setModalMessage("Failed to generate report");
        } finally {
            setLoading(false);
        }
    };

    const handleCopyUrl = () => {
        navigator.clipboard.writeText(reportUrl);
        alert('URL copied to clipboard!');
    };

    return (
        <div style={containerStyle}>

            <h3 className='text-white'>
                Renewal Report for {name}
            </h3>
            <form onSubmit={handleSubmit} style={formStyle}>
                <div>
                    <label style={{ fontSize: 'large', marginBottom: '3rem' }}>
                        Upload data For Renewal Report
                    </label>
                    <input
                        style={{ marginBottom: '1rem' }}
                        type="file"
                        onChange={handleFileChange}
                        required
                    />
                </div>
                <button type="submit" style={buttonStyle}>Generate </button>
            </form>

            {isModalOpen && (
                <ResponseModal message={modalMessage} close={handleCloseModal} />
            )}

            {reportUrl && (
                <div>
                    <button style={{ marginTop: '2rem' }} onClick={handleCopyUrl}>Copy report link</button>
                </div>
            )}
        </div>
    );
};

export default RenewalReport;
