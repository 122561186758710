import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MdEmail, MdArrowBack } from "react-icons/md";
import { FaUserCircle } from "react-icons/fa";
import axios from "axios";
import Loader from "../../components/Loader";
import WeeklyWorkoutReport from "../../components/Modals/WeeklyWorkoutReport";
import WorkoutChart from "../../components/workoutConsistencyChart";
import Calendar from "../../components/Calender/Calender";
import FitnessGraph from "../../components/FitnessGraph/FitnessGraph";
import WorkoutGraph from "../../components/WorkoutGraph/WorkoutGraph";
import RadarChart from "../../components/RadarChart/RadarChart";
import { FaUserTie, FaDumbbell, FaClock, FaChartLine } from "react-icons/fa";
import StepCountGraph from "../../components/stepCountGraph";
import CaloriesTracker from "../nutrition";

const ClientProfile = () => {
  const [clientData, setClientData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [weeklyCheckInData, setWeeklyCheckInData] = useState([]);
  // const [completionHistory, setCompletionHistory] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [calendarData, setCalendarData] = useState(null);
  const [fitnessGraphData, setFitnessGraphData] = useState(null);
  const [workoutGraphData, setWorkoutGraphData] = useState(null);
  const [radarChartData, setRadarChartData] = useState(null);
  const [activityList, setActivityList] = useState([]);

  const { clientCode } = useParams();
  const navigate = useNavigate();

  const fetchCalendarData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_MAIN_SERVICE_BASE_URL}/api/v1/lifestyle?user=${clientCode}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching calendar data:", error);
      return null;
    }
  };

  const fetchRadarChartData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_MAIN_SERVICE_BASE_URL}/api/v1/monthly-wrapped?user=${clientCode}&month=${new Date().getMonth()}&year=${new Date().getFullYear()}`
      );
      const { currMonthSkillPoint, prevMonthSkillPoint, bestSkill } =
        response.data.data.wrapped;
      setRadarChartData({
        currMonthSkillPoint,
        prevMonthSkillPoint,
        bestSkill,
      });
    } catch (error) {
      console.error("Error fetching radar chart data:", error);
    }
  };

  const fetchActivityData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_MAIN_SERVICE_BASE_URL}/api/v1/insights/weekly-activity-list?memberCode=${clientCode}`
      );

      if (!response.data.success) {
        throw new Error("Network response was not ok");
      }

      const data = response.data.data;

      return data;
    } catch (error) {
      console.error("Error fetching activity data:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [clientResponse, checkInResponse, calendarData, activityData] =
          await Promise.all([
            axios.get(
              `${process.env.REACT_APP_MAIN_SERVICE_BASE_URL}/api/v1/client/profile/coach-tools?memberCode=${clientCode}`
            ),
            axios.get(
              `${process.env.REACT_APP_MAIN_SERVICE_BASE_URL}/api/v1/weekly-checkin?memberCode=${clientCode}`
            ),
            fetchCalendarData(),
            fetchActivityData(),
          ]);
        setClientData(clientResponse.data.data);
        setWeeklyCheckInData(checkInResponse.data.data);
        setCalendarData(calendarData);
        setActivityList(activityData);

        const reportId = clientResponse.data.data.renewalReportId;

        if (reportId) {
          // Fetch journey reflection data
          const journeyReflectionResponse = await axios.get(
            `${process.env.REACT_APP_MAIN_SERVICE_BASE_URL}/api/v1/renewal-report?reportId=${reportId}`
          );
          setFitnessGraphData(journeyReflectionResponse.data);
          setWorkoutGraphData(journeyReflectionResponse.data);
        }
        await fetchRadarChartData();
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchData();
  }, [clientCode]);

  if (loading) return <Loader />;
  if (error) return <p>Error loading data</p>;

  const {
    name,
    email,
    totalWorkoutsDone,
    lastEightWeeksWorkout,
    consistencyTrend,
    avgLastFourWeekConsistency,
    // lastWorkoutDate,
    // profilePicture,
    // workoutRank,
    // isPaymentDue,
    // membershipEndDate,
    // skills,
    coach,
    program,
    lifetimeInMonths,
    fitnessScore,
    fitnessScoreImprovement,
    rateOfFSI,
    questionnaireResponse,
    perfectWeeks,
    totalWeeks,
    stepCount,
  } = clientData;

  const consistencyColor =
    avgLastFourWeekConsistency > 2.5
      ? "green"
      : avgLastFourWeekConsistency > 1.2
        ? "yellow"
        : "red";

  // const latestCheckIn = weeklyCheckInData[0] || {};
  // console.log(fitnessGraphData);

  return (
    <div className="relative w-full min-h-screen overflow-x-hidden text-black bg-white flex flex-col items-center px-3">
      <div className="absolute top-4 left-4 z-10">
        <MdArrowBack
          className="cursor-pointer"
          size={35}
          onClick={() => navigate("/coach-tools")}
        />
      </div>
      <div className="flex flex-col justify-center items-center w-full pt-16">
        <div className="border rounded-md w-full md:w-11/12 lg:w-4/5 mx-auto p-2 md:p-4 backdrop-blur-md">
          <div className="flex flex-col lg:flex-row justify-evenly items-start">
            {/* Left section - User info */}
            <div className="flex flex-col lg:w-1/3 mb-4 lg:mb-0">
              <div className="flex items-center mb-4">
                <FaUserCircle size={60} color={consistencyColor} />
                <div className="ml-4">
                  <div className="text-2xl md:text-3xl font-bold">{name}</div>
                  <div className="flex items-center mt-1 text-sm">
                    <MdEmail className="mr-2" /> {email}
                  </div>
                </div>
              </div>
            </div>

            {/* Middle section - User stats */}
            <div className="flex flex-col lg:w-1/3 lg:mb-0 lg:mx-8">
              <div className="flex flex-wrap justify-between">
                <div className="flex items-center mr-4">
                  <FaUserTie className="mr-1" />
                  <span className="font-bold mr-1 text-sm md:text-base bg-clip-text text-transparent bg-gradient-to-r from-[#6482AD] to-[#697565]">
                    Coach:
                  </span>
                  <span className="text-xs mt-[2px]">
                    {coach ? coach : "Pranav"}
                  </span>
                </div>
                <div className="flex items-center mr-6 mb-2">
                  <FaDumbbell className="mr-1 text-purple-400" />
                  <span className="font-semibold mr-1 text-sm md:text-base bg-clip-text text-transparent bg-gradient-to-r from-[#6482AD] to-[#697565]">
                    Program:
                  </span>
                  <span className="text-xs mt-[2px]">{program || "Hyper"}</span>
                </div>
                <div className="flex items-center mr-4">
                  <FaClock className="mr-1 text-yellow-400" />
                  <span className="font-semibold mr-1 text-sm md:text-base bg-clip-text text-transparent bg-gradient-to-r from-[#6482AD] to-[#697565]">
                    Lifetime:
                  </span>
                  <span className="text-xs mt-[2px]">
                    {lifetimeInMonths} Months
                  </span>
                </div>
                <div className="flex flex-col items-start mt-4 sm:mb-0 mb-4">
                  <div className="flex items-center">
                    <FaChartLine className="mr-1 text-red-400" />
                    <span className="font-semibold mr-1 text-sm md:text-base bg-clip-text text-transparent bg-gradient-to-r from-[#6482AD] to-[#697565]">
                      Fitness Score:
                    </span>
                    <span className="text-xs">{fitnessScore}</span>
                  </div>
                  <div className="flex flex-col mt-1 text-xs">
                    <span>
                      Improvement:{" "}
                      {fitnessScoreImprovement !== undefined
                        ? fitnessScoreImprovement?.toFixed(3)
                        : "N/A"}
                    </span>
                    <span>Rate of improvement: {rateOfFSI}</span>
                  </div>
                </div>
              </div>
            </div>

            {/* Right section - Action buttons */}
            <div className="grid grid-cols-2 gap-2 lg:w-1/3">
              <button
                className="border border-gray-300 rounded-md bg-gradient-to-b from-[#171717] to-[#0f0f0f] text-white text-xs py-2 px-4"
                onClick={() => navigate(`/client/${clientCode}/edit`)}
              >
                Edit Profile
              </button>
              <button
                className="border border-gray-300 rounded-md bg-gradient-to-b from-[#171717] to-[#0f0f0f] text-white text-xs py-2 px-4"
                onClick={() =>
                  navigate("/client/renewal-report", {
                    state: { email: clientData.email, name },
                  })
                }
              >
                Renewal Report
              </button>
              <button
                className="border border-gray-300 rounded-md bg-gradient-to-b from-[#171717] to-[#0f0f0f] text-white text-xs py-2 px-4"
                onClick={() =>
                  navigate(`/client/${clientCode}/update-workout`, {
                    state: { name: name },
                  })
                }
              >
                Customize Workout
              </button>
              <button
                className="border border-gray-300 rounded-md bg-gradient-to-b from-[#171717] to-[#0f0f0f] text-white text-xs py-2 px-4"
                onClick={() =>
                  navigate(`/client/${clientCode}/lifestyle`, {
                    state: { email: clientData.email, name: name },
                  })
                }
              >
                Lifestyle
              </button>
              <button
                className="border border-gray-300 rounded-md bg-gradient-to-b from-[#171717] to-[#0f0f0f] text-white text-xs py-2 px-4"
                onClick={() =>
                  navigate(`/client/${clientCode}/weekly-checkin`, {
                    state: { email: email, name: name },
                  })
                }
              >
                Weekly Check-In
              </button>
              <button
                className="border border-gray-300 rounded-md bg-gradient-to-b from-[#171717] to-[#0f0f0f] text-white text-xs py-2 px-4"
                onClick={() =>
                  navigate("/questionnaire", {
                    state: { questionnaireResponse },
                  })
                }
              >
                Questionnaire
              </button>

              <button
                className="border border-gray-300 rounded-md bg-gradient-to-b from-[#171717] to-[#0f0f0f] text-white text-xs py-2 px-4"
                onClick={() => navigate(`/client/${clientCode}/nutrition`)}
              >
                Nutrition
              </button>
              <button
                className="border border-gray-300 rounded-md bg-gradient-to-b from-[#171717] to-[#0f0f0f] text-white text-xs py-2 px-4"
                onClick={() => navigate(`/client/${clientCode}/weekly-plan`)}
              >
                Weekly Plan
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* weekly stats */}
      <div className="rounded-md w-full sm:w-full md:w-11/12 lg:w-4/5 mt-4 backdrop-blur-md">
        <h2 className="text-2xl font-bold m-4">Stats This Week</h2>
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-8 gap-4 mt-4">
          <div className="col-span-1 sm:col-span-1 md:col-span-3 flex flex-col mx-2 px-4 pt-2">
            <h3 className="text-bold text-lg">Activities Completed</h3>
            <div>
              {activityList &&
                activityList.length > 0 &&
                activityList.map((activity, index) => (
                  <div key={index} className="flex flex-col mt-1">
                    <div className="text-lg">{activity.date}</div>
                    <ul>
                      {activity.activities.length > 0 ? (
                        activity.activities.map((act, index) => (
                          <li key={index} className="text-sm">
                            {" "}
                            - {act}
                          </li>
                        ))
                      ) : (
                        <li className="text-sm"> - </li>
                      )}
                    </ul>
                  </div>
                ))}
            </div>
          </div>

          <div className="col-span-1 sm:col-span-1 md:col-span-5 flex flex-col p-2">
            {stepCount && stepCount.length > 0 && (
              <div className="w-full flex flex-col items-center">
                <StepCountGraph stepsArray={stepCount} />
                <h2 className="text-sm font-bold mt-2">Step Count</h2>
              </div>
            )}

            <div className="w-full flex flex-col items-center mt-4">
              <CaloriesTracker memberCode={clientCode} />
            </div>
            <div className="rounded-md sm:w-full md:w-1/2 flex flex-col items-center mt-4">
              <h2 className="text-xl font-bold">Lifestyle Completion</h2>
              {calendarData && (
                <Calendar
                  completionHistory={calendarData.completionHistory}
                  isSummaryPage={false}
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      {/* lifetime stats */}
      <div className="rounded-md w-full md:w-11/12 lg:w-4/5 mt-4 backdrop-blur-md">
        <h2 className="text-2xl font-bold m-4">Lifetime Stats</h2>
        <div className="grid grid-cols-1 md:grid-cols-7 gap-4">
          {/* Main Content Area */}
          <div className="col-span-5 rounded-md backdrop-blur-md">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
              <div className="rounded-md p-2 mt-1">
                <h2 className="text-lg font-bold">Weekly Metrics</h2>
                {lastEightWeeksWorkout && lastEightWeeksWorkout.length > 0 && (
                  <WorkoutChart workouts={lastEightWeeksWorkout} />
                )}
              </div>
              <div className="rounded-md pt-8">
                {workoutGraphData && (
                  <WorkoutGraph apiData={workoutGraphData} />
                )}
              </div>
            </div>
            <div className="grid grid-rows-1 gap-1 mb-4">
              <div>
                <h2 className="text-lg font-bold pl-3">Monthly Metrics</h2>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="rounded-md p-2">
                  {fitnessGraphData && (
                    <FitnessGraph apiData={fitnessGraphData} />
                  )}
                </div>
                <div className="rounded-md p-2">
                  {radarChartData && (
                    <RadarChart radarChartData={radarChartData} />
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Achievements Section */}
          {clientData.achievements && (
            <div className="col-span-2 rounded-md backdrop-blur-md">
              <h1 className="text-lg font-bold mb-4 ml-2 mt-2">Achievements</h1>
              <div className="flex flex-col">
                {clientData.achievements.strength && (
                  <div className="bg-gray-800 p-2 border-gray-800 rounded-lg shadow-md">
                    <h2 className="text-sm font-semibold mb-1">Strength</h2>
                    <p>{clientData.achievements.strength}</p>
                  </div>
                )}
                {clientData.achievements.hypertrophy && (
                  <div className="bg-gray-800 p-2 rounded-lg shadow-md mb-4">
                    <h2 className="text-sm font-semibold mb-1">Hypertrophy</h2>
                    <p>{clientData.achievements.hypertrophy}</p>
                  </div>
                )}
                {clientData.achievements.midRange && (
                  <div className="bg-gray-800 p-2 rounded-lg shadow-md mb-2">
                    <h2 className="text-sm font-semibold mb-1">Mid Range</h2>
                    <p>{clientData.achievements.midRange}</p>
                  </div>
                )}
                {clientData.achievements.fitnessTests && (
                  <div className="bg-gray-800 p-2 rounded-lg shadow-md mb-2">
                    <h2 className="text-sm font-semibold mb-1">
                      Fitness Tests
                    </h2>
                    <p>{clientData.achievements.fitnessTests}</p>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      {/* MAIN STATS */}
      <div className="border rounded-md w-full md:w-11/12 lg:w-4/5 mt-4 p-4 backdrop-blur-md px-2">
        <h2 className="text-2xl font-bold mb-4">MAIN STATS</h2>
        <div className="flex flex-col sm:flex-row justify-between mb-4">
          <div className="text-center sm:flex-1">
            <p className="text-lg font-semibold">Perfect Weeks</p>
            <p className="sm:text-4xl text-3xl font-bold text-[#8080ff] font-anton pt-2">
              {perfectWeeks} / {totalWeeks}
            </p>
          </div>
          <div className="text-center sm:flex-1">
            <p className="text-lg font-semibold">Total Workouts</p>
            <p className="sm:text-4xl text-3xl font-bold text-[#8080ff] font-anton pt-2">
              {totalWorkoutsDone}
            </p>
          </div>
          <div className="sm:flex-1 mt-4 sm:mt-0">
            <StepCountGraph stepsArray={stepCount} />
          </div>
        </div>
        <div className="flex-col flex sm:flex-row justify-between">
          <div className="rounded-md p-2 sm:w-1/2 w-full">
            {calendarData && (
              <Calendar
                completionHistory={calendarData.completionHistory}
                isSummaryPage={false}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
              />
            )}
          </div>
          <div className="rounded-md p-2 sm:w-1/2 w-full mt-8 sm:mt-0">
            <WeeklyWorkoutReport
              consistencyTrend={consistencyTrend}
              suggestedWorkoutPerWeek={4}
              lastEightWeeksWorkout={lastEightWeeksWorkout}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientProfile;
