import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const TopPerformersConsistency = ({ onHover }) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [displayCount, setDisplayCount] = useState(10);
  const [showModal, setShowModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortConfig, setSortConfig] = useState({
    key: "rank",
    direction: "ascending",
  });

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_INSIGHT_SERVICE_BASE_URL}/leaderboard/consistency`
    )
      .then((response) => response.json())
      .then((data) => {
        setData(data.rankList);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, []);

  const handleShowMoreClick = () => {
    setShowModal(true);
  };

  const handleCloseModalClick = () => {
    setShowModal(false);
  };

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  // Filter the data
  const filteredData = data.filter((item) =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Sort the data
  const sortedData = [...filteredData].sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === "ascending" ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === "ascending" ? 1 : -1;
    }
    return 0;
  });

  const navigateToProfile = (clientCode) => {
    navigate(`/client/${clientCode}`);
  };

  return (
    <div className="relative text-black">
      <div className="backdrop-blur-md">
        <div
          style={{
            border: "1px solid black",
            padding: "10px",
            borderRadius: "10px",
          }}
        >
          <h3 className="bg-clip-text text-transparent bg-gradient-to-r from-[#F7EFE5] to-[#E3A5C7]">
            Top Performers Consistency
          </h3>
          <table style={{ margin: "0 auto" }}>
            <thead>
              <tr>
                <th
                  style={{ textAlign: "center" }}
                  onClick={() => handleSort("rank")}
                >
                  Rank
                </th>
                <th
                  style={{ textAlign: "center" }}
                  onClick={() => handleSort("name")}
                >
                  Name
                </th>
                <th
                  style={{ textAlign: "center" }}
                  onClick={() => handleSort("workout")}
                >
                  Workout
                </th>
              </tr>
            </thead>
            <tbody>
              {sortedData.slice(0, displayCount).map((item) => (
                <tr
                  key={item.code}
                  style={
                    onHover
                      ? {
                          // transform: "scale(1.05)",
                          // transition: "transform 0.3s ease-in-out",
                          cursor: "pointer",
                        }
                      : {}
                  }
                  onClick={() => navigateToProfile(item.code)}
                  // cursor="pointer"
                >
                  <td style={{ textAlign: "center" }}>{item.rank}</td>
                  <td style={{ textAlign: "center" }}>{item.name}</td>
                  <td style={{ textAlign: "center" }}>{item.workout}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {displayCount < data.length && (
            <button style={{ marginTop: "2px" }} onClick={handleShowMoreClick}>
              Show more...
            </button>
          )}
        </div>
      </div>
      {showModal && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            backdropFilter: "blur(5px)",
            zIndex: 999,
          }}
        >
          <div
            style={{
              position: "fixed",
              top: "10vh",
              left: "50%",
              transform: "translateX(-50%)",
              width: "fit-content",
              height: "80vh",
              border: "1px solid black",
              boxShadow: "5px 5px 15px rgba(0, 0, 0, 0.3)",
              backgroundColor: "white",
              padding: "20px",
              zIndex: 1000,
              borderRadius: "10px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              color: "black",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "20px",
              }}
            >
              <input
                type="text"
                placeholder="Search by name"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{ color: "black" }}
              />
              <button onClick={handleCloseModalClick} style={{ color: "red" }}>
                Close
              </button>
            </div>
            <h3 style={{ textAlign: "center" }}>Top Performers Consistency</h3>
            <div style={{ overflowY: "auto", maxHeight: "60vh" }}>
              <table style={{ margin: "0 auto" }}>
                <thead>
                  <tr>
                    <th
                      style={{ textAlign: "center" }}
                      onClick={() => handleSort("rank")}
                    >
                      Rank
                    </th>
                    <th
                      style={{ textAlign: "center" }}
                      onClick={() => handleSort("name")}
                    >
                      Name
                    </th>
                    <th
                      style={{ textAlign: "center" }}
                      onClick={() => handleSort("totalScore")}
                    >
                      Total Score
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {sortedData.map((item) => (
                    <tr
                      key={item.code}
                      style={
                        onHover
                          ? {
                              transform: "scale(1.05)",
                              transition: "transform 0.3s ease-in-out",
                              cursor: "pointer",
                            }
                          : {}
                      }
                      onClick={() => navigateToProfile(item.code)}
                    >
                      <td style={{ textAlign: "center" }}>{item.rank}</td>
                      <td style={{ textAlign: "left" }}>{item.name}</td>
                      <td style={{ textAlign: "center" }}>{item.workout}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TopPerformersConsistency;
