import axios from "axios";
import { useState } from "react";
import InputField from "../../../components/common/inputField";
import ResponseModal from "../../../components/generalResponseModal";
import { IoIosClose } from "react-icons/io";

const AddMovementToZoneNew = ({
  weeklyMovement,
  memberCode,
  onClose,
  selectedDay,
  selectedZone,
}) => {
  const [movementName, setMovementName] = useState("");
  const [movementTime, setMovementTime] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const handleAddMovement = async () => {
    try {
      const weeklyMovementResp = await axios.put(
        `${process.env.REACT_APP_MAIN_SERVICE_BASE_URL}/api/v1/weekly-movement/workout`,
        {
          memberCode,
          day: selectedDay,
          zoneId: weeklyMovement[selectedDay][selectedZone]["zoneId"],
          action: "add",
          movementName,
          movementTime: `${movementTime} mins`,
        }
      );
      if (weeklyMovementResp.status === 200) {
        setSuccess(true);
      } else {
        setError(true);
      }
    } catch (error) {
      console.log("error ", error);
      setError(true);
    }
  };

  const handleChange = (key, value) => {
    switch (key) {
      case "movementName":
        setMovementName(value);
        break;
      case "movementTime":
        setMovementTime(value);
        break;
      default:
        console.log("invalid input");
        break;
    }
  };

  return (
    <div className="fixed inset-0 backdrop-blur-sm bg-blend-overlay flex items-center justify-center z-50">
      <div className="bg-white p-4 border rounded-md w-auto">
        <div className="flex flex-row items-center justify-between">
          <h2 className="font-bold text-xl">Add New Activity</h2>
          <button
            className="w-full sm:w-auto rounded-md text-black text-3xl mb-2 sm:mb-0 sm:mr-2"
            onClick={onClose}
          >
            <IoIosClose />
          </button>
        </div>
        {!(success || error) && (
          <div className="flex flex-col items-center justify-between pt-2">
            <InputField
              label="Name"
              name="movementName"
              value={movementName}
              onChange={handleChange}
              type="dropdown"
              customClassName="flex flex-row w-4/5 items-center justify-between mb-2"
              options={[
                { value: "Walking", label: "Walking" },
                { value: "Dynamic Stretch", label: "Dynamic Stretch" },
                { value: "Workout", label: "Workout" },
                { value: "Rest", label: "Rest" },
              ]}
              inputBoxCustomClassName="w-full ml-2 border"
            />
            <InputField
              label="Duration"
              name="movementTime"
              value={movementTime}
              onChange={handleChange}
              type="dropdown"
              options={[
                { value: "15", label: "15 mins" },
                { value: "30", label: "30 mins" },
                { value: "45", label: "45 mins" },
                { value: "60", label: "60 mins" },
              ]}
              customClassName="flex flex-row w-4/5 items-center justify-between mb-2"
              inputBoxCustomClassName="w-full ml-2 border"
            />
            <button
              className="sm:w-2/5 border border-gray-300 rounded-md bg-black text-white text-sm my-3 py-1 sm:mb-0 sm:mr-2"
              onClick={handleAddMovement}
              disabled={!movementName || !movementTime}
            >
              Save
            </button>
          </div>
        )}
        {success && (
          <ResponseModal
            success={true}
            message="Movement added successfully"
            close={onClose}
          />
        )}
        {error && (
          <ResponseModal
            success={false}
            message="Failed to add movement"
            close={onClose}
          />
        )}
      </div>
    </div>
  );
};

export default AddMovementToZoneNew;
