import { Routes, Route } from "react-router-dom";

import AdminDashboard from "./components/adminDashboard";
import Finances from "./components/finance";
import FundManagementForm from "./components/fundManagementForm";
import MonthDetail from "./components/monthlyMemberDetail";
import MemberPaymentHistory from "./components/memberPaymentHistory";
import MonthYearTable from "./components/monthYearMemberTable";
import PersonaDetail from "./components/personaDetail";
import LandingPage from "./components/landingPage";
import UpdateClientData from "./features/ClientProfile/UpdateClientData";
import ClientProfile from "./features/ClientProfile/ClientProfile";
import MonthlyMetrics from "./features/monthlyMetrics";
import RenewalReport from "./features/renewalReport";
import ClientAnalytics from "./features/clientAnalytics";
import LifestyleDesign from "./features/lifestyleDesign";
import UpdateWorkout from "./features/workout.js/updateWorkout";
import CoachTools from "./features/coachToolsHome/CoachTools";
import WeeklyCheckIn from './features/weeklyCheckIn';
import Questionnaire from "./features/Questionnaire/Questionnaire";
import CaloriesTracker from "./features/nutrition";
import WeeklySchedule from "./features/weeklyPlan";
import MemberFeedback from "./features/feedback";
import CoachTools2 from "./features/newCoachTools/CoachTools2";
import ClientProfile2 from "./features/newClientProfile/ClientProfile2";
import Nutrition from "./features/ClientProfile/nutrition";
import DailyLifestyle from "./features/dailyLifestyle";


const AppRouter = () => {
  return (
    <Routes>
      <Route path="/finance/add" Component={FundManagementForm} />
      <Route path="/finance" Component={Finances} />
      <Route path="/finance/member" Component={MemberPaymentHistory} />

      <Route path="/persona/:persona" Component={PersonaDetail} />

      <Route path="/stats/:year/:month" Component={MonthDetail} />
      <Route path="/stats" Component={MonthYearTable} />

      <Route path="/coach-tools" Component={CoachTools} />
      <Route path="/dashboard" Component={AdminDashboard} />

      <Route path="/client/:clientCode/edit" Component={UpdateClientData} />
      <Route path="/client/:clientCode" Component={ClientProfile} />
      <Route path="/client/:clientCode/update-workout" Component={UpdateWorkout} />
      <Route path="/client/renewal-report" Component={RenewalReport} />
      <Route path="/client/:clientCode/lifestyle" Component={DailyLifestyle} />
      <Route path='/client/:clientCode/nutrition' Component={Nutrition} />
      <Route path="/client/:clientCode/weekly-checkin" element={<WeeklyCheckIn />} />
      <Route path="/client/:clientCode/weekly-plan" element={<WeeklySchedule />} />
      <Route path="/client/:clientCode/lifestyle/new" element={<LifestyleDesign />} />

      <Route path="/analytics/client" Component={ClientAnalytics} />
      <Route path="/monthly-metrics" Component={MonthlyMetrics} />

      <Route path="/questionnaire" element={<Questionnaire />} />

      <Route path="/coach-tools-2" element={<CoachTools2/>}/>
      <Route path="/client2/:clientCode" Component={ClientProfile2} />
      <Route path="/" Component={LandingPage} />
      {/* <Route path="*" Component={NotFound} /> */}
    </Routes>
  );
};

export default AppRouter;
