import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Trash2, Pencil, X } from "lucide-react";
import { DAILY_LIFESTYLE_TASKS } from "../../../staticData";

import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { StaticTimePicker } from "@mui/x-date-pickers";
import DeleteScreen from "../../../components/DeleteScreen";
import Duration from "../../../components/Duration";

const TaskPopup = ({ isOpen, onClose, onSave, initialTask = null }) => {
  const [selectedTask, setSelectedTask] = useState(initialTask?.name || "");
  const [selectedTime, setSelectedTime] = useState(
    initialTask?.time ? dayjs(initialTask.time, "hh:mm a") : dayjs()
  );
  const [timeChecked, setTimeChecked] = useState(true);
  const [description, setDescription] = useState(
    initialTask?.description || ""
  );
  const [duration, setDuration] = useState(initialTask?.duration || "");

  const handleDescriptionChange = (e) => {
    const inputText = e.target.value;
    setDescription(inputText);
  };

  useEffect(() => {
    if (initialTask) {
      setSelectedTask(initialTask.name || "");
      setSelectedTime(
        initialTask.time ? dayjs(initialTask.time, "hh:mm a") : dayjs()
      );
      setDescription(initialTask.description || "");
      setDuration(initialTask.duration || "");
    } else {
      setSelectedTask("");
      setSelectedTime(dayjs());
      setDescription("");
      setDuration("");
    }
  }, [initialTask]);

  if (!isOpen) return null;

  const handleSave = () => {
    const taskObj = DAILY_LIFESTYLE_TASKS.find(
      (task) => task.taskName === selectedTask
    );
    onSave(
      taskObj?.taskId || initialTask?.taskId,
      selectedTask,
      selectedTime.format("hh:mm a"),
      description,
      duration,
      timeChecked
    );
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white h-[60%] overflow-y-scroll  p-4 rounded-lg relative w-auto">
        <button onClick={onClose} className="absolute top-2 right-2">
          <X className="h-4 w-4" />
        </button>
        <h2 className="text-lg font-bold mb-3">
          {initialTask ? "Edit Task" : "Add New Task"}
        </h2>
        {selectedTask === "" && (
          <div className="text-sm text-red">*Please Select Task</div>
        )}

        <select
          className="w-full border border-gray-300 rounded-md p-1 mb-3 text-sm"
          value={selectedTask}
          onChange={(e) => setSelectedTask(e.target.value)}
        >
          <option value="">Select Task</option>
          {DAILY_LIFESTYLE_TASKS.map((task) => (
            <option key={task.taskId} value={task.taskName}>
              {task.taskName}
            </option>
          ))}
        </select>
        <div className="flex flex-col gap-2">
          <label>Description</label>
          <input
            placeholder="Enter Description"
            value={description}
            onChange={(e) => handleDescriptionChange(e)}
            type="text"
            className="border border-gray rounded-md pl-2 h-8"
          />
        </div>

        <Duration setDuration={setDuration} />

        <div className="mt-5">
          <label>
            <input
              className="w-4 h-4 pt-3"
              type="checkbox"
              checked={timeChecked}
              onChange={(e) => setTimeChecked(e.target.checked)}
            />
          </label>{" "}
          Time
        </div>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["TimeClock", "TimeClock"]}>
            <DemoItem>
              <StaticTimePicker
                disabled={!timeChecked}
                value={selectedTime}
                onChange={setSelectedTime}
                components={{
                  ActionBar: () => null, // Completely removes the action bar with OK and Cancel buttons
                }}
                sx={{
                  "& .MuiDialogActions-root": {
                    display: "none", // Hides OK and Cancel buttons inline
                  },
                }}
              />
            </DemoItem>
          </DemoContainer>
        </LocalizationProvider>
        <div className="w-full flex justify-end mt-3 gap-2">
          <button
            onClick={() => onClose()}
            className="bg-white border border-black px-2 py-1 rounded text-sm"
          >
            Cancel
          </button>
          <button
            onClick={handleSave}
            className="text-white  bg-black px-2 py-1 rounded text-sm"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

const Lifestyle = () => {
  const [lifestyle, setLifestyle] = useState([]);
  const [selectedCircle, setSelectedCircle] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const [isAddPopupOpen, setIsAddPopupOpen] = useState(false);
  const [deleteTaskConfimation, setDeleteTaskConfimation] = useState(false);
  const [editingTask, setEditingTask] = useState(null);
  const [deleteTaskId, setDeleteTaskId] = useState(null);
  const { clientCode } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const { email, name } = location.state || {};

  const handleGenerateNew = () => {
    console.log("generate new");
    navigate(`/client/${clientCode}/lifestyle/new`, {
      state: { email, name },
    });
  };

  const fetchLifestyleData = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_MAIN_SERVICE_BASE_URL}/api/v1/lifestyle?user=${clientCode}`
      );

      if (!response.data.success) {
        throw new Error("Failed to fetch lifestyle information");
      }

      const { lifeStyleDetails } = response.data;

      if (!lifeStyleDetails || !Array.isArray(lifeStyleDetails.circles)) {
        throw new Error("Unexpected data format: circles is not an array");
      }

      setLifestyle(lifeStyleDetails.circles);
      setSelectedCircle(lifeStyleDetails.circles[0]?.name || null);
    } catch (error) {
      console.error("Error fetching lifestyle data:", error);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchLifestyleData();
  }, [clientCode]);

  const handleCircleSelect = (circleName) => {
    setSelectedCircle(circleName);
  };

  const handleDeleteTask = async (taskId, circleName) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_MAIN_SERVICE_BASE_URL}/api/v1/lifestyle`,
        {
          memberCode: clientCode,
          taskId,
          circleName,
          action: "remove",
        }
      );
      await fetchLifestyleData();
      setDeleteTaskConfimation(false);
    } catch (error) {
      console.error("Error deleting task:", error);
      setError("Failed to delete task. Please try again.");
    }
  };

  const handleEditTask = (task) => {
    setEditingTask(task);
    setIsEditPopupOpen(true);
  };

  const handleDeleteTaskScreen = (id) => {
    setDeleteTaskConfimation(true);
    setDeleteTaskId(id);
  };

  const handleAddTask = () => {
    setEditingTask(null);
    setIsAddPopupOpen(true);
  };

  const handleSaveTask = async (
    taskId,
    taskName,
    time,
    description,
    duration,
    timeChecked
  ) => {
    try {
      let payload;
      if (editingTask) {
        // Edit existing task
        payload = {
          memberCode: clientCode,
          taskId: taskId,
          circleName: selectedCircle,
          action: "update",
          details: {
            time: time,
            description: description,
            duration: duration,
          },
        };
      } else {
        // Add new task
        payload = {
          memberCode: clientCode,
          taskId: taskId,
          taskName: taskName,
          circleName: selectedCircle,
          details: [
            {
              description: description,
              duration: duration,
            },
          ],
          action: "add",
          time: timeChecked === true ? time : null,
        };
      }

      await axios.put(
        `${process.env.REACT_APP_MAIN_SERVICE_BASE_URL}/api/v1/lifestyle`,
        payload
      );

      await fetchLifestyleData();
      setIsEditPopupOpen(false);
      setIsAddPopupOpen(false);
      setEditingTask(null);
    } catch (error) {
      console.error("Error saving task:", error);
      setError("Failed to save task. Please try again.");
    }
  };

  const selectedCircleTasks =
    lifestyle.find((circle) => circle.name === selectedCircle)?.tasks || [];

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="w-full max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
      {deleteTaskConfimation && (
        <DeleteScreen
          setDeleteTaskConfimation={setDeleteTaskConfimation}
          deleteItem={() => handleDeleteTask(deleteTaskId, selectedCircle)}
        />
      )}
      <div className="flex justify-end mb-4">
        <button
          onClick={() => handleGenerateNew()}
          className="bg-black text-white px-4 py-2 rounded mb-2 sm:mb-0"
        >
          Generate New
        </button>
      </div>

      <div className="mb-4 overflow-x-auto">
        <div className="bg-[#F5F7F8] flex flex-nowrap gap-2 p-2 rounded whitespace-nowrap">
          {lifestyle.map((circle) => (
            <button
              key={circle.name}
              onClick={() => handleCircleSelect(circle.name)}
              className={`px-3 py-1 rounded transition-colors duration-200 ${
                selectedCircle === circle.name
                  ? "bg-white text-black shadow-sm"
                  : "hover:bg-gray-200"
              }`}
            >
              {circle.name}
            </button>
          ))}
        </div>
      </div>

      {selectedCircle && (
        <div className="bg-white rounded-lg shadow p-4">
          <div className="flex justify-end mb-4">
            <button
              onClick={handleAddTask}
              className="text-white bg-black px-3 py-1 rounded text-sm"
            >
              Add Task +
            </button>
          </div>
          <ul className="">
            {selectedCircleTasks.map((task) => (
              <li
                key={task.id}
                className="border-b border-t p-3 border-[#EEEDEB]"
              >
                <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center gap-2">
                  <div>
                    <div className="font-medium">{task.name}</div>
                    <div className="text-sm text-gray-600">
                      {task.description}
                    </div>
                  </div>
                  <div className="flex items-center gap-3">
                    <span className="font-semibold text-sm">
                      {task.time || "Flexible"}
                    </span>
                    <button
                      onClick={() => handleEditTask(task)}
                      className="p-1 hover:bg-gray-100 rounded"
                    >
                      <Pencil className="h-4 w-4 text-blue-500" />
                    </button>
                    <button
                      // onClick={() =>
                      //   handleDeleteTask(task.taskId, selectedCircle)
                      // }

                      onClick={() => handleDeleteTaskScreen(task.taskId)}
                      className="p-1 hover:bg-gray-100 rounded"
                    >
                      <Trash2 className="h-4 w-4 text-red-500" />
                    </button>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}

      <TaskPopup
        isOpen={isEditPopupOpen || isAddPopupOpen}
        onClose={() => {
          setIsEditPopupOpen(false);
          setIsAddPopupOpen(false);
          setEditingTask(null);
        }}
        onSave={handleSaveTask}
        initialTask={editingTask}
      />
    </div>
  );
};

export default Lifestyle;
