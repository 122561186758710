import { format, parse } from 'date-fns';

export const MONTH_NAMES = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export function getFormattedDate() {
    const date = new Date();
    const month = MONTH_NAMES[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
    return `${month} ${day} ${year}`;
}

export function formatDate(dateString) {
    const dateParts = dateString.split(' ');
    const dateObj = new Date(`${dateParts[1]} ${dateParts[0]} ${dateParts[2]}`);
    const today = new Date();

    if (dateObj.toDateString() === today.toDateString()) {
        return ['Today', `${dateObj.toLocaleDateString('en-US', { month: 'long', day: 'numeric' })}`];
    } else {
        return [dateObj.toLocaleDateString('en-US', { weekday: 'long' }), `${dateObj.toLocaleDateString('en-US', { month: 'long', day: 'numeric' })}`];
    }
}

export const getDayOfWeek = (inputString) => {
    const date = parse(inputString, 'MMMM d yyyy', new Date());
    return format(date, 'EEE');
};

export const extractDay = (dateString) => {
    const dayRegex = /\s*[-,\.]?\s*(\d+)\s*[-,\.]?\s*/;
    try {
        const match = dateString.match(dayRegex);
        if (match) {
            return parseInt(match[1], 10);
        } else {
            throw new Error(`Invalid date format: ${dateString}`);
        }
    } catch (err) {
        console.error(`extractDay error: ${err}`);
        return NaN;
    }
};

export const getColor = (colors, percentCompletion) => {
    for (let i = 0; i < colors.length; i++) {
        if (percentCompletion <= colors[i].threshold) {
            return colors[i].color;
        }
    }
    return colors[colors.length - 1].color;
};

export const isIPhone = () => {
    const userAgent = navigator.userAgent;
    return userAgent.includes('iPhone');
};