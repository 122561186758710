import React from "react";

const Button = ({ content, onClick }) => {
  return (<button
    className="bg-gray-800 text-white border-none rounded-md px-4 py-2 font-semibold cursor-pointer transition-colors duration-300 ease-in-out hover:bg-gray-600"
    onClick={onClick}
  >
    {content}
  </button>);

};

export default Button;
