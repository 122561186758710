// src/features/WeeklyCheckIn/index.js

import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import axios from 'axios';

const WeeklyCheckIn = () => {
  const [weeklyCheckInData, setWeeklyCheckInData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { clientCode } = useParams();
  const location = useLocation();
  const { name } = location.state || {};

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_MAIN_SERVICE_BASE_URL}/api/v1/weekly-checkin?memberCode=${clientCode}`
        );
        setWeeklyCheckInData(response.data.data);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchData();
  }, [clientCode]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error loading data</div>;

  return (
    <div className="container mx-auto p-4 text-white">
      <h1 className="text-2xl font-bold mb-4">Weekly Check-Ins for {name}</h1>
      <div className="space-y-4">
        {weeklyCheckInData.map((checkIn) => (
          <div key={checkIn._id} className="bg-black bg-opacity-50 rounded-lg p-4">
            <div className="flex gap-8 flex-wrap">
              <p>
                <span className="text-xl font-semibold bg-clip-text text-transparent bg-gradient-to-r from-[#6482AD] to-[#B5CFB7]">
                  Date:
                </span>{" "}
                <span className="text-base">
                  {new Date(checkIn.createdAt).toLocaleString()}
                </span>
              </p>
              <p>
                <span className="text-xl font-semibold bg-clip-text text-transparent bg-gradient-to-r from-[#6482AD] to-[#B5CFB7]">
                  Rating:
                </span>{" "}
                <span className="text-base">{checkIn.rating}/10</span>
              </p>
              <p>
                <span className="text-xl font-semibold bg-clip-text text-transparent bg-gradient-to-r from-[#6482AD] to-[#B5CFB7]">
                  Weight:
                </span>{" "}
                <span className="text-base">{checkIn.weight} KG</span>
              </p>
            </div>
            <div className="flex gap-4 flex-wrap mt-2">
              <p>
                <span className="text-xl font-semibold bg-clip-text text-transparent bg-gradient-to-r from-[#6482AD] to-[#B5CFB7]">
                  Achievement:
                </span>{" "}
                <span className="text-base">{checkIn.achievement}</span>
              </p>
              <p>
                <span className="text-xl font-semibold bg-clip-text text-transparent bg-gradient-to-r from-[#6482AD] to-[#B5CFB7]">
                  Learning:
                </span>{" "}
                <span className="text-base">{checkIn.learning}</span>
              </p>
            </div>
            {checkIn.imageURLs && checkIn.imageURLs.length > 0 && (
  <div className="mt-2 flex flex-wrap">
    {checkIn.imageURLs.map((url, imgIndex) => (
      <div key={imgIndex} className="m-2 w-auto h-72 overflow-hidden">
        <img
          src={url}
          alt={`Check-in ${imgIndex + 1}`}
          className="w-full h-full object-cover"
        />
      </div>
    ))}
  </div>
)}
          </div>
        ))}
      </div>
    </div>
  );
};

export default WeeklyCheckIn;