import React from "react";
import { Radar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";
import { convertMonthlySkillPoint, getPreviousAndPreviousToPreviousMonth } from "./chartUtils";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

const RadarChart = ({ radarChartData }) => {
  const { currMonthSkillPoint, prevMonthSkillPoint, bestSkill } = radarChartData;

  if(!currMonthSkillPoint || !prevMonthSkillPoint) {
    return <></>;
  }

  const currentMonthData = convertMonthlySkillPoint(currMonthSkillPoint);
  const prevMonthData = convertMonthlySkillPoint(prevMonthSkillPoint);
  const [previousMonthName, previousToPreviousMonthName] = getPreviousAndPreviousToPreviousMonth();

  const maxScaleUnit = (Math.floor(Math.max(...currentMonthData, ...prevMonthData) / 5) + 1) * 5;

  const data = {
    labels: ["Endurance", "Pull", "Squat", "Core", "Push"],
    datasets: [
      {
        label: "Current Month",
        data: currentMonthData,
        backgroundColor: "rgba(255, 255, 255, 0.2)",
        borderColor: "rgba(94, 204, 123, 1)",
        borderWidth: 2,
        borderJoinStyle: "miter",
        pointRadius: 0,
        pointHoverRadius: 0,
      },
      {
        label: "Previous Month",
        data: prevMonthData,
        backgroundColor: "rgba(255, 255, 255, 0.2)",
        borderColor: "rgba(126, 135, 239, 1)",
        borderWidth: 2,
        borderJoinStyle: "miter",
        pointRadius: 0,
        pointHoverRadius: 0,
      },
    ],
  };
  const options = {
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      r: {
        min: 0,
        max: maxScaleUnit,
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
        pointLabels: {
          display: true,
          font: {
            size: 14,
            weight: 'bold'
          },
          color: ['#9c80ff', '#80d1ff', '#ffea80', '#a3ff80', '#ff8080'] // Colors for Endurance, Pull, Squat, Core, Push
        },
        angleLines: {
          display: true,
          color: 'rgba(255, 255, 255, 0.2)',
          borderDash: [5, 5], // This creates the dotted line effect
        },
      },
    },
  };

  return (
    <div className="w-full h-auto mt-4">
      <Radar data={data} options={options} />
      <p className="text-start text-[#ffffff42]" style={{marginBlock: '1em'}}>
        <span className="text-[#7E87EF]">{bestSkill},&nbsp;</span> is your strongest element of fitness
      </p>
    </div>
  );
};

export default RadarChart;