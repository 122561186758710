import axios from "axios";
import React, { useEffect, useState } from "react";
import InputField from "../../components/common/inputField";
import { useNavigate, useParams } from "react-router-dom";

import { IoIosRadioButtonOn, IoIosRadioButtonOff } from "react-icons/io";

import {
  ALL_AVAILABLE_COACHES,
  ALL_AVAILABLE_EQUIPMENTS,
  ALL_AVAILABLE_MVTM_BLACKLIST_TAGS,
  ALL_AVAILABLE_MVTM_LEVEL_TAGS,
  DEFAULT_SECTION_FORMATS,
  DEFAULT_SECTION_MVMT_COUNT,
  ROUND_OPTIONS,
  SECTION_CODE_NAME,
  WORKOUT_SPLIT_DATA,
} from "../../staticData";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";

const Modal = ({ message, close }) => {
  return (
    <>
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          backdropFilter: "blur(2px)",
          zIndex: 1000,
        }}
      ></div>
      <div
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          padding: "20px",
          zIndex: 1001,
        }}
      >
        <h4 className="text-black">{message}</h4>
        <button
          className="w-full border border-gray-300 rounded-md bg-generalButton text-white text-sm mx-2 py-2 px-1"
          onClick={close}
        >
          Close
        </button>
      </div>
    </>
  );
};

const UpdateClientDataNew = () => {
  const navigate = useNavigate();

  const navigateToCoachTools = () => {
    navigate("/coach-tools-2");
  };

  const { clientCode } = useParams();

  const [clientData, setClientData] = useState({});

  const [clientEquipments, setClientEquipments] = useState([]);
  const [clientSections, setClientSections] = useState([]);
  const [clientSectionFormat, setClientSectionFormat] = useState([]);
  const [clientMetconDuration, setClientMetconDuration] = useState("");
  const [clientBlackListTags, setClientBlackListTags] = useState([]);
  const [clientMvmtLevelTags, setClientMvmtLevelTags] = useState([]);
  const [clientCoach, setClientCoach] = useState("");
  const [workoutSplitNum, setWorkoutSplitNum] = useState(null);

  const [apiStatus, setApiStatus] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isWorkoutModalOpen, setIsWorkoutModalOpen] = useState(false);

  const fetchClientData = async () => {
    try {
      const clientDataResponse = await axios.get(
        `${process.env.REACT_APP_INSIGHT_SERVICE_BASE_URL}/client/coachMember?code=${clientCode}`
      );
      const {
        equipment,
        sections,
        format,
        metconDuration,
        blackListedTags,
        mvmtLevelTags,
        coach,
        workoutSplit,
      } = clientDataResponse.data;

      setClientData(clientDataResponse.data);
      if (equipment) {
        setClientEquipments(equipment);
      } else {
        setClientEquipments([]);
      }

      if (format) {
        setClientSectionFormat(format);
      } else {
        setClientSectionFormat([]);
      }

      if (sections && sections.length > 0) {
        setClientSections(sections[0]);
      } else {
        setClientSections([]);
      }

      if (metconDuration) {
        setClientMetconDuration(metconDuration);
      } else {
        setClientMetconDuration(0);
      }

      if (mvmtLevelTags) {
        setClientMvmtLevelTags(mvmtLevelTags);
      }

      if (coach) {
        setClientCoach(coach);
      }

      if (blackListedTags) {
        setClientBlackListTags(blackListedTags);
      }
      if (workoutSplit) {
        setWorkoutSplitNum(workoutSplit);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (clientCode) {
      fetchClientData();
    }
  }, [clientCode]);

  const updateOrInsertUserMvmtCount = (formats, name, value) => {
    const existingFormatIndex = formats.findIndex(
      (format) => format.sectionCode === name
    );

    if (existingFormatIndex !== -1) {
      const updatedFormats = [...formats];
      updatedFormats[existingFormatIndex] = {
        ...updatedFormats[existingFormatIndex],
        mvmtCount: value,
      };
      return updatedFormats;
    }

    return [...formats, { sectionCode: name, rounds: value }];
  };

  const handleChange = (name, value) => {
    if (name.startsWith("sectionFormatRounds-")) {
      const sectionCode = name.split("-")[1];

      setClientSectionFormat((prevFormats) =>
        updateOrInsertFormat(prevFormats, sectionCode, `${value} Rounds`)
      );
    } else if (name === "METCON") {
      setClientMetconDuration(value);
    }

    if (name.startsWith("sectionFormatMvmts-")) {
      const sectionCode = name.split("-")[1];

      setClientSectionFormat((prevFormats) =>
        updateOrInsertUserMvmtCount(prevFormats, sectionCode, value)
      );
    }
  };

  const updateOrInsertFormat = (formats, name, value) => {
    const existingFormatIndex = formats.findIndex(
      (format) => format.sectionCode === name
    );

    if (existingFormatIndex !== -1) {
      const updatedFormats = [...formats];
      updatedFormats[existingFormatIndex] = {
        ...updatedFormats[existingFormatIndex],
        rounds: value,
      };
      return updatedFormats;
    }

    return [...formats, { sectionCode: name, rounds: value }];
  };

  const updateUser = async () => {
    try {
      await axios.put(
        `${process.env.REACT_APP_INSIGHT_SERVICE_BASE_URL}/client/coachMember`,
        {
          code: clientCode,
          format: clientSectionFormat,
          equipment: clientEquipments,
          metconDuration: clientMetconDuration,
          section: clientSections,
          blackListedTag: clientBlackListTags,
          mvmtLevelTag: clientMvmtLevelTags,
          workoutSplit: workoutSplitNum,
        }
      );
      setApiStatus({ success: true, message: "Update successful!" });
      setIsModalOpen(true);
    } catch (error) {
      console.error(error);
      setApiStatus({ success: false, message: "Update failed!" });
      setIsModalOpen(true);
    }
  };

  const handleToggleGeneral = (data, type) => {
    switch (type) {
      case "equipment":
        toggleEquipment(data, clientEquipments, setClientEquipments);
        break;
      case "sectionFormat":
        toggleSectionFormat(data);
        break;
      case "blackListTag":
        toggleEquipment(data, clientBlackListTags, setClientBlackListTags);
        break;
      case "mvmtLevelTag":
        toggleEquipment(data, clientMvmtLevelTags, setClientMvmtLevelTags);
        break;
      case "coach":
        setClientCoach((prevData) => (prevData !== data ? data : ""));
        break;
      case "workoutSplit":
        setWorkoutSplitNum((prevData) => (prevData !== data ? data : ""));
        break;
      default:
        break;
    }
  };

  const toggleEquipment = (item, array, setArray) => {
    setArray((prevArray) =>
      prevArray.includes(item)
        ? prevArray.filter((element) => element !== item)
        : [...prevArray, item]
    );
  };

  const isSectionActive = (sectionCode) => {
    const isPresentInSections = clientSections.includes(sectionCode);
    const isPresentInSectionFormat =
      clientSectionFormat &&
      clientSectionFormat.some((csf) => csf.sectionCode === sectionCode);

    // console.log(sectionCode, isPresentInSections || isPresentInSectionFormat);
    return isPresentInSections || isPresentInSectionFormat;
  };

  const toggleSectionFormat = (data) => {
    const dataExists = clientSectionFormat
      ? clientSectionFormat.some((csf) => csf.sectionCode === data.sectionCode)
      : false;

    setClientSectionFormat((prevSectionFormat) =>
      dataExists
        ? prevSectionFormat.filter(
            (psf) => psf.sectionCode !== data.sectionCode
          )
        : [
            ...(prevSectionFormat ? prevSectionFormat : []),
            { sectionCode: data.sectionCode, rounds: "2 Rounds" },
          ]
    );

    setClientSections((prevSections) =>
      dataExists
        ? prevSections.filter((ps) => ps !== data.sectionCode)
        : [...prevSections, data.sectionCode]
    );
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleUpdateWorkout = async () => {
    try {
      await axios.put(
        `${process.env.REACT_APP_MAIN_SERVICE_BASE_URL}/api/v1/workout/hyper`,
        {
          memberCode: clientCode,
        }
      );
      setApiStatus({ success: true, message: "Workout update successful!" });
    } catch (error) {
      console.error(error);
      setApiStatus({ success: false, message: "Workout update failed!" });
    } finally {
      setIsWorkoutModalOpen(false);
      setIsModalOpen(true);
    }
  };

  const openWorkoutModal = () => {
    setIsWorkoutModalOpen(true);
  };

  const closeWorkoutModal = () => {
    setIsWorkoutModalOpen(false);
  };

  return (
    <div className="flex flex-col sm:p-4 p-1 text-black justify-center items-center w-full max-w-7xl mx-auto">
      {Object.keys(clientData).length > 0 && (
        <div className="flex flex-col lg:flex-row w-full mb-3">
          <div className="flex flex-col w-full lg:w-1/2 lg:pr-4 ">
            <div className="mb-3 border  rounded-xl overflow-hidden">
              <Accordion className="border-none ">
                <AccordionSummary
                  expandIcon={"+"}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography>Equipments</Typography>
                </AccordionSummary>
                <AccordionDetails className="flex flex-col gap-3">
                  {ALL_AVAILABLE_EQUIPMENTS.map((eq, idx) => (
                    <div
                      key={idx}
                      className="flex items-center justify-between border rounded-md p-2 bg-white text-black"
                    >
                      <button
                        className={`ml-1 w-10 h-6 flex items-center rounded-full p-1 ${
                          clientEquipments.includes(eq)
                            ? "bg-green"
                            : "bg-[#F5F5F5]"
                        }`}
                        onClick={() => handleToggleGeneral(eq, "equipment")}
                      >
                        <span
                          className={`bg-white w-4 h-4 rounded-full shadow-md transform transition-transform duration-300 ${
                            clientEquipments.includes(eq)
                              ? "translate-x-4"
                              : "translate-x-0"
                          }`}
                        ></span>
                      </button>
                      <span className="text-sm">{eq}</span>
                    </div>
                  ))}
                </AccordionDetails>
              </Accordion>
            </div>
            {/* 
            <div className="border rounded-md backdrop-blur-md p-3 mb-6">
              <h4 className="text-lg font-semibold mb-4">Equipments</h4>
              <div className="flex flex-col gap-3">
                {ALL_AVAILABLE_EQUIPMENTS.map((eq, idx) => (
                  <div
                    key={idx}
                    className="flex items-center justify-between border rounded-md p-2 bg-white text-black"
                  >
                    <button
                      className={`ml-1 w-10 h-6 flex items-center rounded-full p-1 ${
                        clientEquipments.includes(eq)
                          ? "bg-green"
                          : "bg-[#F5F5F5]"
                      }`}
                      onClick={() => handleToggleGeneral(eq, "equipment")}
                    >
                      <span
                        className={`bg-white w-4 h-4 rounded-full shadow-md transform transition-transform duration-300 ${
                          clientEquipments.includes(eq)
                            ? "translate-x-4"
                            : "translate-x-0"
                        }`}
                      ></span>
                    </button>
                    <span className="text-sm">{eq}</span>
                  </div>
                ))}
              </div>
            </div> */}

            <div className="flex  gap-4 mb-6 justify-center sm:justify-start">
              <div className="w-full sm:w-1/2 border rounded-md backdrop-blur-md p-3">
                <h4 className="text-lg font-semibold mb-3">
                  Black Listed Tags
                </h4>
                <div className="flex flex-col gap-3">
                  {ALL_AVAILABLE_MVTM_BLACKLIST_TAGS.map((tag, idx) => (
                    <div
                      key={idx}
                      className="flex items-center justify-between border rounded-md p-2 bg-white text-black"
                    >
                      <button
                        className={`w-10 h-6 flex items-center rounded-full p-1 ${
                          clientBlackListTags.includes(tag)
                            ? "bg-green"
                            : "bg-[#F5F5F5]"
                        }`}
                        onClick={() => handleToggleGeneral(tag, "blackListTag")}
                      >
                        <span
                          className={`bg-white w-4 h-4 rounded-full shadow-md transform transition-transform duration-300 ${
                            clientBlackListTags.includes(tag)
                              ? "translate-x-4"
                              : "translate-x-0"
                          }`}
                        ></span>
                      </button>
                      <span className="text-sm">{tag}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-4 mb-6">
              <div className="rounded-lg  h-min  overflow-hidden border border-gray">
                <Accordion>
                  <AccordionSummary
                    expandIcon={"+"}
                    className="text-lg font-semibold "
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Typography>Workout Split</Typography>
                  </AccordionSummary>

                  <div className="flex flex-col gap-2 px-3 pb-3">
                    {WORKOUT_SPLIT_DATA.map((tag, idx) => (
                      <span
                        key={idx}
                        className={`flex items-center  border rounded-md p-2 cursor-pointer text-sm ${
                          workoutSplitNum === null
                            ? tag.splitId === 5 && "bg-green text-white"
                            : tag.splitId === workoutSplitNum
                              ? "bg-green text-white"
                              : "bg-white text-black"
                        }`}
                        onClick={() =>
                          handleToggleGeneral(tag.splitId, "workoutSplit")
                        }
                      >
                        {tag.splitData}
                      </span>
                    ))}
                  </div>
                </Accordion>
              </div>

              <div className="rounded-lg  h-min  overflow-hidden border border-gray">
                <Accordion>
                  <AccordionSummary
                    expandIcon={"+"}
                    className="text-lg font-semibold "
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Typography>Workout Level Tags</Typography>
                  </AccordionSummary>

                  <div className="flex flex-col gap-3 px-3 pb-3">
                    {ALL_AVAILABLE_MVTM_LEVEL_TAGS.map((tag, idx) => (
                      <div
                        key={idx}
                        className="flex items-center justify-between border rounded-md p-2 bg-white text-black"
                      >
                        <button
                          className={`w-10 h-6 flex items-center rounded-full p-1 ${
                            clientMvmtLevelTags.includes(tag)
                              ? "bg-green"
                              : "bg-[#F5F5F5]"
                          }`}
                          onClick={() =>
                            handleToggleGeneral(tag, "mvmtLevelTag")
                          }
                        >
                          <span
                            className={`bg-white w-4 h-4 rounded-full shadow-md transform transition-transform duration-300 ${
                              clientMvmtLevelTags.includes(tag)
                                ? "translate-x-4"
                                : "translate-x-0"
                            }`}
                          ></span>
                        </button>
                        <span className="text-sm">{tag}</span>
                      </div>
                    ))}
                  </div>
                </Accordion>
              </div>
            </div>
          </div>

          <div className="w-full lg:w-1/2">
            <div className="rounded-lg  h-min  overflow-hidden border border-gray ">
              <Accordion>
                <AccordionSummary
                  expandIcon={"+"}
                  className="text-lg font-semibold "
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography>Section Format</Typography>
                </AccordionSummary>

                <div className="flex flex-col gap-4 px-3 pb-3">
                  {DEFAULT_SECTION_FORMATS.map((dsf, index) => {
                    const isActive = isSectionActive(dsf.sectionCode);
                    const formatData = clientSectionFormat.find(
                      (format) => format.sectionCode === dsf.sectionCode
                    );

                    const movementCountData = DEFAULT_SECTION_MVMT_COUNT.find(
                      (format) => format.section === dsf.sectionCode
                    );

                    const rounds =
                      isActive && formatData ? parseInt(formatData.rounds) : 0;

                    const movementCount =
                      isActive && formatData && formatData.mvmtCount
                        ? formatData.mvmtCount
                        : movementCountData?.mvmtCount;

                    return (
                      <div
                        key={index}
                        className="flex flex-col sm:flex-row sm:gap-0 gap-4 items-center justify-between border rounded-md p-2 bg-white"
                      >
                        <div className="flex items-center gap-2">
                          <button
                            className={`w-10 h-6 flex items-center rounded-full p-1 ${
                              isActive ? "bg-green" : "bg-[#F5F5F5]"
                            }`}
                            onClick={() =>
                              handleToggleGeneral(dsf, "sectionFormat")
                            }
                          >
                            <span
                              className={`bg-white w-4 h-4 rounded-full shadow-md transform transition-transform duration-300 ${
                                isActive ? "translate-x-4" : "translate-x-0"
                              }`}
                            ></span>
                          </button>
                          <span className="text-sm">
                            {SECTION_CODE_NAME[dsf.sectionCode]}
                          </span>
                        </div>
                        <div className="">
                          {isActive && dsf.sectionCode !== "METCON" && (
                            <div className="flex items-center gap-2">
                              <button
                                className={`w-4 h-4 rounded-full bg-gray-200 flex items-center justify-center text-xl ${
                                  rounds <= 1
                                    ? "opacity-50 cursor-not-allowed"
                                    : ""
                                }`}
                                onClick={() =>
                                  handleChange(
                                    `sectionFormatRounds-${dsf.sectionCode}`,
                                    Math.max(1, rounds - 1)
                                  )
                                }
                                disabled={rounds <= 1}
                              >
                                -
                              </button>
                              <span className=" w-24 text-center">
                                {rounds} Rounds
                              </span>
                              <button
                                className={`w-8 h-8 rounded-full bg-gray-200 flex items-center justify-center text-xl ${
                                  rounds >= 5
                                    ? "opacity-50 cursor-not-allowed"
                                    : ""
                                }`}
                                onClick={() =>
                                  handleChange(
                                    `sectionFormatRounds-${dsf.sectionCode}`,
                                    Math.min(5, rounds + 1)
                                  )
                                }
                                disabled={rounds >= 5}
                              >
                                +
                              </button>
                            </div>
                          )}

                          {isActive && dsf.sectionCode === "METCON" && (
                            <div className="flex items-center gap-2">
                              <button
                                className={`w-4 h-4 rounded-full bg-gray-200 flex items-center justify-center text-xl ${
                                  rounds <= 1
                                    ? "opacity-50 cursor-not-allowed"
                                    : ""
                                }`}
                                onClick={() =>
                                  handleChange(
                                    "METCON",
                                    Number(clientMetconDuration) > 0
                                      ? Number(clientMetconDuration) - 1
                                      : 1
                                  )
                                }
                                disabled={clientMetconDuration <= 1}
                              >
                                -
                              </button>
                              <InputField
                                name="METCON"
                                value={clientMetconDuration}
                                onChange={handleChange}
                                customClassName=" border-none rounded-md bg-white text-black text-sm  w-12"
                                inputBoxCustomClassName="p-2 border border-gray-300 text-center rounded w-full"
                              />{" "}
                              Mins
                              <button
                                className={`w-8 h-8 rounded-full bg-gray-200 flex items-center justify-center text-xl ${
                                  rounds >= 5
                                    ? "opacity-50 cursor-not-allowed"
                                    : ""
                                }`}
                                onClick={() =>
                                  handleChange(
                                    "METCON",
                                    Number(clientMetconDuration) + 1
                                  )
                                }
                              >
                                +
                              </button>
                            </div>
                          )}

                          {isActive && dsf.sectionCode !== "METCON" && (
                            <div className="flex items-center gap-2">
                              <button
                                className={`w-4 h-4 rounded-full bg-gray-200 flex items-center justify-center text-xl ${
                                  movementCount <= 1
                                    ? "opacity-50 cursor-not-allowed"
                                    : ""
                                }`}
                                onClick={() =>
                                  handleChange(
                                    `sectionFormatMvmts-${dsf.sectionCode}`,
                                    Math.max(1, movementCount - 1)
                                  )
                                }
                                disabled={movementCount <= 1}
                              >
                                -
                              </button>
                              <span className=" w-28 text-center">
                                {movementCount} Movements
                              </span>
                              <button
                                className={`w-8 h-8 rounded-full bg-gray-200 flex items-center justify-center text-xl ${
                                  movementCount >= 5
                                    ? "opacity-50 cursor-not-allowed"
                                    : ""
                                }`}
                                onClick={() =>
                                  handleChange(
                                    `sectionFormatMvmts-${dsf.sectionCode}`,
                                    Math.min(5, movementCount + 1)
                                  )
                                }
                                disabled={movementCount >= 5}
                              >
                                +
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </Accordion>
            </div>
          </div>
        </div>
      )}

      <div className="mt-6 flex flex-col sm:flex-row justify-center w-full sm:w-auto gap-2">
        <button
          className="w-full sm:w-auto border border-gray-300 rounded-md bg-black text-white text-sm py-2 px-4"
          onClick={updateUser}
        >
          Update Member
        </button>
        <button
          className="w-full sm:w-auto border border-gray-300 rounded-md bg-black text-white text-sm py-2 px-4"
          onClick={openWorkoutModal}
        >
          Update Workout
        </button>
      </div>

      {isModalOpen && <Modal message={apiStatus.message} close={closeModal} />}

      {isWorkoutModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex justify-center items-center z-50">
          <div className="bg-gray-800 rounded-lg p-6 w-11/12 max-w-md">
            <p className="text-white text-center mb-4">
              Pressing continue will update the latest workout, else the changes
              will be reflected in the next workout
            </p>
            <div className="flex flex-col sm:flex-row justify-center items-center">
              <button
                className="w-full sm:w-auto border border-gray-300 rounded-md bg-generalButton text-white text-sm py-2 px-4 mb-2 sm:mb-0 sm:mr-2"
                onClick={closeWorkoutModal}
              >
                Abort
              </button>
              <button
                className="w-full sm:w-auto border border-gray-300 rounded-md bg-green text-white text-sm py-2 px-4"
                onClick={handleUpdateWorkout}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UpdateClientDataNew;
