import React from "react";

const TableHeader = ({ headerData, showDetail }) => {
  return (
    <thead>
      <tr>
        {headerData.map((key, index) => (
          <th key={index}>{key}</th>
        ))}
        {showDetail && <th></th>}
      </tr>
    </thead>
  );
};

export default TableHeader;
