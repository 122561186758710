import React from "react";
import { Link } from "react-router-dom";

const NavBar = () => {
  return (
    <nav className="flex bg-white p-3">
      <Link to="/" className="">
        <img
          src={"/assets/otm-banner.png"}
          alt="OnTheMove Logo"
          width="100"
          height="auto"
        />
      </Link>
    </nav>
  );
};

export default NavBar;
