import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Button from "./common/button";
import ModelChart from "./common/modelChart";
import MonthYearTable from "./monthYearMemberTable";
import PersonaTagsTable from "./personaTagsTable";

import "../styles/dashboard.css";

const AdminDashboard = () => {
  const isAdmin = localStorage.getItem("isAdmin") === "true";
  const navigate = useNavigate();

  if (!isAdmin) {
    navigate("/");
  }

  const [memberTags, setMemberTags] = useState([]);
  const [keys, setKeys] = useState([]);
  const [avgStay, setAvgStay] = useState(0);

  useEffect(() => {
    fetchData();
  }, []);

  const handleAdminClick = () => {
    navigate("/finance");
  };

  const navigateToMonthlyMetrics = () => {
    navigate("/monthly-metrics");
  };

  const fetchData = async () => {
    try {
      const mtResponse = await axios.get(
        `${process.env.REACT_APP_ANALYTICS_SERVICE_BASE_URL}/member/tags`
      );
      setMemberTags(mtResponse.data);

      const keys = Object.keys(mtResponse.data[0]);
      const excludeKeys = ["name"];
      const filteredKeys = keys.filter((key) => !excludeKeys.includes(key));

      setKeys(filteredKeys);

      const atResponse = await axios.get(
        `${process.env.REACT_APP_ANALYTICS_SERVICE_BASE_URL}/attendance/stats/member-lifespan`
      );
      setAvgStay(atResponse.data);
    } catch (err) {
      console.log("error in fetching tag", err);
    }
  };

  return (
    <div>
      <div className="average-stay">
        An average member stays for {avgStay.averageDays} days !
      </div>
      <div className="d-flex flex-row align-items-center justify-content-center">
        <div className="d-flex flex-row">
          <Button
            className="m-2"
            content="Finance"
            onClick={handleAdminClick}
          />

          <Button
            className="m-2"
            content="Monthly Metrics"
            onClick={navigateToMonthlyMetrics}
          />
        </div>
      </div>

      <div className="pieAnalyticsContainer">
        {keys.map((key, idx) => {
          return <ModelChart data={memberTags} gkey={key} key={idx} />;
        })}
      </div>
      <div className="tableContainer">
        <MonthYearTable />
      </div>
      <div className="tableContainer">
        <PersonaTagsTable tagsData={memberTags} />
      </div>
    </div>
  );
};

export default AdminDashboard;
