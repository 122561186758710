const SelectCoach = ({ setCoachConfimation, selectCoach, selectedCoach }) => {
  return (
    <div className="absolute top-0 left-0 h-screen w-screen flex justify-center backdrop-blur-sm items-center z-10">
      <div className="bg-white border flex flex-col items-center border-black px-5 py-3 rounded-xl mb-32">
        Are you sure you want to assign {selectedCoach} as coach?
        <div className="flex justify-between w-full mt-3">
          <div
            onClick={() => setCoachConfimation(false)}
            className=" rounded-md border border-black w-min px-3 py-1 mt-3 hover:cursor-pointer"
          >
            No
          </div>
          <div
            onClick={() => selectCoach()}
            className="bg-black rounded-md text-white w-min px-3 py-1 mt-3 hover:cursor-pointer"
          >
            Yes
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectCoach;
