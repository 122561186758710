import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

const WorkoutChart = ({ workouts }) => {
  // Reverse the workouts array to show the latest week first
  const reversedWorkouts = [...workouts].reverse();

  // Function to determine color based on count
  const getColor = (count) => {
    if (count < 2) return "rgba(255, 99, 132, 0.6)"; // Red
    if (count >= 2 && count <= 4) return "rgba(255, 206, 86, 0.6)"; // Yellow
    return "rgba(75, 192, 192, 0.6)"; // Green
  };

  // Map data to format required by Recharts
  const chartData = reversedWorkouts.map((workout) => ({
    week: workout.week.split("-")[1], // Display the second part of the week label
    count: workout.count,
    fill: getColor(workout.count), // Set color conditionally
  }));

  return (
    <BarChart
      width={600}
      height={300}
      data={chartData}
      margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis
        dataKey="week"
        label={{ value: "Week", position: "insideBottom", offset: -5 }}
      />
      <YAxis
        label={{ value: "Workouts", angle: -90, position: "insideLeft" }}
      />
      <Tooltip />
      <Legend />
      <Bar dataKey="count" fill="#8884d8" barSize={30}>
        {/* Apply fill color based on the workout count */}
        {chartData.map((entry, index) => (
          <cell key={`cell-${index}`} fill={entry.fill} />
        ))}
      </Bar>
    </BarChart>
  );
};

export default WorkoutChart;
