import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const customXAxisTickFormatter = (tick, isCompactView) => {
  const date = new Date(tick);
  const endDate = new Date(date);
  endDate.setDate(date.getDate() + 6);
  if (isCompactView) {
    return `${date.getDate()}/${date.getMonth() + 1}`;
  }
  return `${date.getDate()} ${date.toLocaleString("default", { month: "short" })} - ${endDate.getDate()} ${endDate.toLocaleString("default", { month: "short" })}`;
};

const TLSgraph = ({ selectedCoach }) => {
  const [graphData, setGraphData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [containerWidth, setContainerWidth] = useState(0);

  const measureRef = useCallback((node) => {
    if (node !== null) {
      setContainerWidth(node.getBoundingClientRect().width);
    }
  }, []);

  useEffect(() => {
    const fetchGraphData = async () => {
      setIsLoading(true);
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_MAIN_SERVICE_BASE_URL}/api/v1/workout/consistency/weekly-count`
        );
        const data = res.data.data.slice(-5); // Get only the latest 5 entries

        const processedData = data.map((entry) => {
          if (selectedCoach === "No Coach Selected") {
            return {
              week: new Date(entry.week.split("-")[0]),
              redClients: entry.redClients,
              yellowClients: entry.yellowClients,
              greenClients: entry.greenClients,
            };
          } else {
            const coachData = entry.coachWiseClients[selectedCoach] || {
              redClients: 0,

              yellowClients: 0,
              greenClients: 0,
            };
            return {
              week: new Date(entry.week.split("-")[0]),
              redClients: coachData.redClients,
              yellowClients: coachData.yellowClients,
              greenClients: coachData.greenClients,
            };
          }
        });

        setGraphData(processedData);
      } catch (error) {
        console.error("Error fetching graph data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchGraphData();
  }, [selectedCoach]);

  if (isLoading) {
    return (
      <p className="text-center text-lg text-black">Loading graph data...</p>
    );
  }

  const isCompactView = containerWidth < 500;

  return (
    <div className="w-full" ref={measureRef}>
      <ResponsiveContainer width="100%" height={400}>
        <AreaChart
          data={graphData}
          margin={{
            top: 22,
            right: 20,
            left: 20,
            bottom: isCompactView ? 60 : 20,
          }}
        >
          <XAxis
            dataKey="week"
            tickFormatter={(tick) =>
              customXAxisTickFormatter(tick, isCompactView)
            }
            tick={{
              fill: "#808080",
              fontSize: isCompactView ? 8 : 10,
              angle: isCompactView ? -45 : 0,
              textAnchor: isCompactView ? "end" : "middle",
            }}
            tickSize={8}
            interval={isCompactView ? 1 : 0}
            height={60}
          />
          <YAxis
            domain={[0, 80]}
            ticks={[0, 20, 40, 60, 80]}
            allowDataOverflow={true}
            label={{
              value: "CLIENT COUNT",
              angle: -90,
              position: "insideLeft",
              fill: "#808080",
              fontSize: 12,
            }}
            tick={{ fill: "#808080", fontSize: 12 }}
            tickSize={5}
            axisLine={false}
          />
          <CartesianGrid stroke="#808080" opacity={0.1} vertical={false} />
          <Tooltip
            contentStyle={{
              backgroundColor: "#1c1c1e",
              border: "none",
              borderRadius: "4px",
            }}
            labelStyle={{ color: "#808080" }}
            itemStyle={{ color: "#808080" }}
            formatter={(value, name) => [value, name.replace("Clients", "")]}
            labelFormatter={(label) =>
              customXAxisTickFormatter(label, isCompactView)
            }
          />
          <Area
            type="monotone"
            dataKey="redClients"
            stackId="1"
            stroke="#FA5757"
            fill="#FA5757"
          />

          <Area
            type="monotone"
            dataKey="yellowClients"
            stackId="1"
            stroke="#F5C563"
            fill="#F5C563"
          />
          <Area
            type="monotone"
            dataKey="greenClients"
            stackId="1"
            stroke="#5ECC7B"
            fill="#5ECC7B"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default TLSgraph;
