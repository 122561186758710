import React, { useState } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';

const ResponseModal = ({ message, close }) => {
    return (
        <>
            <div
                style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    backdropFilter: "blur(2px)",
                    zIndex: 1000,
                }}
            >
                <div
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        backgroundColor: "white",
                        padding: "20px",
                        zIndex: 1001,
                        textAlign: "center",
                        borderRadius: "8px",
                        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                    }}
                >
                    {message === "Hold on the report is getting ready..!" && (
                        <>
                            <CircularProgress />
                            <p style={{ marginTop: "20px" }}>{message}</p>
                        </>
                    )}
                    {message === "Failed to generate report" && (
                        <>
                            <p style={{ marginTop: "20px" }}>{message}</p>
                            <button onClick={close} style={{ marginTop: "20px" }}>Close</button>
                        </>
                    )}
                    {message === "Report generated successfully" && (
                        <>
                            <p style={{ marginTop: "20px" }}>{message}</p>
                            <button onClick={close} style={{ marginTop: "20px" }}>Close</button>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

const containerStyle = {
    marginTop: '2rem',
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "50vh",
    padding: "20px",
    boxSizing: "border-box"
};

const formStyle = {
    backgroundColor: "rgb(236, 221, 221)",
    padding: "20px",
    border: "1px solid #ddd",
    borderRadius: "8px",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
    maxWidth: "400px",
    width: "100%",
    textAlign: "center"
};

const buttonStyle = {
    marginTop: "40px",
    display: "block",
    width: "100%",
    padding: "10px",
    backgroundColor: "#007bff",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    transition: "background-color 0.3s"
};

const LifestyleDesign = () => {
    const location = useLocation();
    const { email, name } = location.state || {};
    const navigate = useNavigate();

    const navigateToCoachTools = () => {
        navigate("/coach-tools");
    };

    const [file, setFile] = useState(null);
    const [detailedDailyRoutine, setDetailedDailyRoutine] = useState('');
    const [response, setResponse] = useState(null);
    const [loading, setLoading] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [error, setError] = useState('');

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
        setError('');
    };

    const handleDetailedDailyRoutineChange = (e) => {
        setDetailedDailyRoutine(e.target.value);
        setError('');
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setModalMessage('');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!file && !detailedDailyRoutine) {
            setError('Please upload a file or enter a detailed daily routine.');
            return;
        }

        const formData = new FormData();
        if (file) formData.append('file', file);
        formData.append('email', email);
        if (detailedDailyRoutine) formData.append('detailedDailyRoutine', detailedDailyRoutine);

        setLoading(true);
        setResponse(null);
        setIsModalOpen(true);
        setModalMessage("Hold on the report is getting ready..!");

        try {
            const res = await axios.post(`${process.env.REACT_APP_MAIN_SERVICE_BASE_URL}/api/v1/lifestyle/generate`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            console.log('res ds', res.data, res.data.success, typeof res.data.success);
            if (res.data.success) {
                setResponse(res.data);
                setModalMessage("Report generated successfully");

            } else {
                setModalMessage("Failed to generate report");
            }
        } catch (error) {
            console.error('Error uploading file:', error);
            setModalMessage("Failed to generate report");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={containerStyle}>
            <button
                className='border border-gray-300 rounded-md bg-generalButton text-white text-sm px-2 py-2'
                style={{ marginTop: '6rem' }}
                onClick={navigateToCoachTools}
            >
                Coach Tools
            </button>
            <h3 style={{ marginTop: '4rem', marginBottom: '3rem' }} className='text-white'>
                Lifestyle Design for {name}
            </h3>
            <form onSubmit={handleSubmit} style={formStyle}>
                <div>
                    <label style={{ fontSize: 'large', marginBottom: '3rem' }}>
                        Upload data For Lifestyle Design
                    </label>
                    <input
                        style={{ marginBottom: '1rem' }}
                        type="file"
                        onChange={handleFileChange}
                    />
                </div>
                <div>
                    <label style={{ fontSize: 'large', marginBottom: '1rem' }}>
                        Detailed Daily Routine
                    </label>
                    <textarea
                        style={{
                            width: '100%',
                            padding: '10px',
                            borderRadius: '4px',
                            border: '1px solid #ddd',
                            fontSize: 'large',
                            resize: 'none',
                            overflow: 'hidden',
                        }}
                        rows={1}
                        value={detailedDailyRoutine}
                        onChange={handleDetailedDailyRoutineChange}
                    />
                </div>
                {error && <p style={{ color: 'red' }}>{error}</p>}
                <button type="submit" style={buttonStyle}>Generate </button>
            </form>

            {isModalOpen && (
                <ResponseModal message={modalMessage} close={handleCloseModal} />
            )}

        </div>
    );
};

export default LifestyleDesign;
