import React, { useState, useEffect } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
} from "@mui/material";
import { Plus, Minus } from "lucide-react";
import dayjs from "dayjs";
import axios from "axios";

const MealUploads = ({ clientData }) => {
  const { code: memberCode } = clientData;
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [mealData, setMealData] = useState({
    date: "",
    totalCalories: 0,
    meals: [],
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [expandedAccordion, setExpandedAccordion] = useState(false);

  useEffect(() => {
    fetchMealData(selectedDate.format("YYYY-MM-DD"));
  }, [memberCode, selectedDate]);

  const fetchMealData = async (date) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_MAIN_SERVICE_BASE_URL}/api/v1/nutrition/meals?memberCode=${memberCode}&date=${date}`
      );
      const data = response.data;
      if (data?.success && data.data) {
        setMealData(data.data);
      } else {
        setMealData({ date: date, totalCalories: 0, meals: [] });
      }
    } catch (err) {
      console.error("Error fetching meal data:", err);
      setError("Failed to fetch meal data. Please try again later.");
      setMealData({ date: date, totalCalories: 0, meals: [] });
    }
    setLoading(false);
  };

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedAccordion(isExpanded ? panel : false);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid container spacing={3} direction="column">
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <DateCalendar value={selectedDate} onChange={handleDateChange} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={8}>
          <Card>
            <CardContent>
              <Typography variant="h5" gutterBottom>
                Meals for {selectedDate.format("DD/MM/YYYY")}
              </Typography>
              {loading && <Typography>Loading meal data...</Typography>}
              {error && <Typography color="error">{error}</Typography>}
              {!loading && !error && (
                <>
                  <Typography>Date: {mealData.date}</Typography>
                  <Typography>
                    Number of meals: {mealData.meals.length}
                  </Typography>
                  <Typography>
                    Total calories consumed: {mealData.totalCalories}
                  </Typography>
                </>
              )}
              {mealData.meals.map((meal, index) => (
                <Accordion
                  key={index}
                  expanded={expandedAccordion === `panel${index}`}
                  onChange={handleAccordionChange(`panel${index}`)}
                  sx={{ marginTop: 2 }}
                >
                  <AccordionSummary
                    expandIcon={
                      expandedAccordion === `panel${index}` ? (
                        <Minus />
                      ) : (
                        <Plus />
                      )
                    }
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <img
                        src={meal.mealUrl}
                        alt={meal.mealType}
                        style={{
                          marginRight: 16,
                          objectFit: "cover",
                        }}
                        className="sm:w-40 sm:h-40 w-24 h-24"
                      />
                      <Box>
                        <Typography variant="subtitle1">
                          {meal.mealNutritionAnalysis.mealName}
                        </Typography>
                        <Typography variant="body2">
                          Type: {meal.mealType}
                        </Typography>
                        <Typography variant="body2">
                          Calories: {meal.mealNutritionAnalysis.calories}
                        </Typography>
                      </Box>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Protein: {meal.mealNutritionAnalysis.protein}g
                    </Typography>
                    <Typography>
                      Carbohydrates: {meal.mealNutritionAnalysis.carbohydrates}g
                    </Typography>
                    <Typography>
                      Fat: {meal.mealNutritionAnalysis.fat}g
                    </Typography>
                    <Typography variant="body2" sx={{ marginTop: 1 }}>
                      Feedback: {meal.mealNutritionAnalysis.feedback}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
};

export default MealUploads;
