import React from "react";
import { TrendingUp, TrendingDown } from "lucide-react";

const ConsistencyDisplay = ({ workoutGraphData }) => {
  const calculateConsistencyTrend = (data) => {
    if (!Array.isArray(data) || data.length < 2) return null;

    const latestCount = data[0].count;
    const totalCount = data.reduce((sum, entry) => sum + entry.count, 0);
    const averageCount = totalCount / data.length;

    // Avoid division by zero
    if (averageCount === 0) {
      return latestCount > 0 ? Infinity : 0;
    }

    const percentageChange =
      ((latestCount - averageCount) / averageCount) * 100;
    return percentageChange;
  };

  const trend = calculateConsistencyTrend(workoutGraphData?.data);

  return (
    <div className="flex flex-col gap-2">
      {trend !== null && (
        <div className="flex items-center gap-1 text-sm">
          {trend > 0 ? (
            <>
              <TrendingUp className="text-green" size={30} />
              <span className="text-green text-xs">
                Your workout consistency has increased by{" "}
                {trend === Infinity ? "∞" : Math.abs(trend).toFixed(1)}%
              </span>
            </>
          ) : trend < 0 ? (
            <>
              <TrendingDown className="text-red" size={30} />
              <span className="text-red text-xs">
                Your workout consistency has decreased by{" "}
                {Math.abs(trend).toFixed(1)}%
              </span>
            </>
          ) : (
            <span className="text-gray-600 text-xs">
              Your workout consistency remains unchanged
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export default ConsistencyDisplay;
